import { Badge, Button, Divider, HStack, Image, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, Select, Text, VStack } from "@chakra-ui/react";
import { useState } from "react";
import { Produto } from "../types/types";

type PropsType = {
	product: Produto,
	showProdutoComprado: (productname: string, produtoimage: string, productprice: any, productId: number, quantity: number, corProduto: string) => void,
	seeProductDetails: (productId: string) => void,
}

export const ProductItem = ({ product, seeProductDetails, showProdutoComprado }: PropsType) => {

	const [quantity, setQuantity]: any = useState();
	const [corProduto, setCorProduto]: any = useState();

	return (
		<>

			<VStack key={product.title} onClick={() => { seeProductDetails(product.codigo5) }} h={'350px'} w={"284px"} className={"itemProduct"} borderRadius={"3px"} cursor={"pointer"} bg={"white"} >
				<Image fallbackStrategy="beforeLoadOrError" fit={"contain"} style={{ "borderRadius": "30px 30px 0px 0px" }} w={"284px"}
					h={'254px'} padding={5} fallbackSrc={'/fail_load_image.png'} src={product?.image} />
				<Divider outlineColor={'gray'} w={'full'} />

				<VStack spacing={0} h={'100%'} className="preco" w="full" align={'initial'} px={3}>
					<Text className="preco" fontSize={"md"} textDecoration='line-through' textColor='gray'>{(product.preco + (10 / 100) * product.preco).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</Text>
					{product?.mostrar_preco === '1' &&
						<Text pt={2} style={{ color: "#373737" }} fontSize={"22px"}>{(product.preco).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</Text>

					}
					<HStack py={1} p={1}>
						{product.salitoqtd5 === null ?
							<Badge colorScheme={'red'}>Produto esgotado!</Badge>
							:
							<Badge colorScheme={'green'}>Disponível!</Badge>
						}
						<Text textColor={'black'} fontSize={15}>({product?.salitoqtd5 === null ? 0 : product?.salitoqtd5} disponíveis)</Text>
					</HStack>
					<Text className="nome" textColor='GrayText' fontFamily={'Inter'} fontSize={"14px"}>{product.descricao5}</Text>

				</VStack>

				<Popover>

					<PopoverContent >
						<PopoverArrow />
						<PopoverCloseButton />
						<PopoverHeader fontSize={15} textAlign={'center'}><b>Selecione a quantidade de unidades</b></PopoverHeader>
						<PopoverBody>
							<VStack>

								<NumberInput w={'40%'} onInput={(e: any) => e.target.value = e.target.value.slice(0, 2)} onChange={(e: any) => setQuantity(e)} max={50} defaultValue={1} min={1}>
									<NumberInputField type={'number'} maxLength={50} />
									<NumberInputStepper >
										<NumberIncrementStepper />
										<NumberDecrementStepper />
									</NumberInputStepper>
								</NumberInput>
								<Text fontFamily={'Inter, sans-serif'} textColor={product.quantidade === 0 ? 'red' : 'black'} fontSize={16}>Unidades: <b>({product.quantidade} disponíveis)</b></Text>

								{product?.cor1?.length > 0 &&
									<>
										<Text fontSize={14} textAlign={'center'}><b>Escolha a cor do produto</b></Text>
										<Select onChange={(e: any) => setCorProduto(e.target.value)} >

											<option value={'Escolha a cor'}>Escolha a cor do produto</option>
											<option value={product?.cor1}>{product?.cor1}</option>
											<option value={product?.cor2}>{product?.cor2}</option>
											<option value={product?.cor3} >{product?.cor3}</option>
										</Select>
									</>

								}
							</VStack>
							<Button disabled={product.quantidade === 0 ? true : false} w={"full"} top={1} _focus={{}} colorScheme={"blue"} variant={"ghost"} onClick={() => showProdutoComprado((product.nome), product.image, product.preco, product.id, quantity, corProduto)}>Adicionar ao carrinho</Button>

						</PopoverBody>
					</PopoverContent>
				</Popover>

			</VStack>


		</>

	);
}