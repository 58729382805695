import { useMediaQuery } from "@chakra-ui/react"
import { PedidosPage } from "../../pages/PedidosPage"
import { PedidosMobilePage } from "../Mobile/PedidosMobilePage"


export const PedidosResponsivo = () => {
    const [MenorThan1260] = useMediaQuery('(min-width: 1136px)')
    return (

        <>
            {MenorThan1260 ? <PedidosPage /> : <PedidosMobilePage />}
        </>
    )
}
