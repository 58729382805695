import { message } from "antd";
import { createContext, ReactNode, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { api } from "../../util/api";

export const EmpresasContext = createContext<any[]>([]);

interface EmpresasProviderProps {
    children: ReactNode
}

export function EmpresasProvider({ children }: EmpresasProviderProps) {
    const navigate = useNavigate();
    const [empresas, setEmpresas] = useState<any[]>([]);
    const [verified, setVerified]: any = useState(false)

    function VeriifyLoadEmpresas() {
        if (empresas !== undefined && empresas?.length > 0) {

            return verified;
        }
    }

    useEffect(() => {
        // Busca todas as empresas inseridas no banco e retorna dentro do provider

        api.get("/empresas/")
            .then(async (response: any) => {
                setEmpresas(response.data)
            })
            .catch((error: any) => {

                message.destroy()
                message.error('Não foi possível conectar ao servidor!')
                navigate("/*")

            });

    }, [verified, navigate, empresas.length])


    return (

        <EmpresasContext.Provider value={empresas}>
            {children}
        </EmpresasContext.Provider>

    )
}
