import { VStack } from "@chakra-ui/react";
import { ProdutosResponsivo } from "../components/Responsivos/ProdutosResponsivo";
import  '../Styles/Globalcss.css'
export const ListaProdutosPage = () => {

	return (
		<VStack h={'full'} minH={'550px'} spacing={5} className="animacao-bg">
			<ProdutosResponsivo />
		</VStack>
	)
}