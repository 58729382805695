import { PedidosProvider } from "./Context/PedidosContext";
import { PedidosResponsivo } from "./Responsivos/PedidosResponsivo";
export const PedidosProvidersPage = () => {

    return (
        <PedidosProvider  >
            <PedidosResponsivo />
        </PedidosProvider >

    );
}