/* eslint-disable @typescript-eslint/no-unused-vars */
import { LockOutlined, UploadOutlined } from "@ant-design/icons"
import { Avatar, Box, Button, Text, VStack } from "@chakra-ui/react"
import { message, Upload, UploadProps } from "antd"
import { motion } from "framer-motion"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { Footer } from "../components/Footer"
import { NavBarResponsiva } from "../components/Responsivos/NavBarResponsiva"
import { GetMe } from "../hooks/GetMe"

export const Profile = () => {

  const { me, mutateMe, isLoadingMe } = GetMe()
  const navigate = useNavigate()

  const props: UploadProps = {
    name: 'file',
    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    headers: {
      authorization: 'authorization-text',
    },
    onChange(info: any) {

      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  function DeslogarConta() { // Limpa o localStorage removendo o nome, avatar e token do usuário que estão salvos no próprio localStorage.
    localStorage.clear();
    navigate('/empresas/all')
    mutateMe()
  }

  useEffect(() => {

    if (!isLoadingMe && me?.name === undefined) {
      message.destroy()
      message.warning("Você ainda não está logado, por favor faça o login e tente novamente!", 2.0);
      navigate('/')
    }
  })


  return (
    <>
      <NavBarResponsiva />

      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{
          duration: 1.0,

          ease: [0, 0.71, 0.2, 1.01]
        }}
        exit={{ opacity: 0 }}
      >
        <VStack align={'center'} justify={'center'} h={'full'} bg={"gray.100"} w={"full"}>
          <Box p={7} h={'full'} w={'60%'} mt={'5%'} minW={'300px'} minH={'480px'} bgColor={'#fafafa'} boxShadow='0.3px 0.3px 7px rgb(156, 156, 156)' borderRadius={8}>
            <VStack>

              <Text whiteSpace={'break-spaces'} fontFamily={'Inter, sans-serif'} py={3} color={"gray.600"} fontSize={20}><b>Editar Perfil</b></Text>
              {me?.avatar !== null ?

                <Avatar cursor={"pointer"} borderColor={"white"} borderWidth={2} size={'xl'} src={me?.avatar} />
                :
                <>
                  <Avatar size={'xl'} src={'https://plussmaxx.com.br/uploads/user_image/placeholder.png'} />
                  <Upload {...props}>
                    <Button rightIcon={<UploadOutlined />}>Enviar imagem</Button>
                  </Upload>
                </>

              }

              <Text textColor={'gray.800'} fontFamily={'Roboto, sans-serif'} fontSize={20} py={3}><b>{me?.name}</b></Text>
              <Button p={5} top={5} textColor={'red'} variant={'outline'} _focus={{}} onClick={DeslogarConta} colorScheme='red'>Sair da conta</Button>
              <Button onClick={() => navigate('/adm')} _focus={{}} fontFamily={'Roboto, sans-serif'} colorScheme={'gray'} p={2} top={8}>Acesso administrador <LockOutlined style={{ fontSize: 20 }} /></Button>


            </VStack>
          </Box>
        </VStack>
      </motion.div>

      <Footer />
    </>
  )
}