import { Box, Button, Center, Image, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Text, useDisclosure, VStack } from "@chakra-ui/react";
import { message } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useSWR, { useSWRConfig } from "swr";
import { GetAllUsers } from "../hooks/GetAllUsers(AdminPage)";
import { LoginAdm } from "../hooks/GetLoginAdm";
import { Empresas, Produto, Usuario } from "../types/types";
import { api, apiEndPoint } from "../util/api";

export const AdmPage = () => {

    const { admlogged, isLoading } = LoginAdm()
    const { allusers } = GetAllUsers()
    const { mutate } = useSWRConfig()
    const navigate = useNavigate()

    const route: string = apiEndPoint;
    const fetcher = (url: any) => fetch(route + `/admin/allproducts`).then(r => r.json())
    const { data } = useSWR(route + `/admin/allproducts`, fetcher, { refreshInterval: 20000 })

    const { onOpen, isOpen, onClose } = useDisclosure()
    const { onOpen: onOpenEditProduto, isOpen: isOpenEditProduto, onClose: onCloseEditProduto } = useDisclosure()

    const [edit, setEdit]: any = useState();
    const [editproduto, setEditProduto]: any = useState();
    const [allempresas, setAllempresas]: any = useState();
    const [userEmpresas, setUserEmpresas]: any = useState();
    
    if (!isLoading && admlogged?.statusCode === 401) {
        navigate('/loginadm')
    }

    useEffect(() => {

        api.get('admin/allempresas').then((response: any) => {
            setAllempresas(response.data)
        })
    }, [])

    async function EditUser(iduser: any) {
        await api.get(`admin/user/${iduser}`).then(async (response: any) => {
            setEdit(response.data)
            onOpen()
        })
        await api.get(`admin/selectempresauser/${iduser}`).then((response: any) => {
            setUserEmpresas(response.data)
            mutate(`admin/selectempresauser/${iduser}`)

        })

    }

    async function EditProduct(idProduct: number) {
        await api.get(`admin/produto/${idProduct}`).then(async (response: any) => {
            mutate(route + '/admin/allproducts')
            setEditProduto(response.data)
            onOpenEditProduto()
        })
    }

    async function ExcluirProduto(idProduct: number) {
        await api.delete(`admin/deletarproduto/${idProduct}`).then(async () => {
            message.success({
                content: 'Produto excluído com sucesso!',
                className: 'custom-class',
                duration: 2,
                style: {
                    fontFamily: 'Roboto, sans-serif',
                    fontSize: 15,
                    zIndex: 10,

                },

            });
            mutate(route + '/admin/allproducts')
            onCloseEditProduto()
        })
    }

    function settarUsuario(idUser: number, fornecedor: number) {
        api.put('admin/settarusuario', { idUser, fornecedor }).then(() => {
            message.destroy()
            message.success({
                content: 'Usuário alterado com sucesso!',
                className: 'custom-class',
                duration: 2,
                style: {
                    fontFamily: 'Roboto, sans-serif',
                    fontSize: 15,
                    zIndex: 10,

                },

            });

            onClose()
        }).catch((e: any) => {
            message.destroy()
            message.error({
                content: 'Erro ao alterar usuário!',
                className: 'custom-class',
                duration: 2,
                style: {
                    fontFamily: 'Roboto, sans-serif',
                    fontSize: 15,
                    zIndex: 10,

                },

            });
        })
    }

    function setUserEmpresa(idUser: number, idEmpresa: number) {
        api.put('admin/settarempresauser', { idUser, idEmpresa }).then(() => {
            message.success("Empresa selecionada com successo!")
        })
    }

    function AlterarEmpresaUser(idUser: number, idEmpresa: number) {
        api.put('admin/alterarempresauser', { idUser, idEmpresa }).then(() => {
            message.success("Empresa alterada com successo!")
            onClose()
        })
    }

    return (
        <Box w={'100%'} p={3} bgColor={'#fafafa'} boxShadow='0px 0px 15px 2px rgba(0,0,0,0.39);' borderRadius={6}>
            <Center>
                <VStack bg={'#fafafa'} p={5} w="60%" h={'full'} minH={"600px"} borderRadius={4}>
                    <Text textColor={'black'} fontSize={26}>Página administrador</Text>
                    <Text textColor={'black'} fontSize={22}>Alterar permissões de usuários</Text>

                    <Select value={''} onChange={(e: any) => EditUser(e.target.value)} placeholder="Selecionar usuário">
                        {allusers?.map((usuarios: Usuario) => (
                            <option key={usuarios.id} value={usuarios.id}>{usuarios.nome}  ({usuarios.email})</option>

                        ))}
                    </Select>

                    <Text pt={5} textColor={'black'} fontSize={16}>Edição de produtos</Text>
                    <Select value={''} onChange={(e: any) => EditProduct(e.target.value)} placeholder="Selecionar produto">
                        {data?.map((produtos: Produto) => (
                            <option key={produtos.id} value={produtos.id}>{produtos.nome}</option>

                        ))}
                    </Select>

                    <VStack>
                        <Image cursor={'pointer'} onClick={() => navigate('/empresas/all')} _active={{}} _focus={{}} mt={'30%'} w={'400px'} src='/logosuc.png'></Image>
                    </VStack>


                </VStack>
            </Center>

            <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay zIndex={0} />
                <ModalContent>
                    <ModalHeader>SELECIONADO: {edit?.[0]?.nome}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <VStack>

                            {edit?.map((usuario: Usuario) => (
                                usuario.fornecedor === 1 ?
                                    <>
                                        <Text textColor={'black'} fontSize={15}><b>Esse usuário é um fornecedor!</b></Text>
                                        <Select value={''} onChange={(e: any) => settarUsuario(usuario?.id, e.target.value)} placeholder="Selecionar tipo de usuário">
                                            <option value='0'>Cliente</option>
                                            <option value='1'>Fornecedor</option>
                                        </Select>
                                        {userEmpresas?.length !== 0 ?
                                            userEmpresas?.map((empresas: Empresas) => {
                                                return (
                                                    <>
                                                        <Text fontSize={16}>Empresa do fornecedor : {empresas.title}</Text>
                                                        <Text>Alterar empresa do fornecedor</Text>
                                                        <Select onChange={(e: any) => AlterarEmpresaUser(usuario?.id, e.target.value)} placeholder="Alterar empresa">
                                                            {allempresas?.map((empresas: Empresas) => (
                                                                <option key={empresas.title} value={empresas.id}>{empresas.title}</option>

                                                            ))}
                                                        </Select>
                                                    </>
                                                )
                                            })
                                            :
                                            <>
                                                <Text fontSize={16}>Selecionar empresa para fornecedor</Text>
                                                <Select onChange={(e: any) => setUserEmpresa(usuario?.id, e.target.value)} placeholder="Selecionar empresa">
                                                    {allempresas?.map((empresas: Empresas) => (
                                                        <option key={empresas.cnpj} value={empresas.id}>{empresas.title}</option>

                                                    ))}
                                                </Select>
                                            </>

                                        }
                                    </>
                                    :
                                    <>
                                        <Text textColor={'black'} fontSize={15}><b>Esse usuário é um cliente!</b></Text>
                                        <Text>Selecione 0 para tornar usuário comum ou selecione 1 para tornar usuário fornecedor</Text>
                                        <Select onChange={(e: any) => settarUsuario(usuario?.id, e.target.value)} placeholder="Settar usuário como fornecedor">
                                            <option value='0'>Cliente</option>
                                            <option value='1'>Fornecedor</option>
                                        </Select>
                                    </>
                            ))}

                        </VStack>

                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={onClose} colorScheme={'blue'}>Fechar</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <Modal closeOnOverlayClick={false} isOpen={isOpenEditProduto} onClose={onCloseEditProduto}>
                <ModalOverlay zIndex={0} />
                <ModalContent>
                    <ModalHeader>SELECIONADO: {editproduto?.[0]?.nome}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <VStack>
                            <Image w={'50%'} src={editproduto?.[0]?.image}></Image>
                            <Text fontSize={16}>Alterar nome</Text>
                            <Input placeholder={editproduto?.[0]?.nome}></Input>
                            <Button onClick={() => ExcluirProduto(editproduto?.[0]?.id)} colorScheme={'red'}>Excluir produto</Button>
                        </VStack>

                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={onCloseEditProduto} colorScheme={'blue'}>Fechar</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>



    )
}