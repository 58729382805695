import { message } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { api } from '../util/api';
export const useCadastroCliente = () => {

	// Hook usado para o cadastro do cliente usando o Google

	const navigate = useNavigate()
	const [error, setError] = useState();
	const [success, setSuccess] = useState<number>();
	const [loginIsLoading, setIsLoadingLogin] = useState(false);

	const salvarUsuarioClienteApi = async (data: any) => {
		setIsLoadingLogin(!loginIsLoading);
		await api.post('/auth/cadastro-dadosrestantes-google', { data })

			.then(async () => {

				message.success("Cadastrado com sucesso!", 1.0);

				message.loading('Redirecionando para a página principal!', 0.8);
				setIsLoadingLogin(!loginIsLoading);

				navigate('/');
				setIsLoadingLogin(false);
			})
			.catch((error: any) => {
				message.error("Não foi possível concluir seu cadastro, por favor verifique seus dados e tente novamente!");
				setIsLoadingLogin(false);
			});

	}
	return {
		salvarUsuarioClienteApi,
		error,
		success
	}

}