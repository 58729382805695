/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Divider, HStack, Image, Show, Spinner, Text, VStack } from '@chakra-ui/react';
import "@fontsource/poppins";
import "@fontsource/roboto";
import { Badge } from '@mantine/core';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Empresas } from '../../types/types';
import { api } from '../../util/api';
import { EmpresasContext } from '../Context/EmpresasContext';

export const ListCompanyMobile = () => {
    const { categoria } = useParams()
    const empresas: Empresas[] = useContext(EmpresasContext);
    const navigate = useNavigate();
    const [isProductLoading, setIsProductLoading] = useState(false);
    const [busca, setBusca]: any = useState(empresas);

    function seeProductsByEmpresa(empresaId: any) {
        navigate('/' + empresaId + '/page=1/lista-produtos')
    }

    function timeout(delay: number) {
        return new Promise(res => setTimeout(res, delay));

    }

    async function BuscaEmpresa() {

        if (empresas?.length > 0) {
            setIsProductLoading(false);
        }

        if (categoria === 'all') {
            setBusca(empresas)
        }

        else {
            await api.post('/empresas/buscacategoria', { categoria }).then((response: any) => {

                if (response?.data?.length > 0) {
                    setBusca(response.data);
                    setIsProductLoading(false);

                }
                else
                    setBusca(empresas)
                setIsProductLoading(false);

            })
        }
    }

    useEffect(() => {
        setIsProductLoading(true)
        BuscaEmpresa()
        TurnOffLoading()


    }, [empresas, categoria])

    async function TurnOffLoading() {
        await timeout(10000);
        setIsProductLoading(false)
    }


    return (

        <>
            <Box h={busca?.length === 1 ? '400px' : 'auto'} w={'100%'} p={6} boxShadow='0.3px 0.3px 8px rgb(158, 158, 158)' borderRadius={5} bgColor={'#ffffff'}>
                {isProductLoading &&
                    <VStack h={'450px'} justify={'center'} pt={'5%'} align={'center'}>
                        <Spinner color='blue' size='xl' />
                        <Text fontFamily={'Roboto, sans-serif'} fontSize={20}><b>Carregando...</b></Text>
                    </VStack>
                }
                
                {busca.map((empresas: any) => {
                    return (

                        <Show key={empresas?.image}>
                            <HStack cursor={'pointer'} onClick={() => seeProductsByEmpresa(empresas.id)} pt={8} h={'120px'}>
                                <Image alt='lista-empresas' fit={'contain'} w={'30%'} minH={'100px'} maxH={'140px'} maxW={'150px'} src={empresas.image}></Image>

                                <VStack alignItems={"start"}>
                                    <Text fontFamily={'Inter'} pt={3} textColor={'blackAlpha.800'} fontSize={14}><b>{empresas.title}</b></Text>
                                    <Text w={'220px'} textOverflow={'ellipsis'} whiteSpace={'nowrap'} overflow={'hidden'} fontFamily={'Roboto, sans-serif'} textColor={'gray'} fontSize={14}>{empresas.description}</Text>
                                    <Badge color="green">Aberto</Badge>
                                </VStack>

                            </HStack>
                            <Divider orientation="horizontal" borderColor={'gray.300'} w={'100%'} pt={5} h={'35px'} />

                        </Show>
                    )
                })}

            </Box>
        </>
    )
}