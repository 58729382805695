import { CloudServerOutlined, MobileTwoTone, SafetyCertificateTwoTone, SafetyOutlined, ShoppingCartOutlined } from "@ant-design/icons"
import { Badge, Box, Button, HStack, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spacer, Text, useDisclosure, VStack } from "@chakra-ui/react"
import { Steps } from "antd"
import { motion } from "framer-motion"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Footer } from "../components/Footer"
import '../Styles/Globalcss.css'

export const TermosDeUsoPage = () => {  
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const navigate = useNavigate();

    const { isOpen: isOpenInstrucoes, onOpen: OnOpenInstrucoes, onClose: onCloseInstrucoes } = useDisclosure()
    const { isOpen: isOpenFormasPagamento, onOpen: onOpenFormasPagamento, onClose: onCloseFormasPagamento } = useDisclosure()
    const [distanceScroll, setDistanteScroll]: any = useState()
    const dadosSelect: any = [
        {
            image: 'https://i.ibb.co/C5dwN4L/99538099-3b7a5d00-298b-11eb-9f.png',
            label: 'Pix',
            value: 'PIX',
            description: 'Pix é o pagamento instantâneo brasileiro. O meio de pagamento criado pelo Banco Central (BC)',
        },

        {
            image: 'https://i.ibb.co/0qCZNjT/debit-card.png',
            label: 'Cartão',
            value: 'CARTÃO',
            description: 'Cartões de crédito, débito ou vale alimentação.',
        },
        {
            image: 'https://i.ibb.co/98XcKjK/cash-payment-1.png',
            label: 'Dinheiro',
            value: 'DINHEIRO',
            description: 'Pagamento a vista com dinheiro em espécie.',
        }
    ];


    const steps = [
        {
            title: 'Primeiro',
            content: 'O primeiro passo é cadastrar sua conta, no botão "Crie sua conta" na barra de navegação do menu principal ➡️',
        },
        {
            title: 'Segundo',
            content: 'O segundo passo é selecionar a empresa no qual deseja comprar, após clicar na empresa desejada você poderá clicar no botão "Adicionar ao Carrinho" nos produtos que deseja! ➡️',
        },
        {
            title: 'Terceiro',
            content: 'O último passo é clicar no carrinho localizado na barra superior de navegação, verificar se seus pedidos estão corretos, clicar em "Finalizar Pedido", selecionar um método de pagamento e assim concluir seu pedido! 😉',
        },


    ]
    
    const [current, setCurrent] = useState(0);

    const next = () => {
        setCurrent(current + 1);
    };

    const prev = () => {
        setCurrent(current - 1);
    };
    const { Step } = Steps;

    const onChange = (value: number) => {
        setCurrent(value);
    };

    window.onscroll = function () {
        var distanceScrolled = document.documentElement.scrollTop;
        setDistanteScroll(distanceScrolled)
    }

    const WidthText = '650px'

    return (

        <>
            <VStack bg={'#f8f8f8'}>
                {distanceScroll < 200 ?
                    <motion.div

                        exit={{ opacity: 0 }}
                        initial={{ opacity: 0, width: '100%' }}
                        animate={{ opacity: 1 }}
                    >

                        <HStack position={'fixed'} w={'full'} bg={'#3a5fcb'} boxShadow={'0px 0px 14px 2px rgba(0,0,0,0.83);'}>

                            <Image onClick={() => { navigate('/empresas/all') }} cursor={"pointer"} width={'160px'} src='/logo branca.svg'></Image>
                            <Text fontFamily={'Inter, sans-serif'} fontSize={25} textColor='white' w={'100%'} pl={3} py={3}>Termos de uso</Text>
                        </HStack>
                    </motion.div>
                    :
                    <motion.div

                        exit={{ display: '' }}
                        initial={{ opacity: 1, width: '100%' }}
                        animate={{ opacity: 0, display:'' }}
                    >

                        <HStack position={'fixed'} w={'full'} bg={'#3a5fcb'} boxShadow={'0px 0px 14px 2px rgba(0,0,0,0.83);'}>

                            <Image  width={'160px'} src='/logo branca.svg'></Image>
                            <Text fontFamily={'Inter, sans-serif'} fontSize={25} textColor='white' w={'100%'} pl={3} py={3}>Termos de uso</Text>
                        </HStack>
                    </motion.div>

                }
                <Spacer py={8} />
                <Image cursor={'pointer'} onClick={() => navigate('/')} minW={'300px'} top={'5%'} w={'20%'} src='/logosuc.png'></Image>
                <Text fontWeight={1.2} lineHeight={1.50} fontSize={30} textColor={'black'} fontFamily={'Roboto, sans-serif'} p={5}>Carrinho de compras - Success Sistemas</Text>
                <Text fontFamily={'Inter'} lineHeight={1.25} fontWeight={'300px'} textColor='black' fontSize={22}>Sua privacidade, seus direitos e o nosso compromisso.</Text>
                <Text maxW={'668px'} fontFamily={'Inter'} textColor='blackAlpha.800' p={3} fontSize={18}>Usamos seus dados para criar ferramentas que facilitam o seu dia a dia. Explicaremos aqui o que fazemos com os seus dados.</Text>

                <HStack>
                    <Text fontSize={25}><SafetyCertificateTwoTone /></Text>
                    <Text textColor={'gray.700'} fontSize={20}><b>Somos transparentes</b></Text>
                </HStack>
                <Text p={3} fontFamily={'Roboto, sans-serif'} maxW={WidthText} textColor={'GrayText'} fontSize={17}>Explicamos de forma simples e clara os usos que fazemos das suas informações, como e por que processamos seus dados e com quem podemos compartilhá-los.</Text>

                <HStack align={'center'}>
                    <Text fontSize={25}><MobileTwoTone /></Text>
                    <Text textColor={'gray.700'} fontSize={20}><b>Criamos valor</b></Text>
                </HStack>
                <Text p={3} fontFamily={'Roboto, sans-serif'} maxW={WidthText} textColor={'GrayText'} fontSize={17}>Processamos seus dados com responsabilidade para fornecer serviços personalizados. Por exemplo, usamos sua localização geográfica para oferecer serviços próximos à sua localização.</Text>

                <HStack>
                    <Text textColor={'blue.400'} fontSize={25}><ShoppingCartOutlined /></Text>
                    <Text textColor={'gray.700'} fontSize={20}><b>Trabalhamos com qualidade</b></Text>
                </HStack>
                <Text p={3} fontFamily={'Roboto, sans-serif'} maxW={WidthText} textColor={'GrayText'} fontSize={17}>Nos esforçamos para processar informações de qualidade, precisas e atualizadas. Por exemplo, salvamos suas informações e atualizamos em todas as nossas plataformas, suas compras chegam corretamente e você economiza tempo nos formulários.</Text>

                <HStack>
                    <Text textColor={'blue.400'} fontSize={25}><CloudServerOutlined /></Text>
                    <Text textColor={'gray.700'} fontSize={20}><b>Protegemos seus dados</b></Text>
                </HStack>
                <Text p={3} fontFamily={'Roboto, sans-serif'} maxW={WidthText} textColor={'GrayText'} fontSize={17}>Protegemos seus dados pessoais com o mais alto padrão de segurança. Não comercializamos seus dados.</Text>

                <HStack>
                    <Text textColor={'blue.400'} fontSize={25}><SafetyOutlined /></Text>
                    <Text textColor={'gray.700'} fontSize={20}><b>Como compartilhamos seus dados</b></Text>
                </HStack>
                <Text p={3} fontFamily={'Roboto, sans-serif'} maxW={WidthText} textColor={'GrayText'} fontSize={17}>Suas informações pessoais estão protegidas. Não vendemos ou comercializamos informações que possam identificá-lo. Também não compartilhamos ou transferimos de nenhuma outra forma suas informações pessoais para terceiros, com exceção dos prestadores de serviços (terceiros que contratamos para fornecer um serviço seguindo nossas instruções), parceiros comerciais, outras empresas do nosso grupo, outros usuários, autoridades competentes, entre outros..</Text>

                <HStack>
                    <Button p={1} borderRadius={3} colorScheme={'telegram'} onClick={OnOpenInstrucoes}>Instruções de compra</Button>
                    <Button p={1} borderRadius={3} colorScheme={'telegram'} onClick={onOpenFormasPagamento}>Formas de pagamento</Button>
                </HStack>
                <Button borderRadius={2} p={2} _focus={{}} variant={'solid'} colorScheme={'whatsapp'} onClick={() => navigate('/empresas/all')}>Comprar</Button>

                <Footer />

            </VStack>
            <>
                {/* Modal que abre tela de instruções */}
                <Modal isOpen={isOpenInstrucoes} onClose={onCloseInstrucoes}>
                    <ModalOverlay />
                    <ModalContent >
                        <ModalHeader>Instruções:</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody textColor={'black'} fontFamily={'Roboto, sans-serif'} fontSize={18}>
                            <>
                                <Steps percent={current === 2 ? 100 : 50 && current === 1 ? 50 : 30} onChange={onChange} current={current}>
                                    {steps.map(item => (
                                        <Step key={item.title} title={item.title} />
                                    ))}
                                </Steps>

                                <Box mt={2} p={5} boxShadow={'5px 8px 12px 3px rgba(0,0,0,0.18);'}>

                                    <div style={{ padding: '5%' }} className="steps-content">{steps[current].content}</div>
                                    <div className="steps-action">

                                    </div>
                                </Box>
                                {current > 0 && (
                                    <Button top={2} style={{ margin: '0 8px' }} onClick={() => prev()}>
                                        Anterior
                                    </Button>
                                )}
                                {current < steps.length - 1 && (
                                    <Button mt={4} onClick={() => next()}>
                                        Próximo
                                    </Button>
                                )}
                            </>
                        </ModalBody>

                        <ModalFooter>
                            <Button colorScheme='blue' mr={3} onClick={onCloseInstrucoes}>
                                Fechar
                            </Button>

                        </ModalFooter>
                    </ModalContent>
                </Modal>
                {/* Modal que abre tela de instruções */}


                {/* Modal que abre tela das formas de pagamento */}
                <Modal isOpen={isOpenFormasPagamento} onClose={onCloseFormasPagamento}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Formas de pagamento</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody textColor={'black'} fontFamily={'Roboto, sans-serif'} fontSize={18}>
                            <Text>Essas são as nossas formas de pagamento:</Text>
                            <Box>
                                <HStack p={3}>
                                    <Image w={'15%'} src={dadosSelect?.[0]?.image} />
                                    <Text fontSize={18}>Pix</Text>
                                    <Badge colorScheme={'red'}> APROVAÇÃO IMEDIATA!</Badge>
                                </HStack>

                                <HStack p={3}>
                                    <Image w={'15%'} src={dadosSelect?.[1]?.image} />
                                    <Text fontSize={18}>Cartão</Text>
                                    <Badge colorScheme={'green'}>APROVAÇÃO RÁPIDA</Badge>
                                </HStack>

                                <HStack p={3}>
                                    <Image w={'15%'} src={dadosSelect?.[2]?.image} />
                                    <Text fontSize={18}>Dinheiro</Text>
                                    <Badge colorScheme={'yellow'}>APENAS NA ENTREGA</Badge>
                                </HStack>

                            </Box>

                            <Text textColor={'gray.700'}>Em breve maiores informações disponíveis!</Text>
                        </ModalBody>

                        <ModalFooter>
                            <Button colorScheme='blue' mr={3} onClick={onCloseFormasPagamento}>
                                Fechar
                            </Button>

                        </ModalFooter>
                    </ModalContent>
                </Modal>
                {/* Modal que abre tela das formas de pagamento */}
            </></>
    )
}