/* eslint-disable eqeqeq */
import { CheckCircleTwoTone, CloseCircleOutlined } from '@ant-design/icons';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import { Box, Button, HStack, Input, InputGroup, InputRightElement, SimpleGrid, Spacer, Text, VStack } from '@chakra-ui/react';
import { Stepper } from "@mantine/core";
import { Popover } from 'antd';
import axios from "axios";
import { useState } from "react";
import ReactInputMask from 'react-input-mask';
import { useNavigate } from "react-router-dom";
import { Footer } from "../components/Footer";
import { NavBarLogin } from "../components/NavBarLogin";
import { useCadastroCliente } from "../hooks/useCadastroCliente";
import { Endereco } from '../types/types';

export const SignUpGoogle = () => {

	const { salvarUsuarioClienteApi } = useCadastroCliente();


	const navigate = useNavigate()

	const [active, setActive] = useState(1);
	const [rua, setRua] = useState('');
	const [cep, setCep] = useState('');
	const [numero, setNumero] = useState('');
	const [cidade, setCidade] = useState('');
	const [estado, setEstado] = useState('');
	const [bairro, setBairro] = useState('');
	const [cpfcnpj, setCpfCnpj] = useState('');
	const [datanascimento, SetDataNascimento]: any = useState();
	const [inscricaoestadual, SetInscricaoEstadual]: any = useState();
	const [telefone, SetTelefone]: any = useState('');
	const GoogleId = localStorage.getItem('@App:googleId')
	const [loginIsLoading, setIsLoadingLogin] = useState(false);
	const url = "https://viacep.com.br/ws/" + cep + "/json/";

	//Função criada para pegar a quantidade de "passos" que o usuário passou, feita para mudar o nome do button de continuar para finalizar.
	const [passoAtual, setPassoAtual] = useState(1);
	const quantidadeTotalPassos = 2;
	const nextStep = () => setActive((current) => (current < 2 ? current + 1 : current));
	const prevStep = () => setActive((current) => (current > 1 ? current - 1 : current));

	function delay(ms: number) {
		return new Promise(resolve => setTimeout(resolve, ms));
	}

	function setAddress() {
		axios.get(url).then(result => {
			const data: Endereco = result.data;
			setBairro(data.bairro);
			setCidade(data.localidade)
			setRua(data.logradouro)
			setEstado(data.uf)

		}).catch(error => {
			console.log("Ocorreu um erro, ", error)
		});

	}

	const informacaoCPF = (
		<div>
			<p>Por favor, digite seu CPF ou CNPJ completo para continuar!</p>
		</div>
	);


	function validCpf(cpf: any) {
		if (!cpf || cpf.length !== 11
			|| cpf === "00000000000"
			|| cpf === "11111111111"
			|| cpf === "22222222222"
			|| cpf === "33333333333"
			|| cpf === "44444444444"
			|| cpf === "55555555555"
			|| cpf === "66666666666"
			|| cpf === "77777777777"
			|| cpf === "88888888888"
			|| cpf === "99999999999")
			return false
		var soma = 0
		var resto
		for (var i = 1; i <= 9; i++)
			soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i)
		resto = (soma * 10) % 11
		if ((resto === 10) || (resto === 11)) resto = 0
		if (resto !== parseInt(cpf.substring(9, 10))) return false
		soma = 0
		for (var i = 1; i <= 10; i++)
			soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i)
		resto = (soma * 10) % 11
		if ((resto === 10) || (resto === 11)) resto = 0
		if (resto !== parseInt(cpf.substring(10, 11))) return false
		return true
	}


	function validarCNPJ(cnpj: any) {

		cnpj = cnpj.replace(/[^\d]+/g, '');

		if (cnpj == '') return false;

		if (cnpj.length != 14)
			return false;

		// LINHA 10 - Elimina CNPJs invalidos conhecidos
		if (cnpj == "00000000000000" ||
			cnpj == "11111111111111" ||
			cnpj == "22222222222222" ||
			cnpj == "33333333333333" ||
			cnpj == "44444444444444" ||
			cnpj == "55555555555555" ||
			cnpj == "66666666666666" ||
			cnpj == "77777777777777" ||
			cnpj == "88888888888888" ||
			cnpj == "99999999999999")
			return false; // LINHA 21

		// Valida DVs LINHA 23 -
		var tamanho = cnpj.length - 2
		var numeros = cnpj.substring(0, tamanho);
		var digitos = cnpj.substring(tamanho);
		var soma = 0;
		var resultado;
		var i;
		var pos = tamanho - 7;
		for (i = tamanho; i >= 1; i--) {
			soma += numeros.charAt(tamanho - i) * pos--;
			if (pos < 2)
				pos = 9;
		}
		resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
		if (resultado != digitos.charAt(0))
			return false;

		tamanho = tamanho + 1;
		numeros = cnpj.substring(0, tamanho);
		soma = 0;
		pos = tamanho - 7;
		for (i = tamanho; i >= 1; i--) {
			soma += numeros.charAt(tamanho - i) * pos--;
			if (pos < 2)
				pos = 9;
		}
		resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
		if (resultado != digitos.charAt(1))
			return false; // LINHA 49

		return true; // LINHA 51

	}

	const data = {
		rua,
		cep,
		numero,
		cidade,
		estado,
		bairro,
		cpfcnpj,
		GoogleId,
		datanascimento,
		inscricaoestadual,
		telefone,
	}

	function SalvarDadosUsuarioApi() {

		salvarUsuarioClienteApi(data)

	}

	return (
		<>
			<VStack bgColor={'gray.100'} spacing={15} w='full'>
				<NavBarLogin />
				<Box w={'full'} maxW={'550px'} bgColor={'white'} boxShadow='0px 0px 20px 4px #8d8d8d87;' borderRadius={8}>
					<VStack padding={10} mt={2} >
						<Text fontFamily={'Inter, sans-serif'} color={"gray.600"} fontSize={26}><b>Cadastro com Google</b></Text>
						<Text fontFamily={'Inter'} fontSize={17} color={"gray.400"} >É rápido e fácil</Text>

						<Stepper py={10} active={active} breakpoint="xs">
							<Stepper.Step label="Passo 1" description="CPF">
							</Stepper.Step>

							<Stepper.Step label="Passo 2" description="CPF/CNPJ e endereço">
								Passo 1 conteúdo: CPF e dados pessoais
							</Stepper.Step>


						</Stepper>


						{active === 1 ?
							<VStack align={'initial'} w={"100%"}>
								<Text fontFamily={'Inter, sans-serif'} fontSize={18}>CPF ou CNPJ</Text>
								<HStack w={'full'}>
									<InputGroup>
										<Input onInput={(e: any) => e.target.value = e.target.value.slice(0, 14)} type={'number'} autoComplete="off" onChange={(e: any) => setCpfCnpj(e.target.value)} fontSize={18} fontFamily={'Poppins, sans-serif'} w={'100%'} placeholder={'CPF ou CNPJ'}></Input>
										<InputRightElement fontSize={28} children=
											{cpfcnpj.length !== 11 && cpfcnpj.length !== 14 ?
												<Popover content={informacaoCPF} title="Insira seu CPF ou CNPJ completo!">
													<InfoOutlineIcon />
												</Popover>

												:
												cpfcnpj.length === 11 ?
													validCpf(cpfcnpj) === true ?
														<CheckCircleTwoTone style={{ color: 'green' }} />
														:

														<CloseCircleOutlined style={{ color: 'red' }} />
													:
													cpfcnpj.length === 14 ?
														validarCNPJ(cpfcnpj) === true ?
															<CheckCircleTwoTone style={{ color: 'green' }} />
															:
															<CloseCircleOutlined style={{ color: 'red' }} />
														:
														<></>
											}
										/>
									</InputGroup>
									<Text fontSize={30}>*</Text>
								</HStack>
								{validCpf(cpfcnpj) === false && cpfcnpj.length === 11 ?


									<Text alignSelf={'center'} textColor={'red'} fontSize={16}>Por favor preencha o campo com um CPF válido!</Text>
									:
									<></>
								}

								{validarCNPJ(cpfcnpj) === false && cpfcnpj.length === 14 ?
									<Text textAlign={'center'} textColor={'red'} fontSize={16}>Por favor preencha o campo com um CNPJ válido!</Text>
									:
									<></>
								}

								<Text fontFamily={'Inter, sans-serif'} fontSize={18}>Data de Nascimento</Text>
								<Input type={'date'} onChange={(e: any) => SetDataNascimento(e.target.value)} fontSize={16} w={'100%'} p={5} placeholder={'Data de nascimento'}></Input>

								<Text fontFamily={'Inter, sans-serif'} fontSize={18}>Número Inscrição Estadual(Se possui)</Text>
								<Input onInput={(e: any) => e.target.value = e.target.value.slice(0, 14)} type={'number'} onChange={(e: any) => SetInscricaoEstadual(e.target.value)} fontSize={16} w={'100%'} p={5} placeholder={'Inscrição estadual'}></Input>

								<Text fontFamily={'Inter, sans-serif'} fontSize={18}>Telefone</Text>
								<InputGroup>
									<ReactInputMask maskPlaceholder={null} onChange={(e: any) => SetTelefone(e.target.value)} onInput={(e: any) => e.target.value = e.target.value.slice(0, 14)} placeholder='Telefone' className='chakra-input css-qou3w9' mask=" (99)999999999" />
									<InputRightElement
										pointerEvents='none'
										children={<Text fontSize={30}>*</Text>}
									/>
								</InputGroup>

							</VStack>
							: <></>}

						{active === 2 ?
							<SimpleGrid w={"100%"} spacing={3} column={{ xsm: 1, sm: 1, md: 2, lg: 2 }}>

								<Input type={'number'} value={cep} onBlur={setAddress} onChange={(e: any) => setCep(e.target.value)} fontSize={18} fontFamily={'Poppins, sans-serif'} w={'100%'} placeholder={'CEP'}></Input>

								<Input value={rua} onChange={(e: any) => setRua(e.target.value)} fontSize={18} type={'address'} fontFamily={'Poppins, sans-serif'} w={'100%'} placeholder={'Rua'}></Input>


								<Input type={'number'} value={numero} onChange={(e: any) => setNumero(e.target.value)} fontSize={18} fontFamily={'Poppins, sans-serif'} placeholder={'Número'}></Input>
								<Input value={cidade} onChange={(e: any) => setCidade(e.target.value)} fontSize={18} type={'address'} fontFamily={'Poppins, sans-serif'} placeholder={'Cidade'}></Input>

								<Input value={bairro} onChange={(e: any) => setBairro(e.target.value)} fontSize={18} fontFamily={'Poppins, sans-serif'} placeholder={'Bairro'}></Input>
								<Input value={estado} onChange={(e: any) => setEstado(e.target.value)} fontSize={18} type={'address'} fontFamily={'Poppins, sans-serif'} placeholder={'Estado'}></Input>
							</SimpleGrid>
							: <></>}

						<HStack pt={4}>
							<Button onClick={() => {


								prevStep();
								//Verifica se o passo atual é maior ou igual a quantidade total de passos permitidos, caso seja verdade; vai permitir decrementar a variável
								//Esse passo atual só é usado para alterar o nome do button de finalizar para continuar 
								if (passoAtual >= quantidadeTotalPassos) {
									setPassoAtual(passoAtual - 1)
									setCpfCnpj('')
									SetTelefone('')
									SetDataNascimento('')
									SetInscricaoEstadual('')
								}

							}} variant={"outline"} w={'200px'} _focus={{}} fontFamily={'Roboto, sans-serif'} colorScheme={'blue'} >Voltar</Button>
							<Button
								isDisabled={

									(passoAtual === 1

										&& ((cpfcnpj.length === 14
											&& validarCNPJ(cpfcnpj) === false)
											|| ((cpfcnpj.length === 11
												&& validCpf(cpfcnpj) === false)
												|| (cpfcnpj.length !== 11
													&& cpfcnpj.length !== 14))
											|| telefone?.length < 13
										))
										||
										(passoAtual === 2 &&
											(cep?.length < 7 ||
												rua.length < 6 ||
												numero?.length < 1 ||
												cidade?.length < 3 ||
												bairro?.length < 3 ||
												estado?.length < 2
											))
										? true : false}

								onClick={async () => {

									//caso esteja no último passo, é chamado a api para salvar os dados
									if (passoAtual === 2) {
										SalvarDadosUsuarioApi();
										setIsLoadingLogin(!loginIsLoading);
										await delay(100)
										setIsLoadingLogin(false);

									}
									nextStep();

									//Verifica se o passo atual é menor que a quantidade total de passos permitidos, caso seja verdade; vai para o passo 2
									//Esse passo atual só é usado para alterar o nome do button de continuar para finalizar e chamar a api quando necessário, ou seja; quando preencher o último passo
									if (passoAtual < quantidadeTotalPassos) {
										setPassoAtual(passoAtual + 1)
									}

								}} w={'200px'} isLoading={loginIsLoading} _focus={{}} fontFamily={'Inter, sans-serif'} colorScheme={'blue'}>{passoAtual === quantidadeTotalPassos ? "Finalizar" : "Continuar"}</Button>

						</HStack>

						<HStack>
							<Text p={2} fontFamily={'Inter, sans-serif'} fontWeight={"medium"} color={"gray.600"}>Já possui um cadastro?</Text>
							<Text onClick={() => { navigate('/login') }} fontWeight={"medium"} cursor={"pointer"} fontFamily={'Inter, sans-serif'} color={"#508feb"}>Faça login.</Text>
						</HStack>

						<Spacer />
					</VStack>
				</Box>
			</VStack>
			<VStack pt={19} bgColor={'gray.100'} >
				<Footer />
			</VStack>

		</>
	)
}	