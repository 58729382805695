import { Spinner, VStack } from "@chakra-ui/react";
import { message } from "antd";
import { createContext, ReactNode, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { api } from "../../util/api";

export const PedidosContext = createContext<any[]>([]);

interface PedidosProviderProps {
    children: ReactNode
}

export function PedidosProvider({ children }: PedidosProviderProps) {
    const [pedidos, setPedidos] = useState<any[]>([]);
    const navigate = useNavigate();
    const [isPedidoLoading, setIsPedidoLoading] = useState(false);

    useEffect(() => {

        // Busca todos os pedidos do usuário e os retorna dentro do provider
        setIsPedidoLoading(true);
        api.get("/pedido/itens")
            .then(async (response: any) => {
                setPedidos(response.data)
                setIsPedidoLoading(false)

            })
            .catch((error: any) => {

                message.destroy()
                message.error('Não foi possível conectar ao servidor!')
                setIsPedidoLoading(false)
                navigate("/*")

            });

    }, [navigate])

    return (

        <PedidosContext.Provider value={pedidos}>
            {isPedidoLoading &&
                <VStack bg={'#fafafa'}>
                    <Spinner speed="0.5s" thickness='3px' size='xl' />
                </VStack>
                
            }
            {children}
        </PedidosContext.Provider>

    )
}
