import { HStack, SimpleGrid, Skeleton, VStack } from "@chakra-ui/react";
export const SkeletonLoadingProdutos = () => {
	return (

		<VStack h={'full'} w={{ "lg": "70%", "xl": "100%" }} className="animacao-bg" >
			
			<SimpleGrid  pt={20} pl={'5%'} top={'50%'} spacing={15} w={'full'} columns={{ xl: 4, lg: 3 }}>
				
				<HStack spacing={'50%'} w={"full"} >
					<Skeleton borderRadius={5} paddingX={110} h={"350px"} w={"420px"} />
					<Skeleton borderRadius={5} paddingX={110} h={"350px"} w={"420px"} />
					<Skeleton borderRadius={5} paddingX={110} h={"350px"} w={"420px"} />
					<Skeleton borderRadius={5} paddingX={110} h={"350px"} w={"420px"} />
				</HStack>

			</SimpleGrid>
			
		</VStack >


	);
}