import { CheckOutlined, SendOutlined, WechatFilled } from "@ant-design/icons";
import { Box, Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, HStack, IconButton, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Show, Text, Textarea, useDisclosure, VStack } from "@chakra-ui/react";
import { message, notification } from "antd";
import { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { GetChatAnswers } from "../hooks/GetChatAnswers";
import { GetChatMessage } from "../hooks/GetChatMessages";
import { GetEmpresaFornecedor } from "../hooks/GetEmpresaFornecedor";
import { GetMe } from "../hooks/GetMe";
import { ChatRespostas, Empresas, Mensagens } from "../types/types";
import { api } from "../util/api";
import { EmpresasContext } from "./Context/EmpresasContext";
import { LoadingCircle } from "./loading/LoadingCircle";

export const Chat = () => {
  const { me } = GetMe();
  const { empresafornecedor } = GetEmpresaFornecedor();
  const { allMessages, mutateMessages } = GetChatMessage();
  const { allChatAnswers, mutateChatAnswers } = GetChatAnswers();
  const navigate = useNavigate();

  const { isOpen, onOpen, onClose } = useDisclosure()
  const { isOpen: isOpenResponder, onOpen: onOpenResponder, onClose: onCloseResponder } = useDisclosure()


  const { empresaId }: any = useParams();
  const [idEmpresa, setIdEmpresa]: any = useState();
  const empresas: any = useContext(EmpresasContext);
  const [filtroempresa] = empresaId > 0 ? empresas.filter((filter: any) => (filter.id == empresaId)) : [];
  const [filterbyselect] = idEmpresa?.length > 0 ? empresas.filter((filter: any) => (filter.id == idEmpresa)) : [];

  const [email, setEmail]: any = useState();
  const [mensagem, setMensagem]: any = useState('');
  const [resposta, setResposta]: any = useState();
  const [isLoading, setIsLoading]: any = useState();
  const [mensagemresposta, setMensagemResposta]: any = useState();

  function ResponderMensagem(autorMensagem: string, idMensagem: number, mensagem: string, emailFornecedor: string, nome: string) {

    setMensagemResposta({ autorMensagem, idMensagem, mensagem, emailFornecedor, nome })
    onOpenResponder()
  }

  function MarcarComoLida(idMensagem: number) {
    setIsLoading(true)
    api.put('chat/marcarcomolida', { idMensagem }).then(() => {
      mutateMessages()
      mutateChatAnswers()
      message.destroy();
      notification.destroy();
      notification.open({
        duration: 2.5,
        placement: 'top',
        message: <Text textColor={'white'} fontFamily={'Roboto, sans-serif'} fontSize={16}><b>Sucesso!</b></Text>,
        description:
          <Text textColor={'white'} fontSize={16} fontFamily={'Inter, sans-serif'}><b>Mensagem marcada como lida!</b></Text>,
        style: { background: '#4aaf53', borderRadius: 6, boxShadow: '0px 0px 20px 4px #6868688a' },
      });
      setIsLoading(false)

    }).catch((e: any) => {
      message.destroy()
      message.error("Não foi possível marcar essa mensagem como lida!");
    })
    setIsLoading(false)
  }


  function SendMessage(idEmpresa: number, idUsuario: number, nomeUsuario: string, email: string, mensagem: string) {

    // Envia uma mensagem privada para o fornecedor passando todos os parâmetros da requisição no corpo do request

    setIsLoading(true)
    api.post('chat/enviarmensagem', { idEmpresa, idUsuario, nomeUsuario, email, mensagem }).then(() => {
      mutateMessages()
      onClose()
      notification.open({
        message: <Text textAlign={'center'} textColor={'white'}><b>Sucesso!</b></Text>,
        description: <Text textColor={'white'} fontFamily={'Roboto, sans-serif'} fontSize={17}>Sua mensagem foi enviada com sucesso ao fornecedor!</Text>,
        style: { background: '#65c46d', borderRadius: 6, boxShadow: '0px 0px 20px 4px #6868688a' },
      })
      setIsLoading(false)
    })
      .catch((e: any) => {
        if (e.response.status === 401) {

          onClose()
          message.destroy()
          message.error({
            content: 'Por favor, faça login para enviar uma mensagem para o forncedor!',
            className: 'custom-class',
            duration: 2,
            style: {
              fontFamily: 'Roboto, sans-serif',
              fontSize: 15,
              zIndex: 10,

            },

          });
        }
        if (e.response.status === 404) {
          onClose()
          message.destroy()
          message.error({
            content: 'Seu email não foi encontrado no sistema, por favor tente fazer login novamente!',
            className: 'custom-class',
            duration: 2,
            style: {
              fontFamily: 'Roboto, sans-serif',
              fontSize: 15,
              zIndex: 10,

            },

          });
        }
        setIsLoading(false)
      })

  }

  const EnviarRespostaComEnter = (event: any, idMensagem: number, resposta: string, emailFornecedor: string, nome: string) => {
    if (event.key === 'Enter') {
      EnviarResposta(idMensagem, resposta, emailFornecedor, nome)
    }
  }

  function EnviarResposta(idMensagem: number, resposta: string, emailFornecedor: string, nome: string) {
    if (resposta.length < 10) {
      message.destroy();
      message.error("Mensagem muito curta, insira uma mensagem maior!");
      setIsLoading(false)
      return 1;
    }

    else

      setIsLoading(true);
    api.put('chat/enviarresposta', { idMensagem, resposta, emailFornecedor, nome }).then(async () => {
      message.destroy();
      onCloseResponder()
      message.success("Resposta enviada com sucesso!", 3)
      mutateMessages()
      setIsLoading(false)
      setResposta('')

    }).catch((error: any) => {
      if (error.response.status === 500) {
        message.destroy();
        onCloseResponder();
        message.warning("Email não enviado para o cliente!");
        setIsLoading(false);
        mutateMessages()
      }
    })

  }


  return (
    <>
      <VStack position={'fixed'} top={'90%'} left={'48%'} w={'full'} h={'full'}>

        <IconButton
          mr={3}
          right={3}
          bg={'transparent'}
          aria-label=""
          size='lg'
          _hover={{ bg: '#e1ebfc' }}
          _focus={{ bg: 'transparent' }}
          icon={<WechatFilled style={{ fontSize: 40 }} />}
          onClick={onOpen}
        />
      </VStack>

      <Drawer
        isOpen={isOpen}
        placement='right'
        onClose={onClose}
      >
        <DrawerOverlay zIndex={0} />
        <DrawerContent borderRadius={10} boxShadow={'0px 0px 2px 2px #575757'} maxW={'600px'}>
          <DrawerCloseButton />
          <DrawerHeader>Enviar Mensagem</DrawerHeader>

          <DrawerBody w={'full'}>
            {me?.statusCode === 401 ?
              <VStack pt={'15%'}>
                <Text fontFamily={'Inter, sans-serif'} fontSize={16}>Por favor, faça login para enviar uma mensagem para algum fornecedor</Text>
                <Button colorScheme={'blue'} onClick={() => navigate('/login')}>Fazer login</Button>
              </VStack>
              :
              empresafornecedor?.length > 0 && me?.fornecedor === 1 ?
                <VStack pt={5}>
                  <Text fontFamily={'Inter'} textColor={'black'} fontSize={18}><b>{empresafornecedor?.[0]?.title}</b></Text>
                </VStack>



                :
                <>

                  {allChatAnswers?.length > 0 &&
                    allChatAnswers?.map((resposta: ChatRespostas) => {
                      return (
                        <Show key={resposta?.resposta}>
                          <VStack borderRadius={5} bg={'gray.100'} p={3}>
                            <Text pt={5} fontFamily={'Inter'} fontSize={16}>Você possui novas respostas!</Text>
                            <Button variant={'link'} cursor={'pointer'} onClick={() => navigate(`/${resposta?.id_empresa}/lista-produtos`)} textColor={'blue'} fontFamily={'Inter, sans-serif'} fontSize={14}>{resposta?.title}</Button>
                            <HStack borderRadius={5} p={3} _hover={{ bg: '#fafafa' }}>

                              <Text textColor={'gray'} fontFamily={'Inter, sans-serif'} fontSize={14}>[{resposta?.mensagem}] - </Text>
                              <Text textColor={'black'} fontFamily={'Roboto, sans-serif'} fontSize={16}><b>{resposta?.resposta}</b></Text>
                            </HStack>
                            <Button fontSize={14} onClick={() => MarcarComoLida(resposta?.id_mensagem)} variant={"link"} colorScheme={"blue"}>Marcar como lida<CheckOutlined style={{ fontSize: 15, padding: 1 }} /></Button>

                          </VStack>
                        </Show>
                      )
                    })

                  }

                  <Text mt={10} fontFamily={'Roboto, sans-serif'} textColor={'black'} fontSize={17} py={1}>Enviar nova pergunta para fornecedor</Text>
                  <Text mt={2} fontFamily={'Roboto, sans-serif'} textColor={'black'} fontSize={15} py={1}>Insira seu endereço de email</Text>
                  <Input disabled={me?.email?.length > 1 ? true : false} onChange={(e: any) => setEmail(e.target.value)} defaultValue={me?.email} p={2} placeholder="Email"></Input>
                  <Text pt={2} fontFamily={'Roboto, sans-serif'} textColor={'black'} fontSize={16}>Selecione abaixo o fornecedor que deseja enviar uma mensagem ou fazer uma pergunta</Text>
                  <Select onChange={(e: any) => setIdEmpresa(e.target.value)} top={1} defaultValue={filtroempresa?.title} placeholder={empresaId === undefined ? 'Selecionar empresa' : filtroempresa?.title}>
                    {empresaId === undefined &&
                      empresas?.length > 0 && empresas !== undefined &&
                      empresas?.map((empresas: Empresas) => {
                        return (
                          <option key={empresas?.title} value={empresas.id}>{empresas.title}</option>
                        )
                      })

                    }

                  </Select>
                  <Text pt={4} fontFamily={'Roboto, sans-serif'} textColor={'black'} fontSize={16}>Digite no campo abaixo a mensagem que deseja enviar para o fornecedor</Text>

                  <Box bg={'#fafafa'} maxH={'400px'} mt={1} p={3}>
                    <Textarea onChange={(e: any) => setMensagem(e.target.value)} _hover={{}} bg={'transparent'} variant={'filled'} outlineColor={'transparent'} borderColor={'transparent'} _focus={{ borderColor: 'transparent' }} h={'full'} placeholder="Digite sua mensagem aqui"></Textarea>
                  </Box>
                </>
            }
            {me?.statusCode !== 401 && me?.fornecedor > 0 ?
              allMessages?.length > 0 ?
                <Text pt={10} textAlign={'center'} fontFamily={'Roboto, sans-serif'} fontSize={18}>Você tem novas mensagens não respondidas!</Text>
                :
                <Text pt={10} textAlign={'center'} fontFamily={'Roboto, sans-serif'} fontSize={18}>Você não possui novas mensagens!</Text>
              :
              <></>
            }

            {allMessages?.length > 0 ?
              allMessages?.map((mensagens: Mensagens) => {
                return (
                  <Show key={mensagens?.id_mensagem}>
                    <VStack p={3}>
                      <HStack borderRadius={5} p={3} _hover={{ bg: '#fafafa' }}>
                        <Text textColor={'gray'} fontFamily={'Inter, sans-serif'} fontSize={14}>[{mensagens?.nome}] - </Text>
                        <Text textColor={'black'} fontFamily={'Roboto, sans-serif'} fontSize={16}><b>{mensagens?.mensagem}</b></Text>
                        <Button onClick={() => ResponderMensagem(mensagens?.nome, mensagens?.id_mensagem, mensagens?.mensagem, mensagens?.email, me?.name)} fontSize={14} variant={"link"} colorScheme={"blue"}>Responder<SendOutlined style={{ fontSize: 15, padding: 1 }} /></Button>
                      </HStack>

                      <Button fontSize={14} onClick={() => MarcarComoLida(mensagens?.id_mensagem)} variant={"link"} colorScheme={"blue"}>Marcar como lida e não responder<CheckOutlined style={{ fontSize: 15, padding: 1 }} /></Button>
                    </VStack>
                  </Show>
                )
              })
              :
              <></>
            }


          </DrawerBody>

          <DrawerFooter>
            <Button variant='outline' mr={3} onClick={onClose}>
              Cancelar
            </Button>
            <Button isLoading={isLoading ? true : false} disabled={mensagem?.length < 5 || (empresaId === undefined && (idEmpresa === undefined || idEmpresa === '')) ? true : false} onClick={() => SendMessage(empresaId === undefined ? idEmpresa : filtroempresa?.id, me?.id, me?.name, empresaId === undefined ? filterbyselect?.email : filtroempresa?.email, mensagem)} colorScheme='blue'>Enviar</Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>

      <Modal
        isOpen={isOpenResponder}
        onClose={onCloseResponder}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Responder mensagem</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>

            <HStack borderRadius={5} p={3} _hover={{ bg: '#fafafa' }}>
              <Text textColor={'gray'} fontFamily={'Inter, sans-serif'} fontSize={14}>{mensagemresposta?.autorMensagem} - </Text>
              <Text textColor={'black'} fontFamily={'Roboto, sans-serif'} fontSize={16}><b>{mensagemresposta?.mensagem}</b></Text>
            </HStack>
            <Input onKeyPress={(e: any) => EnviarRespostaComEnter(e, mensagemresposta?.idMensagem, resposta, mensagemresposta?.emailFornecedor, me?.name)} onChange={(e: any) => setResposta(e.target.value)} placeholder="Digite a resposta aqui"></Input>
            {isLoading &&
              <LoadingCircle />
            }
          </ModalBody>

          <ModalFooter>
            <Button onClick={() => EnviarResposta(mensagemresposta?.idMensagem, resposta, mensagemresposta?.emailFornecedor, me?.name)} colorScheme='whatsapp' mr={3}>
              Responder
            </Button>
            <Button onClick={onCloseResponder} >Cancelar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}