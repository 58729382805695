import useSWR from "swr";
import { apiEndPoint } from "../util/api";

// Hook usado para buscar o usuário que está logado sendo passado como parâmetro o ID salvo no localStorage

export function GetMe() {
	const token = localStorage.getItem('@App:Access_Token')
	const fetcher = (url: any) => fetch(apiEndPoint + '/me', {
		headers: {
			'Authorization': `Bearer ${token}`,
		}

	}).then(response => response.json());

	const { data, error, mutate } = useSWR(apiEndPoint + 'me', fetcher, { refreshInterval: 30000 })

	return {
		me: data,
		isLoadingMe: !error && !data,
		error: error,
		mutateMe: mutate
	}
}