import { Button, Center, HStack, Image, Text, VStack } from "@chakra-ui/react";
import "@fontsource/roboto";
import "@fontsource/roboto-condensed";
import "@fontsource/rubik";
import { useNavigate } from "react-router-dom";


export const RotaNotFound = () => {

    const navigate = useNavigate()
    return (

        <>

            <VStack>

                <Image w={'full'} h={'full'} position={'absolute'} src={'/ceu estrelado.jpg'} />
                <Image w={'420px'} minW={'120px'} pt={10} cursor={'pointer'} onClick={() => { navigate('/empresas/all') }} position={'relative'} src='/logo branca.svg'></Image>
            </VStack>

            <Center w={'100%'} pr={'20%'} pt={'10%'} >

                <HStack px={5} justify={'left'} align={'center'}>
                    <VStack justify={'center'} align={'initial'}>
                        <Text position={'relative'} fontFamily={'Roboto, sans-serif'} textColor={'rgb(182, 180, 180)'} fontSize={20}> ERRO 404</Text>
                        <Text position={'relative'} fontWeight={100} fontFamily={'Roboto, sans-serif'} textColor={'rgb(255, 255, 255)'} fontSize={48}> Página não encontrada!</Text>
                        <Text w={'80%'} position={'relative'} wordBreak={'break-word'} fontFamily={'Rubik, sans-serif'} textColor={'rgb(226, 226, 226)'} fontSize={18}> Não encontramos a página que você procura, o botão abaixo te redirecionará para a página inicial.</Text>
                        <Button _active={{}} _focus={{}} w={'30%'} h={'40px'} position={'relative'} textColor={'black'} borderRadius={3} top={5} bg={'#ffffff'} fontSize={16} _hover={{ bg: 'rgb(233, 233, 233)' }} onClick={() => { navigate('/empresas/all') }}>Voltar</Button>
                    </VStack>
                </HStack>

            </Center>
        </>

    )
}