import { useMediaQuery } from "@chakra-ui/react"
import { ItemMobile } from "../Mobile/ItemMobile"
import Produtos from "../Produtos"

export const ProdutosResponsivo = () => {
    const [MenorThan1260] = useMediaQuery('(min-width: 1136px)')
    return (
        <>
            {MenorThan1260 ? <Produtos /> : <ItemMobile />}
        </>
    )
}
