import useSWR from "swr";
import { apiEndPoint } from "../util/api";

// Hook usado para buscar as mensagens privadas do usuário

export function GetChatMessage() {
	const token = localStorage.getItem('@App:Access_Token')
	const fetcher = (url: any) => fetch(apiEndPoint + `/chat/mensagens`, {
		headers: {
			'Authorization': `Bearer ${token}`,
		}
	}).then(response => response.json());

	const { data, error, mutate } = useSWR(apiEndPoint + 'chat/mensagens', fetcher, { refreshInterval: 30000 })

	return {
		allMessages: data,
		isLoadingChat: !error && !data,
		error: error,
		mutateMessages: mutate
	}
}