import { LikeOutlined, UserOutlined } from "@ant-design/icons"
import { Avatar, Badge, Button, HStack, Input, InputGroup, Stack, Text, VStack } from "@chakra-ui/react"
import '@fontsource/lato'
import '@fontsource/quando'
import '@fontsource/roboto'
import { message, Progress, Rate } from "antd"
import { useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { GetMe } from "../hooks/GetMe"
import { GetProductRate } from "../hooks/GetProductRate"
import '../Styles/Globalcss.css'
import { Rates } from "../types/types"
import { api } from "../util/api"

export const OpinioesProdutos = () => {
    const { id }: any = useParams();

    const { me } = GetMe();

    const navigate = useNavigate();

    const desc = ['Péssimo', 'Ruim', 'Normal', 'Bom', 'Ótimo'];

    const [opiniao, setOpiniao]: any = useState();

    const [valor, setValor]: any = useState();

    const [isloading]: any = useState();

    const { allRate, mutateRate }: any = GetProductRate(id);

    const calculoMediaTaxa = allRate?.length > 0 ? allRate?.map((rate: any) => rate.taxa) : [];

    var somaRates: any = 0;

    for (let i = 0; i < calculoMediaTaxa?.length; i++) {
        somaRates += calculoMediaTaxa[i] / calculoMediaTaxa?.length;
    }

    const EnviarOpiniaoComEnter = (event: any) => {
        if (event.key === 'Enter') {
            AddProductAvaliation()
        }
    }

    function AddProductAvaliation() {
        if (opiniao === undefined || opiniao.length < 10) {
            message.destroy()
            message.error("Sua opinião sobre esse produto deve ter no mínimo 10 caracteres!")
            return 1;
        }
        if (valor === undefined || valor.length < 1 || valor === 0) {
            message.destroy()
            message.error("Por favor, selecione a quantidade de estrelas que deseja atribuir ao produto !")
            return 1;
        }
        else
            api.post('/rate/addrate', { id, valor, opiniao }).then(() => {
                message.destroy()
                message.success("Sua opinião foi registrada com sucesso ao produto!")
                setOpiniao('')
                mutateRate()
            }).catch((e: any) => {
                message.destroy()
                message.error("Não foi possível enviar sua avaliação sobre esse produto, por favor tente mais tarde!")
            })
    }

    return (
        <>
            <Text fontFamily={'Roboto, sans-serif'} textColor={'black'} fontSize={22} p={3}>Opiniões do produto {allRate?.length > 0 ? `(${allRate?.length})` : ''}</Text>
            {me?.statusCode === 401 ?
                <VStack >
                    <Text fontFamily={'Inter, sans-serif'} fontSize={16}>Por favor, faça login para enviar sua opinião sobre esse produto</Text>
                    <Button colorScheme={'blue'} onClick={() => navigate('/login')}>Fazer login</Button>
                </VStack>
                :
                <Stack boxShadow={'0px 0px 8px 5px #eeeeee86;'} borderRadius={10} bg={'#fdfdfd'}>

                    <Text fontFamily={'Roboto, sans-serif'} textColor={'black'} fontSize={16} p={2}><b>Selecione a quantidade de estrelas que você deseja atribuir a esse produto</b></Text>

                    <HStack pl={5} >
                        <Rate allowClear={false} style={{ color: 'blue', padding: 6, fontSize: 25 }} tooltips={desc} onChange={setValor} value={valor} />
                        {valor ? <Text p={3} fontFamily={'Inter'} fontSize={14}>{desc[valor - 1]}</Text> : ''}
                    </HStack>

                    <Text fontFamily={'Roboto, sans-serif'} textColor={'black'} fontSize={16} py={4} px={2}><b>Digite sua opinião sobre esse produto</b></Text>

                    <InputGroup p={2} w={'70%'} size='lg'>

                        <Input onKeyPress={(e: any) => EnviarOpiniaoComEnter(e)} value={opiniao} _hover={{}} _active={{}} _focus={{}} onChange={(e: any) => setOpiniao(e.target.value)} maxLength={100} fontSize={16} placeholder='Digite sua opinião sobre esse produto aqui' />
                        <Button onClick={() => AddProductAvaliation()} _hover={{ bg: '#345ed1' }} isLoading={isloading ? true : false} zIndex={0} borderRadius={3} cursor={'pointer'} fontSize={20} bg={'blue.500'} children={<LikeOutlined style={{ color: 'white', borderRadius: 5 }} />} />
                    </InputGroup>
                </Stack>
            }

            {allRate?.length > 0 ?
                <>
                    <Text textAlign={'center'} fontFamily={'Roboto, sans-serif'} textColor={'black'} fontSize={20} p={2}>Todas avaliações desse produto:</Text>
                    <VStack borderRadius={10} bg={'#f7f7f7'} boxShadow='0px 0px 10px 3px #f7f7f7' align={'center'}>
                        <Text fontSize={20} fontFamily={'Inter, sans-serif'}>Média geral</Text>
                        <Text fontSize={30} fontWeight={700} fontFamily={'Inter, sans-serif'} textColor={somaRates === 3 ? 'yellow.400' : somaRates < 3 ? 'red' : somaRates >= 4 ? 'green' : ''} >{somaRates.toFixed(1)}</Text>

                        <HStack align={'initial'} ml={5}>
                            <div style={{ width: 170 }}>
                                <Progress type="line" strokeColor={{ '0%': '#108ee9', '100%': '#87d068' }} style={{ padding: 10 }} percent={somaRates.toFixed(1) * 10} size="default" status="active" />
                            </div>
                        </HStack>

                        <Rate allowHalf style={{ color: 'blue', fontSize: 20 }} disabled value={somaRates} />

                        <Text textColor={'#949494'} fontSize={18} lineHeight={1.4} >{allRate?.length > 1 ? ` ${allRate.length} avaliações` : `${allRate.length} avaliação `}</Text>
                    </VStack>

                </>
                :
                <Text textAlign={'center'} fontFamily={'Roboto, sans-serif'} textColor={'black'} fontSize={20} p={2}>Esse produto ainda não possui nenhuma avaliação</Text>
            }

            {allRate?.statusCode === 400 ?
                <></>
                :
                <VStack pl={3} align={'initial'}>
                    {allRate?.map((rates: Rates) => (
                        <Stack py={2} key={rates.id} >
                            <Rate disabled style={{ color: 'blue' }} value={rates.taxa} />
                            <HStack wordBreak={'break-word'}>
                                {rates?.avatar?.length > 15 ?
                                    <Avatar size={'sm'} bg={'transparent'} src={rates.avatar}></Avatar>
                                    :
                                    <UserOutlined style={{ fontSize: 20 }} />
                                }

                                <Badge borderRadius={5} p={1} colorScheme={rates?.taxa < 3 ? 'red' : rates?.taxa === 3 ? 'yellow' : rates.taxa >= 4 ? 'green' : ''} >

                                    <HStack>
                                        <Text fontSize={13} fontFamily={'Roboto, sans-serif'}>[{rates.nome}]</Text>
                                        <Text whiteSpace={'break-spaces'} overflowWrap={'break-word'} fontSize={12} fontFamily={'Inter, sans-serif'} >{rates.avaliacao}</Text>
                                    </HStack>

                                </Badge>
                            </HStack>
                        </Stack>
                    ))}
                </VStack>
            }
        </>
    )
}