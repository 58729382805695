import { Box, Button, Input, Text, VStack } from "@chakra-ui/react";
import { message } from "antd";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Footer } from "../components/Footer";
import { NavBarLogin } from "../components/NavBarLogin";
import { api, apiEndPoint } from "../util/api";

export const RedefinirSenha = () => {
    const navigate = useNavigate()
    const { param_token } = useParams()
    const [emailuser, setEmailUser] = useState()

    function delay(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const RedefinirSenhaComEnter = (event: any) => {
        if (event.key === 'Enter') {
            RegisterNewPassword();
        }
    }

    fetch(apiEndPoint + '/me', {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + param_token
        }
    }).then(response => response.json()).then((response: any) => {

        if (response.statusCode === 401) {

            navigate('/')
        }
        else {

            setEmailUser(response.email)
        }

    })

    const [alterarSenhaIsLoading, setAlterarSenhaIsLoading] = useState(false);
    const [password, setNewpassword]: any = useState();
    const [confirmpassword, setConfirmpassword]: any = useState();

    function RegisterNewPassword() {

        
        setAlterarSenhaIsLoading(true)
        if (password !== confirmpassword) {
            message.destroy()
            message.error("As senhas não coincidem!", 4)
            setAlterarSenhaIsLoading(false)
            return 0;
        }
        if (password.length < 8) {
            message.destroy()
            message.error("Senha muito curta, por favor insira uma senha maior!")
            setAlterarSenhaIsLoading(false)
            return 1;
        }
        api.post('alterarsenha', { password, emailuser }).then(async () => {
            message.destroy()
            message.success("Sua senha foi alterada com sucesso!");
            setAlterarSenhaIsLoading(false)
            message.loading("Redirecionando para a página de login...")
            localStorage.clear()
            await delay(2000)
            navigate('/login')
        }).catch((error: any) => {
            message.error("Não foi possível alterar sua senha!");
            setAlterarSenhaIsLoading(false)

        })
    }

    return (
        <>
            <NavBarLogin />

            <VStack position={'fixed'} w={'full'} h={'full'} spacing={0} bgColor={'gray.100'}>
                <Box maxW={'600px'} p={10} mt={'4%'} borderRadius={8}>
                    <VStack>

                        <Text p={5} whiteSpace={'break-spaces'} fontFamily={'Roboto, sans-serif'} color={'black'} fontSize={25}><b>Alteração de senha Carrinho de Compras</b></Text>
                        <Text whiteSpace={'break-spaces'} fontFamily={'Inter, sans-serif'} py={2} color={"gray.600"} fontSize={18}><b>Para continuar, digite sua nova senha!</b></Text>

                        <Input type={'password'} onChange={(e: any) => setNewpassword(e.target.value)} fontFamily={'Poppins, sans-serif'} w={'100%'} mt={9} placeholder={'Senha:'}></Input>

                        <Input onKeyPress={(e: any) => RedefinirSenhaComEnter(e)} type={'password'} onChange={(e: any) => setConfirmpassword(e.target.value)} fontFamily={'Poppins, sans-serif'} w={'100%'} mt={9} placeholder={'Confirmação da senha:'}></Input>

                        <Button isLoading={alterarSenhaIsLoading ? true : false} onClick={RegisterNewPassword} top={5} colorScheme={'blue'} >Confirmar alteração de senha</Button>

                    </VStack>
                </Box>
                <VStack pt={'3%'} w={'full'}>
                    <Footer />
                </VStack>
            </VStack>
        </>
    )

}