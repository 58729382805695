import '@fontsource/lato'
import '@fontsource/quando'
import '@fontsource/roboto'
import { AxiosResponse } from "axios"
import { useEffect, useState } from "react"
import { useParams } from 'react-router-dom'
import { SkeletonLoadingDetail } from '../components/loading/SkeletonLoadingDetail'
import { api, apiEndPoint } from '../util/api'
import { ProductExpanded } from './ProductExpanded'
import { RotaNotFound } from './RotaNotFound'

export const Details = () => {

    //esse "id" deve ser o mesmo nome que é colocado na varivavel do parametro na RouteComponents (/:id)
    const { id } = useParams();
    const { empresaId } = useParams();

    const [produto, setProduto] = useState<| null>(null);
    const [isProductLoading, setIsProductLoading] = useState(false);
    const [errorObj, setErrorObj] = useState<string>();

    useEffect(() => {
        window.scrollTo(0, 0);
        setIsProductLoading(true);
        const route: string = apiEndPoint;
        api.get(route + `/produtos/${empresaId}/` + id)
            .then((response: AxiosResponse<any, any>) => {

                setProduto(response.data)
                setIsProductLoading(false);
            }).catch((error: any) => {

                setIsProductLoading(false);

                if (error.response) {
                    // Requisição feita e o server respondeu
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    setErrorObj(error.response.data)
                } else if (error.request) {
                    // A requisição foi feita mas não houve nenhuma resposta
                    console.log(error.request);
                    setErrorObj(error.Request)
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                    setErrorObj(error.message)
                }
            });
    }, [id, empresaId])

    return (

        <>
            {isProductLoading ?
                <SkeletonLoadingDetail />
                :
                produto == null ?

                    <RotaNotFound />
                    :
                    <ProductExpanded product={produto} />

            }
        </>

    )
}