import { message } from "antd";
import { createContext, ReactNode, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "../../util/api";
import { SkeletonLoadingTopRatedProducts } from "../loading/SkeletonLoadingTopRatedProducts";
import { SkeletonResponsivo } from "../Responsivos/SkeletonResponsivo";

export const ProdutosContext = createContext<any[]>([]);


interface ProdutosProviderProps {
    children: ReactNode

}


export function ProdutosProvider({ children }: ProdutosProviderProps) {
    const navigate = useNavigate();
    const { empresaId, page } = useParams();
    
    const [isProductLoading, setIsProductLoading] = useState(false);
    const [produtos, setProdutos] = useState<any[]>([]);

    useEffect(() => {

        // Busca todos os produtos de uma empresa passando o ID da empresa o parâmetro "empresaId" e retorna esses produtos dentro do provider

        setIsProductLoading(true);
        api.get(`/produtos/${empresaId}?page=${page}`)
            .then(async (response: any) => {
                setProdutos(response.data)
                setIsProductLoading(false)
            })
            .catch((error: any) => {
                if (error.response.status === 404) {
                    message.destroy()
                    message.error(`Não foi possível encontrar produtos na empresa ${empresaId} `)
                    setIsProductLoading(false)
                    navigate('/*')
                }
                if (error.response.status === 400) {
                    setIsProductLoading(false)
                }

            });
    }, [navigate, empresaId])

    return (

        <ProdutosContext.Provider value={produtos}>
            {isProductLoading &&
                <>
                    <SkeletonLoadingTopRatedProducts />
                    <SkeletonResponsivo />
                </>
            }
            {children}
        </ProdutosContext.Provider>
    )
}