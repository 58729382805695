import useSWR from "swr";
import { apiEndPoint } from "../util/api";

// Hook usado para buscar a empresa do fornecedor sendo passada através do ID

export function GetEmpresaFornecedor() {
	const token = localStorage.getItem('@App:Access_Token')
	const fetcher = (url: any) => fetch(apiEndPoint + `/empresas/empresafornecedor`, {
		headers: {
			'Authorization': `Bearer ${token}`,
		}
	}).then(response => response.json());

	const { data, error, mutate } = useSWR(apiEndPoint + 'empresas/empresafornecedor', fetcher)

	return {
		empresafornecedor: data,
		isLoadingEmpresa: !error && !data,
		error: error,
		mutateEmpresa: mutate
	}
}