import { message } from "antd";
import { createContext, ReactNode, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { api } from "../../util/api";

export const CartContext = createContext<any[]>([]);

interface CartProviderProps {
    children: ReactNode
}

export function CartProvider({ children }: CartProviderProps) {
    const navigate = useNavigate();
    const [carts, setCarts] = useState<any[]>([]);
    
    useEffect(() => {

        // Buscará o carrinho correspondente ao ID do usuário logado, retornando-o dentro do provider

        api.get("/user/cart")
            .then((response: any) => {
                setCarts(response.data.products)

            }).catch((error: any) => {
                if (error.response.status === 503) {
                    message.destroy()
                    message.error('Não foi possível conectar ao servidor!')
                    navigate('/*');
                }
            });

    }, [navigate])

    return (

        <CartContext.Provider value={carts}>
            {children}
        </CartContext.Provider>
    )
}
