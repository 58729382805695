import { Empresas } from "../pages/Empresas";
import { Chat } from "./Chat";
import { EmpresasProvider } from "./Context/EmpresasContext";
export const EmpresasProvidersPage = () => {

	return (
		<EmpresasProvider>
			<Empresas />
			<Chat />
		</EmpresasProvider>

	);
}