import { Box, Button, Center, Image, Input, Text, VStack } from "@chakra-ui/react";
import { message } from "antd";
import { motion } from "framer-motion";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { api } from "../util/api";
export const LoginAdm = () => {

    const [loginIsLoading, setIsLoadingLogin] = useState(false);
    const [emailEmpty, setEmailEmpty] = useState(false)
    const [senhaEmpty, setSenhaEmpty] = useState(false)
    const [email, setEmail] = useState('');
    const [senha, setSenha] = useState('');
    const navigate = useNavigate()

    const handleKeyPress = (event: any) => {
        if (event.key === 'Enter') {
            LoginUser()
        }
    }

    const LoginUser = async () => {
        if (email.length === 0 || senha.length === 0) {
            setEmailEmpty(true)
            setSenhaEmpty(true)
            return 1;
        }
        else
            setIsLoadingLogin(!loginIsLoading);

        await api.post("/admin/login", { email, senha })
            .then((response: any) => {
                localStorage.setItem('AdmToken', response.data.access_token)
                message.destroy()
                message.success("Acesso permitido!")
                window.location.href = '/adm'
                setIsLoadingLogin(false)
            })
            .catch((e: any) => {
                message.destroy()
                message.error("Login ou senha incorretos!")
                setIsLoadingLogin(false)
            })
    }


    return (
        <>
            <Image _active={{}} _focus={{}} w={'full'} h={'full'} position={'absolute'} src='/stars.jpg'></Image>

            <motion.div
                className="box"
                initial={{ opacity: 0, scale: 0.2 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{
                    duration: 1.0,
                    delay: 0.1,
                    ease: [0, 0.71, 0.2, 1.01]
                }}
            >
                <Center >
                    <Box minW={'350px'} w={'40%'} p={10} mt={'4%'} position={'relative'} bgColor={'white'} boxShadow='0px 0px 10px 3px #FFFFFF' borderRadius={6}>
                        <Text textAlign={'center'} position={'relative'} whiteSpace={'break-spaces'} fontFamily={'Inter, sans-serif'} py={1} color={"black"} fontSize={25}><b>Acesso a área de administrador</b></Text>
                        <Text textAlign={'center'} position={'relative'} whiteSpace={'break-spaces'} fontFamily={'Inter, sans-serif'} py={2} color={"black"} fontSize={17}><b>Para acessar digite seu usuário e senha de administrador</b></Text>

                        <Input bg={'transparent'} _hover={{}} textColor={'black'} onKeyPress={handleKeyPress} autoFocus maxLength={40} variant={'filled'} type={'email'} borderColor={emailEmpty && email.length === 0 ? 'red' : 'gray.100'} onChange={(e: any) => setEmail(e.target.value)} fontFamily={'Poppins, sans-serif'} w={'100%'} mt={5} placeholder={'Email:'}></Input>
                        {emailEmpty && email.length === 0 && <Text textColor={'red'} fontFamily={'Inter, sans-serif'} fontSize={14}>Digite um email</Text>}
                        <Input bg={'transparent'} _hover={{}} textColor={'black'} onKeyPress={handleKeyPress} maxLength={40} variant={'filled'} borderColor={senhaEmpty && senha.length === 0 ? 'red' : 'gray.100'} type={'password'} onChange={(e: any) => setSenha(e.target.value)} fontFamily={'Poppins, sans-serif'} w={'100%'} mt={2} placeholder={'Senha:'}></Input>
                        {senhaEmpty && senha.length === 0 && <Text position={'relative'} textColor={'red'} fontFamily={'Inter, sans-serif'} fontSize={14}>Digite uma senha</Text>}

                        <Button textColor={'white'} _loading={{ bgColor: '#40c955' }} _focus={{ bgColor: '#4067c9' }} _hover={{ bgColor: '#319de0' }} isLoading={loginIsLoading} onClick={LoginUser} w={'100%'} fontFamily={'Roboto, sans-serif'} bgColor={'#4067c9'} p={2} top={1} >Entrar</Button>
                    </Box>
                </Center>
            </motion.div>

            <VStack>
                <Image fallbackStrategy="onError" onError={() => window.location.reload()} cursor={'pointer'} onClick={() => navigate('/empresas/all')} position={'absolute'} _active={{}} _focus={{}} mt={'10%'} w={'400px'} src='/logo branca.svg'></Image>
            </VStack>


        </>


    )
}