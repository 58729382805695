import { VStack } from '@chakra-ui/react';
import { Details } from '../components/Details';

export const Compras = () => {

    return (
        <VStack bg={"gray.200"} w={"full"} pt={'3%'}>
            <Details />
        </VStack>
    )
}