import { useMediaQuery } from "@chakra-ui/react"
import { CarrinhoComprasPage } from "../../pages/CarrinhoComprasPage"
import { CarrinhoComprasPageMobile } from "../Mobile/CarrinhoComprasPageMobile"

export const CarrinhoComprasPageReponsivo = () => {
    const [MenorThan1260] = useMediaQuery('(min-width: 1136px)')
    return (

        <>
            {MenorThan1260 ? <CarrinhoComprasPage /> : <CarrinhoComprasPageMobile />}
        </>
    )
}
