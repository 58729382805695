import useSWR from "swr";
import { apiEndPoint } from "../util/api";

// Hook usado para buscar o carrinho do usuário através do ID do usuário

export function GetCarrinho() {
	const token = localStorage.getItem('@App:Access_Token')
	const fetcher = (url: any) => fetch(apiEndPoint + `/user/cart`, {
		headers: {
			'Authorization': `Bearer ${token}`,

		}
	}).then(response => response.json());

	const { data, error, mutate } = useSWR(apiEndPoint + 'user/cart', fetcher)

	return {
		dataCarrinho: data,
		isLoadingCarrinho: !error && !data,
		error: error,
		mutateCarrinho: mutate
	}
}
