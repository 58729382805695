import { Image, VStack } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
export const NavBarLogin = () => {
    const navigate = useNavigate()
    return (

        <VStack bg={"#3a5fcb"} pt={"5px"} w={"full"} h={"100px"} fontFamily={"Inter"}>
            <Image onClick={() => { navigate('/empresas/all'); }} cursor={"pointer"} width={'250px'} src='/logo branca.svg'></Image>
        </VStack>

    )
}