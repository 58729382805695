/* eslint-disable @typescript-eslint/no-unused-vars */
import { SearchOutlined } from '@ant-design/icons'
import { Box, Divider, HStack, Image, Input, InputGroup, InputLeftElement, Modal, ModalBody, ModalContent, ModalOverlay, Show, Text, useDisclosure, VStack } from '@chakra-ui/react'
import { Popover } from 'antd'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import '../Styles/Globalcss.css'
import { ItensLista } from '../types/types'
import { api } from '../util/api'

export function BuscaEmpresas() {

    const { isOpen, onOpen, onClose } = useDisclosure();
    const [lista, setLista]: any = useState();

    const navigate = useNavigate();

    function CloseModal() {
        onClose();
        setLista()
    }


    // Toda vez que o usuário pressionar as teclas (Ctrl + Q) o app abrirá a Modal de busca de empresas
    let keys = {
        a: false,
        s: false,
    };

    document.addEventListener("keydown", (event) => {
        if (event.keyCode === 17) {
            keys.a = true;
        }
        if (event.key === "q") {
            keys.s = true;
        }

        if (keys.a && keys.s) {
            onOpen()
        }

    });

    document.addEventListener("keyup", (event) => {
        if (event.keyCode === 17) {
            keys.a = false;
        }
        if (event.key === "q") {
            keys.s = false;
        }
    });


    function SelecionarEmpresaComEnter(e: any) {
        if (e.keyCode === 13) {
            if (lista?.[0]?.id !== undefined)
                seeProductDetails(lista?.[0]?.id)

        }
    }


    async function SearchEmpresaByName(buscaempresa: any) {

        // Função que irá buscar a empresa de acordo com o nome da empresa digitada pelo usuário e mostrará a lista como resposta da requisição

        if (buscaempresa.length > 0) {

            await api.post('/empresas/buscaempresas', { buscaempresa }).then((response: any) => {

                if (response?.data?.length > 0) {
                    setLista(response.data);

                }
                else
                    setLista()

            })

        }
        else
            setLista()

    }

    function seeProductDetails(empresaId: any) {
        //Navega para uma rota específica passando o Id do produto como parâmetro.
        navigate(`/${empresaId}/page=1/lista-produtos/`);
        onClose();
        setLista()

    }

    return (
        <>
            <Popover placement='bottom' content={''} title={"Dica: Pressione Ctrl + Q a qualquer momento para abrir a busca de empresas"}>
                <InputGroup>
                    <InputLeftElement
                        pointerEvents='none'

                        children={<SearchOutlined color='gray.300' />}
                    />
                    <Input borderRadius={7} boxShadow={'0px 0px 20px 8px #57575785'} cursor={'pointer'} onClick={onOpen} size={'md'} bg={'white'} placeholder='Pesquisar empresas...' ></Input>
                </InputGroup>
            </Popover>

            <Modal size={'2xl'} blockScrollOnMount={false} isOpen={isOpen} onClose={CloseModal}>
                <ModalOverlay bg={'blackAlpha.500'} />
                <ModalContent top={'3%'}>
                    <ModalBody maxH={'600px'} p={0} overflowY={lista?.length < 4 || lista === undefined ? 'unset' : 'scroll'}>
                        <VStack>
                            <InputGroup boxShadow={'0px 0px 22px -4px #000000;'} p={2} >
                                <InputLeftElement
                                    pointerEvents='none'
                                    top={3}
                                    ml={1}
                                    color='green'
                                    fontSize={22}
                                    children={<SearchOutlined />}
                                />

                                <Input onKeyDown={(e: any) => SelecionarEmpresaComEnter(e)} fontFamily={'Roboto, sans-serif'} maxLength={20} _hover={{}} focusBorderColor='unset' h={'50px'} borderRadius={8} fontSize={18} borderColor={'transparent'} autoFocus onChange={(e: any) => SearchEmpresaByName(e.target.value)} placeholder='Digite o nome da empresa que deseja buscar'></Input>

                            </InputGroup>
                            {lista !== undefined &&
                                <Divider w={'90%'} pt={1} borderColor={'gray.300'} />
                                
                            }
                            {lista?.map((item: ItensLista) => {
                                return (
                                    <Show key={item?.id}>
                                        <Box _hover={{ bg: '#f0f0f0' }} h={'65px'} borderRadius={3} w={'full'} bg={'white'}>

                                            <HStack _active={{ bg: '#D9D4D4' }} p={3} h={'full'} cursor={'pointer'} justify={'center'} align={'center'} onClick={() => seeProductDetails(item.id)}>
                                                <Text pl={2} w={'full'} fontSize={17} fontFamily={'Inter, sans-serif'}>{item.title}</Text>
                                                <Image minW={100} fit={'contain'} maxH={'50px'} w={'12%'} src={item.image}></Image>
                                            </HStack>
                                        </Box>
                                    </Show>
                                )
                            })}

                        </VStack>
                    </ModalBody>

                </ModalContent>
            </Modal>
        </>
    )
}

