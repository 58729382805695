/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { CheckCircleTwoTone, CloseCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import { Box, Button, HStack, Input, InputGroup, InputRightElement, SimpleGrid, Spacer, Text, VStack } from '@chakra-ui/react';
import { Stepper } from "@mantine/core";
import { message, notification, Popover } from "antd";
import axios from "axios";
import { motion } from 'framer-motion';
import { useState } from "react";
import GoogleLogin from "react-google-login";
import ReactInputMask from 'react-input-mask';
import { useNavigate } from "react-router-dom";
import { Footer } from "../components/Footer";
import { NavBarLogin } from "../components/NavBarLogin";
import '../Styles/Globalcss.css';
import { Endereco } from '../types/types';
import { api } from "../util/api";
export const SignUp = () => {


	const [active, setActive] = useState(1);
	const [nome, setNome] = useState('')
	const [datanasc, setDataNasc] = useState('')
	const [rua, setRua] = useState('')
	const [cep, setCep] = useState('')
	const [numero, setNumero] = useState('')
	const [cidade, setCidade] = useState('')
	const [estado, setEstado] = useState('')
	const [bairro, setBairro] = useState('')
	const [cpfcnpj, setCpfCnpj]: any = useState('')
	const [inscricaoestadual, setInscricaoEstadual] = useState('')
	const [email, setEmail] = useState('')
	const [emailExistente, setEmailExistente]: any = useState()
	const [telefone, setTelefone] = useState('')
	const [senha, setSenha] = useState('')
	const [name, setName] = useState()
	const [googleId, setGoogleId] = useState('')
	const [imageUrl, setImageUrl] = useState()
	const navigate = useNavigate()
	const [passoAtual, setPassoAtual] = useState(1);
	const [empty, setEmpty]: any = useState(false)
	const [cnpjvalido, setCnpjvalido]: any = useState(false)



	const quantidadeTotalPassos = 3;

	const data = {
		nome,
		rua,
		cep,
		numero,
		cidade,
		estado,
		bairro,
		cpfcnpj,
		email,
		senha,
		datanasc,
		inscricaoestadual,
		telefone
	}


	const [loginIsLoading, setIsLoadingLogin] = useState(false);
	const [loadingBuscaEmail, setLoadingBuscaEmail]: any = useState()

	function delay(ms: number) {
		return new Promise(resolve => setTimeout(resolve, ms));
	}

	const nextStep = () => setActive((current) => (current < 3 ? current + 1 : current));
	const prevStep = () => setActive((current) => (current >= 2 ? current - 1 : current));
	const url = "https://viacep.com.br/ws/" + cep + "/json/";



	const EnviarComEnter = (event: any) => {
		if (event.key === 'Enter') {
			FazerCadastro()
		}
	}
	function SetAddress() {
		axios.get(url).then(result => {

			const data: Endereco = result.data;
			setBairro(data.bairro);
			setCidade(data.localidade)
			setRua(data.logradouro)
			setEstado(data.uf)

		}).catch(error => {
			console.log("Ocorreu um erro, ", error)
		});
	}



	function VerifyEmailExistente() {
		api.post('verificaremailexistente', { email }).then((response: any) => {


			if (response.data.length > 0) {

				setEmailExistente(1)
				setLoadingBuscaEmail(false)

			}
			else
				setEmailExistente(0)
			setLoadingBuscaEmail(false)

		})
		delay(10000000)

	}




	function FazerCadastro() {

		setIsLoadingLogin(!loginIsLoading);
		if (senha.length < 8) {
			message.destroy()
			message.error("Senha muito curta, por favor insira uma senha maior!")
			setIsLoadingLogin(false)
			return 1;
		}

		else
			localStorage.clear()
		api.post('/auth/cadastro', { data }).then(async (result: { data: { access_token: string; email: string }; }) => {
			localStorage.setItem('@App:Access_Token', result.data.access_token);
			message.success('Usuário cadastrado com sucesso!', 0.8)
			await delay(100)
			message.destroy()
			notification.open({
				duration: 2.5,
				placement: 'top',
				message: <Text textColor={'white'} fontFamily={'Roboto, sans-serif'} fontSize={16}><b>Logado com sucesso!</b></Text>,
				description:
					<Text textColor={'white'} fontSize={16} fontFamily={'Inter, sans-serif'}><b>Seja bem vindo!</b></Text>,
				style: { background: '#4aaf53', borderRadius: 6, boxShadow: '0px 0px 20px 4px #6868688a' },
			});
			await delay(100)
			setIsLoadingLogin(false)

			await api.get("/me").then(async (result: { data: { name: string;[0]: any }; }) => {
				localStorage.setItem('@App:NomeUser', result.data.name)
				navigate('/');
			})
			// Caso ele não encontre o usuário no banco de dados ele retorna um unauthorized e não permite o registro do usuário
		}).catch((error: any) => {


			message.error('Não foi possível fazer o seu cadastro, por favor verifique seus dados e tente novamente!', 2.0)
			setIsLoadingLogin(false)
		})
	}

	const FazerCadastroGoogle = (response: any) => {

		// Efetua o cadastro do usuário, caso ele retorne algum erro provavelmente o email já está cadastrado no banco (UNIQUE KEY) e então ele retorna uma mensagem de erro
		const { profileObj:
			{ name, imageUrl, googleId }
		}
			= response
		setName(name);
		setImageUrl(imageUrl);
		setGoogleId(googleId);
		api.post('/auth/cadastro-google', { response }).then(async (result: any) => {

			localStorage.clear();
			localStorage.setItem('@App:googleId', googleId);
			localStorage.setItem('@App:NomeUser', name);
			localStorage.setItem('@App:ImageUser', imageUrl);
			localStorage.setItem('@App:Access_Token', result.data.access_token);
			navigate('/cadastro-google');
		}).catch((error: any) => {
			if (error.response.status === 401) {
				setIsLoadingLogin(false)
				message.error("Email ou senha já cadastrados, por favor utilize outra conta!");
			}
			else {

				setIsLoadingLogin(false)
				message.error("Erro ao cadastrar email, por favor tente novamente mais tarde");
			}
		})
	}


	function validCpf(cpf: any) {
		if (!cpf || cpf.length !== 11
			|| cpf === "00000000000"
			|| cpf === "11111111111"
			|| cpf === "22222222222"
			|| cpf === "33333333333"
			|| cpf === "44444444444"
			|| cpf === "55555555555"
			|| cpf === "66666666666"
			|| cpf === "77777777777"
			|| cpf === "88888888888"
			|| cpf === "99999999999")
			return false
		var soma = 0
		var resto
		for (var i = 1; i <= 9; i++)
			soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i)
		resto = (soma * 10) % 11
		if ((resto === 10) || (resto === 11)) resto = 0
		if (resto !== parseInt(cpf.substring(9, 10))) return false
		soma = 0
		for (var i = 1; i <= 10; i++)
			soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i)
		resto = (soma * 10) % 11
		if ((resto === 10) || (resto === 11)) resto = 0
		if (resto !== parseInt(cpf.substring(10, 11))) return false
		return true
	}

	function validarCNPJ(cnpj: any) {

		cnpj = cnpj.replace(/[^\d]+/g, '');

		if (cnpj == '') return false;

		if (cnpj.length != 14)
			return false;

		// LINHA 10 - Elimina CNPJs invalidos conhecidos
		if (cnpj == "00000000000000" ||
			cnpj == "11111111111111" ||
			cnpj == "22222222222222" ||
			cnpj == "33333333333333" ||
			cnpj == "44444444444444" ||
			cnpj == "55555555555555" ||
			cnpj == "66666666666666" ||
			cnpj == "77777777777777" ||
			cnpj == "88888888888888" ||
			cnpj == "99999999999999")
			return false; // LINHA 21

		// Valida DVs LINHA 23 -
		var tamanho = cnpj.length - 2
		var numeros = cnpj.substring(0, tamanho);
		var digitos = cnpj.substring(tamanho);
		var soma = 0;
		var resultado;
		var i;
		var pos = tamanho - 7;
		for (i = tamanho; i >= 1; i--) {
			soma += numeros.charAt(tamanho - i) * pos--;
			if (pos < 2)
				pos = 9;
		}
		resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
		if (resultado != digitos.charAt(0))
			return false;

		tamanho = tamanho + 1;
		numeros = cnpj.substring(0, tamanho);
		soma = 0;
		pos = tamanho - 7;
		for (i = tamanho; i >= 1; i--) {
			soma += numeros.charAt(tamanho - i) * pos--;
			if (pos < 2)
				pos = 9;
		}
		resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
		if (resultado != digitos.charAt(1))
			return false; // LINHA 49

		return true; // LINHA 51

	}

	function IsCEP(strCEP: any) {
		if (strCEP.length < 8
			||
			strCEP === '00000000'
			|| strCEP === '11111111'
			|| strCEP === '22222222'
			|| strCEP === '33333333'
			|| strCEP === '44444444'
			|| strCEP === '55555555'
			|| strCEP === '66666666'
			|| strCEP === '77777777'
			|| strCEP === '88888888'
			|| strCEP === '99999999'
		) {
			return false;
		}
		else
			return true;



	}




	const successo = (
		<div>
			<p>Você pode cadastrar sua conta usando esse email!</p>
		</div>
	);
	const informacao = (
		<div>
			<p>Por favor, digite seu email completo para continuar!</p>
		</div>
	);
	const erro = (
		<div>
			<p>Esse email já está sendo usado!</p>
		</div>
	);

	const informacaoCPF = (
		<div>
			<p>Por favor, digite seu CPF ou CNPJ completo para continuar!</p>
		</div>
	);


	return (
		<>
			<VStack bgColor={'gray.100'} spacing={'2.2%'} w='100%'>
				<NavBarLogin />

				<Box w={'full'} maxW={'650px'} bgColor={'#ffffff'} padding={2} boxShadow='0px 0px 20px 4px #8d8d8d87;' borderRadius={20}>
					<motion.div
						className="box"
						initial={{ opacity: 0, scale: 0.5 }}
						animate={{ opacity: 1, scale: 1 }}
						transition={{
							duration: 0.5,
							delay: 0.1,
							ease: [0, 0.71, 0.2, 1.01]
						}}
					>
						<VStack padding={10} mt={1} >

							<Text fontFamily={'Inter, sans-serif'} color={"gray.600"} fontSize={26}><b>Criar uma nova conta</b></Text>
							<Text fontFamily={'Inter'} color={"gray.400"} >É rápido e fácil</Text>

							<Stepper py={10} active={active} breakpoint="xs">
								<Stepper.Step label="Passo 1" description="Nome, email, telefone e data de nascimento">
								</Stepper.Step>

								<Stepper.Step label="Passo 2" description="CPF/CNPJ e senha">
									Passo 1 conteúdo: Nome, email, telefone e data de nascimento
								</Stepper.Step>

								<Stepper.Step label="Passo 3" description="Endereço">
									Passo 2 conteúdo: Senha, inscrição estadual e CPF/CNPJ
								</Stepper.Step>

								<Stepper.Completed>
									Passo 3 conteúdo: Endereço
								</Stepper.Completed>
							</Stepper>

							{active === 1 ?
								<>
									<HStack w={'full'}>
										<InputGroup>
											<InputRightElement
												pointerEvents='none'
												children={<Text fontSize={30}>*</Text>}
											/>
											<Input value={nome} onChange={(e: any) => setNome(e.target.value)} maxLength={45} fontSize={18} fontFamily={'Poppins, sans-serif'} w={'100%'} placeholder={'Digite seu nome completo'}></Input>
										</InputGroup>

									</HStack>
									<HStack w={'full'}>
										<InputGroup pt={2} fontFamily={'Poppins, sans-serif'} w={'100%'} onChange={(e: any) => setEmail(e.target.value)}>
											{email.search('@') && email.search('.com') > 0 ?
												VerifyEmailExistente()
												:
												<></>
											}
											<Input maxLength={40} p={5} fontSize={16} value={email} type={'email'} placeholder={'Digite seu endereço de email'} />
											<InputRightElement fontSize={28} pt={5} children=



												{emailExistente === 0 && (email.search('@') && email.search('.com') > 0) ?
													<Popover content={successo} title="Sucesso!">
														<CheckCircleTwoTone style={{ color: 'green' }} />
													</Popover>



													:
													emailExistente === 1 && (email.search('@') && email.search('.com') > 0) ?
														<Popover content={erro} title="Email em uso!">
															<CloseCircleOutlined style={{ color: 'red' }} />
														</Popover>
														:
														email.length === 0 ?
															<InfoOutlineIcon />
															:
															<Popover content={informacao} title="Insira seu email completo!">

																<LoadingOutlined />
															</Popover>
												}


											/>

										</InputGroup>
										<Text fontSize={30}>*</Text>
									</HStack>


									{emailExistente === 1 && email.search('@') && email.search('.com') > 0 && email.length > 8 ?
										<Text fontSize={17} textColor={'red'} fontFamily={'Roboto, sans-serif'}>Já existe uma conta registrada com esse email!</Text>
										:
										<></>
									}

									<InputGroup>
										<ReactInputMask onChange={(e: any) => setTelefone(e.target.value)} onInput={(e: any) => e.target.value = e.target.value.slice(0, 14)} value={telefone} placeholder='Telefone' className='chakra-input css-qou3w9' mask="(99)999999999" />
										<InputRightElement
											pointerEvents='none'
											children={<Text fontSize={30}>*</Text>}
										/>
									</InputGroup>
									<HStack w={'full'}>
										<Input type={'date'} onInput={(e: any) => e.target.value = e.target.value.slice(0, 10)} textColor={'gray.600'} value={datanasc} onChange={(e: any) => setDataNasc(e.target.value)} fontSize={18} fontFamily={'Poppins, sans-serif'} w={'100%'} placeholder={'Data de nascimento...'}></Input>
										<Text fontSize={30}>*</Text>
									</HStack>
									<HStack>
										<Text fontSize={28}>(*)</Text>
										<Text fontSize={16}><b>Campos obrigatórios!</b></Text>
									</HStack>


								</>
								: <></>}

							{active === 2 ?
								<VStack w={"100%"}>
									<HStack w={'full'}>
										<InputGroup>
											<Input onInput={(e: any) => e.target.value = e.target.value.slice(0, 14)} type={'number'} autoComplete="off" onChange={(e: any) => setCpfCnpj(e.target.value)} fontSize={18} fontFamily={'Poppins, sans-serif'} w={'100%'} placeholder={'CPF ou CNPJ'}></Input>
											<InputRightElement fontSize={28} children=
												{cpfcnpj.length !== 11 && cpfcnpj.length !== 14 ?
													<Popover content={informacaoCPF} title="Insira seu CPF ou CNPJ completo!">
														<InfoOutlineIcon />
													</Popover>

													:
													cpfcnpj.length === 11 ?
														validCpf(cpfcnpj) === true ?
															<CheckCircleTwoTone style={{ color: 'green' }} />
															:

															<CloseCircleOutlined style={{ color: 'red' }} />
														:
														cpfcnpj.length === 14 ?
															validarCNPJ(cpfcnpj) === true ?
																<CheckCircleTwoTone style={{ color: 'green' }} />
																:
																<CloseCircleOutlined style={{ color: 'red' }} />
															:
															<></>
												}
											/>
										</InputGroup>
										<Text fontSize={30}>*</Text>
									</HStack>
									{validCpf(cpfcnpj) === false && cpfcnpj.length === 11 ?


										<Text textColor={'red'} fontSize={16}>Por favor preencha o campo com um CPF válido!</Text>
										:
										<></>
									}

									{validarCNPJ(cpfcnpj) === false && cpfcnpj.length === 14 ?
										<Text textColor={'red'} fontSize={16}>Por favor preencha o campo com um CNPJ válido!</Text>
										:
										<></>
									}
									<Input autoComplete="off" type={'number'} onInput={(e: any) => e.target.value = e.target.value.slice(0, 14)} value={inscricaoestadual} onChange={(e: any) => setInscricaoEstadual(e.target.value)} fontSize={18} fontFamily={'Poppins, sans-serif'} w={'100%'} p={5} placeholder={'Inscrição estadual (Se possuir)'}></Input>
									<InputGroup>
										<InputRightElement
											pointerEvents='none'
											children={<Text fontSize={30}>*</Text>}
										/>
										<Input maxLength={20} value={senha} onChange={(e: any) => setSenha(e.target.value)} fontSize={18} type={'password'} fontFamily={'Poppins, sans-serif'} w={'100%'} placeholder={'Senha'}></Input>
									</InputGroup>

									{senha.length === 0 ? <Text textColor={'red'} fontFamily={'Inter, sans-serif'} fontSize={14}>Digite uma senha!</Text> : <></>}
									{senha.length !== 0 && senha.length < 8 ? <Text textColor={'red'} fontFamily={'Inter, sans-serif'} fontSize={14}>Digite uma senha mais forte!</Text> : <></>}
									<HStack justify={'center'} w={'full'} align={'center'}>
										<Text fontSize={28}>(*)</Text>
										<Text fontSize={16}><b>Campos obrigatórios!</b></Text>
									</HStack>

								</VStack>
								: <></>
							}

							{active === 3 ?
								<SimpleGrid w={"100%"} spacing={3} column={{ xsm: 1, sm: 1, md: 2, lg: 2 }}>
									<HStack>
										<InputGroup>
											<Input className='chakra-input css-qou3w9' type={'number'} fontSize={18} fontFamily={'Poppins, sans-serif'} onInput={(e: any) => e.target.value = e.target.value.slice(0, 8)} value={cep} onBlur={SetAddress} onChange={(e: any) => setCep(e.target.value)} placeholder={'CEP'}></Input>											<InputRightElement
												pointerEvents='none'
												children={
													cep.length !== 8 ?
														<InfoOutlineIcon fontSize={30} />
														:
														IsCEP(cep) === true ?
															<CheckCircleTwoTone style={{ color: 'green', fontSize: 30 }} />
															:
															<CloseCircleOutlined style={{ color: 'red', fontSize: 30 }} />

												}
											/>
										</InputGroup>
										<Text fontSize={30}>*</Text>
									</HStack>

									<HStack>
										<Input onInput={(e: any) => e.target.value = e.target.value.slice(0, 40)} value={rua} onChange={(e: any) => setRua(e.target.value)} fontSize={18} type={'address'} fontFamily={'Poppins, sans-serif'} w={'100%'} placeholder={'Rua'}></Input>
										<Text fontSize={30}>*</Text>
									</HStack>

									<HStack>
										<Input onInput={(e: any) => e.target.value = e.target.value.slice(0, 6)} onKeyPress={EnviarComEnter} value={numero} type={'number'} onChange={(e: any) => setNumero(e.target.value)} fontSize={18} fontFamily={'Poppins, sans-serif'} placeholder={'Número'}></Input>
										<Text fontSize={30}>*</Text>
									</HStack>
									<HStack>
										<Input onInput={(e: any) => e.target.value = e.target.value.slice(0, 22)} value={cidade} onChange={(e: any) => setCidade(e.target.value)} fontSize={18} type={'address'} fontFamily={'Poppins, sans-serif'} placeholder={'Cidade'}></Input>
										<Text fontSize={30}>*</Text>
									</HStack>

									<HStack>
										<Input onInput={(e: any) => e.target.value = e.target.value.slice(0, 22)} value={bairro} onChange={(e: any) => setBairro(e.target.value)} fontSize={18} fontFamily={'Poppins, sans-serif'} placeholder={'Bairro'}></Input>
										<Text fontSize={30}>*</Text>
									</HStack>
									<HStack>
										<Input onInput={(e: any) => e.target.value = e.target.value.slice(0, 20)} onKeyPress={EnviarComEnter} value={estado} onChange={(e: any) => setEstado(e.target.value)} fontSize={18} type={'address'} fontFamily={'Poppins, sans-serif'} placeholder={'Estado'}></Input>
										<Text fontSize={30}>*</Text>
									</HStack>

									<HStack justify={'center'} w={'full'} align={'center'}>
										<Text fontSize={28}>(*)</Text>
										<Text fontSize={16}><b>Campos obrigatórios!</b></Text>
									</HStack>

								</SimpleGrid>
								: <></>}

							<HStack pt={4}>
								<Button onClick={() => {


									//Verifica se o passo atual é maior ou igual a quantidade total de passos permitidos, caso seja verdade; vai permitir decrementar a variável
									//Esse passo atual só é usado para alterar o nome do button de finalizar para continuar 
									if (passoAtual === quantidadeTotalPassos) {
										setPassoAtual(passoAtual - 1)

									}
									if (passoAtual === 1) {
										navigate('/')
									}

									if (passoAtual > 1) {
										setPassoAtual(passoAtual - 1)
										setCpfCnpj('')


									}
									prevStep();


								}} variant={"outline"} w={'200px'} _focus={{}} fontFamily={'Roboto, sans-serif'} colorScheme={'blue'} >Voltar</Button>
								<Button isDisabled={
									nome.length === 0 ||
										telefone.length === 0 ||
										datanasc.length === 0 ||
										emailExistente === 1 ||
										emailExistente === undefined ||
										email.length === 0 ||
										(email.search('@gmail.com') < 1 &&
											email.search('@hotmail.com') < 3 &&
											email.search('@outlook.com') < 3)
										||

										(passoAtual === 2
											&& ((cpfcnpj.length === 14
												&& validarCNPJ(cpfcnpj) === false)
												|| ((cpfcnpj.length === 11
													&& validCpf(cpfcnpj) === false)
													|| (cpfcnpj.length !== 11
														&& cpfcnpj.length !== 14))
											)) ||

										(passoAtual === 3 &&
											(IsCEP(cep) === false ||
												cep?.length === 0
												|| rua?.length === 0
												|| numero?.length === 0
												|| cidade?.length < 2
												|| bairro?.length < 3
												|| estado?.length < 2
											))

										? true : false} onClick={() => {


											//caso esteja no último passo, é chamado a api para salvar os dados
											if (passoAtual === quantidadeTotalPassos) {
												FazerCadastro();


											}
											if (passoAtual === 2) {
												setPassoAtual(2)
											}

											if (passoAtual >= 1) {
												setPassoAtual(passoAtual + 1)
											}
											if ((passoAtual === 2 && senha.length < 8)) {
												setPassoAtual(2)
												return 1;
											}
											else
												nextStep();



											//Verifica se o pesso atual é menor que a quantidade total de passos permitidos, caso seja verdade; vai para o passo 2
											//Esse passo atual só é usado para alterar o nome do button de continuar para finalizar e chamar a api quando necessário, ou seja; quando preencher o último passo
											if (passoAtual < quantidadeTotalPassos) {
												setPassoAtual(passoAtual + 1);
											}

											if (passoAtual === quantidadeTotalPassos) {
												setPassoAtual(3);
											}

										}} minW={'120px'} w={'200px'} _focus={{}} fontFamily={'Inter, sans-serif'} isLoading={loginIsLoading} colorScheme={'blue'}>{passoAtual === quantidadeTotalPassos ? "Finalizar" : "Continuar"}</Button>
							</HStack>

							<HStack>
								<Text p={2} fontFamily={'Inter, sans-serif'} fontWeight={"medium"} color={"gray.600"}>Já possui um cadastro?</Text>
								<Text onClick={() => { navigate('/login') }} fontWeight={"medium"} cursor={"pointer"} fontFamily={'Inter, sans-serif'} color={"#508feb"}>Faça login.</Text>
							</HStack>

							<Spacer />
							<div className="botCadastroGoogle">
								<GoogleLogin clientId="457670064004-p72pesrup6ol6rhu2r1dbvii1ab6bhv3.apps.googleusercontent.com"
									render={renderProps => (
										<button onClick={renderProps.onClick} disabled={renderProps.disabled} className='g-sign-in-button'>
											<div className='content-wrapper'>
												<div className='logo-wrapper'>
													<img src='https://developers.google.com/identity/images/g-logo.png'></img>
												</div>
												<span className='text-container'>
													<span>Cadastrar com o Google</span>
												</span>
											</div>
										</button>

									)}
									onSuccess={FazerCadastroGoogle}
									onFailure={FazerCadastroGoogle}

								/>
							</div>

						</VStack>
					</motion.div>
				</Box>
			</VStack>
			<VStack bgColor={'gray.100'} pt={'1%'} >
				<Footer />
			</VStack>
		</>
	)
}	