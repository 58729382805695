/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
import { FrownOutlined, LoginOutlined } from "@ant-design/icons";
import { Box, Button, HStack, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useDisclosure, VStack } from "@chakra-ui/react";
import { message, notification } from "antd";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import GoogleLogin from "react-google-login";
import { useNavigate } from "react-router-dom";
import { Footer } from "../components/Footer";
import { NavBarLogin } from "../components/NavBarLogin";
import { api } from "../util/api";

export const SignIn = () => {


	const [loginIsLoading, setIsLoadingLogin] = useState(false);
	const [emailisLoading, setEmailLoading] = useState(false)
	const navigate = useNavigate();
	const [name, setName] = useState();
	const [imageUrl, setImageUrl] = useState();
	const [email, setEmail] = useState('');
	const [senha, setSenha] = useState('');
	const [emailrecuperacao, setEmailRecuperacao]: any = useState(undefined);
	const { isOpen, onOpen, onClose } = useDisclosure()
	const { isOpen: isOpenDadosFornecedor, onOpen: onOpenDadosFornecedor, onClose: onCloseDadosFornecedor } = useDisclosure()
	let [access_token, setAccess_Token]: any = useState()
	const [emailEmpty, setEmailEmpty] = useState(false)
	const [senhaEmpty, setSenhaEmpty] = useState(false)

	const [nomeFornecedor, setNomeFornecedor]: any = useState();
	const [emailFornecedor, setEmailFornecedor]: any = useState();
	const [CodigoFornecedor, setCodigoFornecedor]: any = useState();


	const [emailFornecedorCriptografado, setEmailFornecedorCriptografado]: any = useState()
	const [senhaFornecedorCriptografado, setSenhaFornecedorCriptografado]: any = useState()

	useEffect(() => {
		if (email?.length > 1 || senha?.length > 1) {
			criptografarEmailFornecedor(email)
			criptografarSenhaFornecedor(senha)
		}
	}, [emailFornecedorCriptografado, senhaFornecedorCriptografado, email, senha])

	const handleKeyPress = (event: any) => {
		if (event.key === 'Enter') {
			LoginUser()
		}
	}
	const EnviarEmailComEnter = (event: any) => {
		if (event.key === 'Enter') {
			RecuperarSenha();
		}
	}
	const FazerLoginGoogle = (response: any) => {

		const { profileObj:
			{ name, imageUrl }
		}
			= response
		setName(name)
		setImageUrl(imageUrl)
		api.post('/auth/loginGoogle', { response }).then(async (result: any) => {
			localStorage.clear();

			localStorage.setItem('@App:googleId', response.profileObj.googleId);
			localStorage.setItem('@App:NomeUser', response.profileObj.givenName);
			localStorage.setItem('@App:ImageUser', response.profileObj.imageUrl);
			localStorage.setItem('@App:Access_Token', result.data.access_token);
			message.destroy();
			message.success('Seja bem vindo!', 1.5);

			await api.get("/me").then(async (result: { data: any; fornecedor: string }) => {
				localStorage.setItem('@App:NomeUser', result.data.name);
				setIsLoadingLogin(false);
				if (result.data.fornecedor !== undefined && result.data.fornecedor === 1) {
					window.location.href = '/fornecedor';
				}
				else
					navigate('/empresas/all');
			})

		}).catch((error: any) => {
			if (error.response.status === 401) {
				message.destroy()
				message.error("Conta não cadastrada! Por favor cadastre-se no botão (Criar conta)!")
				setIsLoadingLogin(false)
			}
		})
	}

	const data = {
		email,
		senha,
	}

	function CadastrarDadosFornecedor() {

		api.post('auth/cadastrarFornecedor', { CodigoFornecedor, nomeFornecedor, emailFornecedor }).then(() => {
			message.success("Cadastrado com sucesso! Por favor faça login novamente!")
			onCloseDadosFornecedor()

		}).catch((e: any) => {
			message.error("Não foi possível cadastrar seus dados")
		})

	}

	async function RecuperarSenha() {
		if (emailrecuperacao.length === 0
			|| emailrecuperacao.length < 8
			|| (emailrecuperacao.search('@gmail.com') < 1 &&
				emailrecuperacao.search('@hotmail.com') < 3 &&
				emailrecuperacao.search('@outlook.com') < 3)) {
			notification.destroy();
			message.destroy();
			message.error("Não é possível enviar um email de recuperação para o endereço de email informado!")
			return 1;
		}
		else
			setEmailLoading(true)
		localStorage.clear()
		api.post('auth/verificarconta', { emailrecuperacao }).then(async (response: any) => {
			access_token = response.data.access_token


			// Enviará o email que o usuário digitou para o endpoint que vai verificar no banco de dados se aquele email é de uma conta que foi registrada usando o Google.
			// Se o email for de uma conta cadastrada através da api do Google ele não permitirá o envio do email para o usuário

			api.post('verificaremailexistenterecover', { emailrecuperacao }).then((response: any) => {

				if (response.data.length > 0) {

					api.post('auth/enviaremail', { emailrecuperacao, access_token }).then(() => {

						// Chama o endpoint que enviará para o email do usuário o link de redefinição de senha e setta no LocalStorage o email que o usuário digitou
						notification.destroy();
						notification.open({
							message: <Text fontFamily={'Inter'} textColor={'black'}><b>Email enviado!</b></Text>,
							description: <Text fontFamily={'Roboto, sans-serif'} fontSize={16}>Foi enviado para o seu email um link para redefinição de sua senha!</Text>,
							placement: 'bottom',
							duration: 5,
							style: { color: 'green' }
						})


						setEmailLoading(false)
						setEmailRecuperacao('')


					}).catch((error: any) => {
						setEmailLoading(false)
						notification.destroy()
						notification.open({
							message: <Text fontFamily={'Inter'} textColor={'black'}><b>Erro!</b></Text>,
							description: <Text fontFamily={'Roboto, sans-serif'} fontSize={16}>Não foi possível enviar o link de redefinição de senha para seu email, lamentamos o ocorrido!</Text>,
							placement: 'bottom',
							duration: 5,
							style: { color: 'red' }

						})
						return 0;
					})
				}

				// Caso o email não for encontrado ele cai no else informando que o email não está cadastrado no sistema
				else {


					notification.destroy()
					notification.open({
						message: <Text fontFamily={'Inter'} textColor={'black'}><b>Erro!</b></Text>,
						description:

							<Text textColor={'red'} fontFamily={'Roboto, sans-serif'} fontSize={16}>Esse email não encontra-se cadastrado em nosso sistema! Por favor faça seu cadastro!</Text>,
						placement: 'bottom',
						duration: 5,


					})
					setEmailLoading(false)
				}
			})


			// Caso o retorno da api for "unauthorized" significa que o email é de uma Conta Google, portanto não faz o envio do email para a redefinição de senha do usuário

		}).catch((error: any) => {
			if (error.response.status === 401) {

				notification.destroy();
				notification.open({
					message: <Text fontFamily={'Inter'} textColor={'black'}><b>Erro!</b></Text>,
					description: <Text fontFamily={'Roboto, sans-serif'} fontSize={16}>O email informado foi registrado usando o Google, portanto não é possível alterar a senha</Text>,
					placement: 'bottom',
					duration: 5,
					style: { color: 'orange' }

				})
				setEmailLoading(false)
			}
			else {
				setEmailLoading(false)
				notification.destroy()
				notification.open({
					message: <Text fontFamily={'Inter'} textColor={'black'}><b>Erro!</b></Text>,
					description: <Text fontFamily={'Roboto, sans-serif'} fontSize={16}>Esse email não encontra-se cadastrado em nosso sistema! Por favor faça seu cadastro!</Text>,
					placement: 'bottom',
					duration: 5,
					style: { color: 'red' }

				})
			}
		})

	}
	// CRIPTOGRAFIA EMAIL
	function criptografarEmailFornecedor(email: any) {
		var b: any = [''], c: any = ''
		for (var i = 0; i <= email?.length; i++) {
			c = email
			var letraMaiuscula = c.toUpperCase()
			if (letraMaiuscula[i] === "A") { b[i] = "´" } if (letraMaiuscula[i] === "B") { b[i] = "µ" } if (letraMaiuscula[i] === "C") { b[i] = "¶" } if (letraMaiuscula[i] === "D") { b[i] = "·" } if (letraMaiuscula[i] === "E") { b[i] = "¸" } if (letraMaiuscula[i] === "F") { b[i] = "¹" } if (letraMaiuscula[i] === "G") { b[i] = "º" } if (letraMaiuscula[i] === "H") { b[i] = "»" }
			if (letraMaiuscula[i] === "I") { b[i] = "¼" } if (letraMaiuscula[i] === "J") { b[i] = "½" } if (letraMaiuscula[i] === "K") { b[i] = "¾" } if (letraMaiuscula[i] === "L") { b[i] = "¿" } if (letraMaiuscula[i] === "M") { b[i] = "À" } if (letraMaiuscula[i] === "N") { b[i] = "Á" } if (letraMaiuscula[i] === "O") { b[i] = "Â" } if (letraMaiuscula[i] === "P") { b[i] = "Ã" }
			if (letraMaiuscula[i] === "Q") { b[i] = "Ä" } if (letraMaiuscula[i] === "R") { b[i] = "Å" } if (letraMaiuscula[i] === "S") { b[i] = "Æ" } if (letraMaiuscula[i] === "T") { b[i] = "Ç" } if (letraMaiuscula[i] === "U") { b[i] = "È" } if (letraMaiuscula[i] === "V") { b[i] = "É" } if (letraMaiuscula[i] === "X") { b[i] = "Ê" } if (letraMaiuscula[i] === "W") { b[i] = "Ü" }
			if (letraMaiuscula[i] === "Y") { b[i] = "Ë" } if (letraMaiuscula[i] === "Z") { b[i] = "Ì" } if (letraMaiuscula[i] === "1") { b[i] = "Í" } if (letraMaiuscula[i] === "2") { b[i] = "Î" } if (letraMaiuscula[i] === "3") { b[i] = "Ï" } if (letraMaiuscula[i] === "4") { b[i] = "Ð" } if (letraMaiuscula[i] === "5") { b[i] = "Ñ" } if (letraMaiuscula[i] === "6") { b[i] = "Ò" }
			if (letraMaiuscula[i] === "7") { b[i] = "Ó" } if (letraMaiuscula[i] === "8") { b[i] = "Ô" } if (letraMaiuscula[i] === "9") { b[i] = "Õ" } if (letraMaiuscula[i] === "0") { b[i] = "Ö" } if (letraMaiuscula[i] === " ") { b[i] = "×" } if (letraMaiuscula[i] === "&") { b[i] = "Ø" } if (letraMaiuscula[i] === "-") { b[i] = "Ù" } if (letraMaiuscula[i] === ".") { b[i] = "Ú" }
			if (letraMaiuscula[i] === "Ç") { b[i] = "°" } if (letraMaiuscula[i] === "'") { b[i] = "±" } if (letraMaiuscula[i] === "+") { b[i] = "Ý" } if (letraMaiuscula[i] === '"') { b[i] = "÷" } if (letraMaiuscula[i] === "(") { b[i] = "ÿ" } if (letraMaiuscula[i] === ")") { b[i] = "þ" } if (letraMaiuscula[i] === ",") { b[i] = "Û" } if (letraMaiuscula[i] === ":") { b[i] = "á" }
			if (letraMaiuscula[i] === "[") { b[i] = "_" } if (letraMaiuscula[i] === "]") { b[i] = "|" } if (letraMaiuscula[i] === "{") { b[i] = "@" } if (letraMaiuscula[i] === "}") { b[i] = ";" } if (letraMaiuscula[i] === "´") { b[i] = "=" } if (letraMaiuscula[i] === "Õ") { b[i] = "$" } if (letraMaiuscula[i] === "Ã") { b[i] = "#" } if (letraMaiuscula[i] === '"\\"') { b[i] = "ß" }
			if (letraMaiuscula[i] === "°") { b[i] = ">" } if (letraMaiuscula[i] === "º") { b[i] = "G" } if (letraMaiuscula[i] === "%") { b[i] = "T" } if (letraMaiuscula[i] === "É") { b[i] = "*" } if (letraMaiuscula[i] === "Á") { b[i] = "N" } if (letraMaiuscula[i] === "/") { b[i] = "²" } if (letraMaiuscula[i] === "<") { b[i] = "¤" }
		}
		const criptoEmailFormatted = b.toString().replace(/,/g, "").replace(/\./g, "");
		setEmailFornecedorCriptografado(criptoEmailFormatted);
	}

	function VerificarLengthEmail(lengthEmail: number) {
		if (lengthEmail < 20) {
			setEmailFornecedorCriptografado(emailFornecedorCriptografado?.concat('×'))
		}
	}
	function VerificarLengthSenha(lengthSenha: number) {
		if (lengthSenha < 6) {
			setSenhaFornecedorCriptografado(senhaFornecedorCriptografado?.concat('×'))
		}
	}

	// CRIPTOGRAFIA SENHA
	function criptografarSenhaFornecedor(senha: any) {
		var b: any = [''], c: any = ''
		for (var i = 0; i <= senha?.length; i++) {
			c = senha
			var letraMaiuscula = c.toUpperCase()
			if (letraMaiuscula[i] === "A") { b[i] = "´" } if (senha[i] === "B") { b[i] = "µ" } if (letraMaiuscula[i] === "C") { b[i] = "¶" } if (letraMaiuscula[i] === "D") { b[i] = "·" } if (letraMaiuscula[i] === "E") { b[i] = "¸" } if (letraMaiuscula[i] === "F") { b[i] = "¹" } if (letraMaiuscula[i] === "G") { b[i] = "º" } if (letraMaiuscula[i] === "H") { b[i] = "»" }
			if (letraMaiuscula[i] === "I") { b[i] = "¼" } if (letraMaiuscula[i] === "J") { b[i] = "½" } if (letraMaiuscula[i] === "K") { b[i] = "¾" } if (letraMaiuscula[i] === "L") { b[i] = "¿" } if (letraMaiuscula[i] === "M") { b[i] = "À" } if (letraMaiuscula[i] === "N") { b[i] = "Á" } if (letraMaiuscula[i] === "O") { b[i] = "Â" } if (letraMaiuscula[i] === "P") { b[i] = "Ã" }
			if (letraMaiuscula[i] === "Q") { b[i] = "Ä" } if (letraMaiuscula[i] === "R") { b[i] = "Å" } if (letraMaiuscula[i] === "S") { b[i] = "Æ" } if (letraMaiuscula[i] === "T") { b[i] = "Ç" } if (letraMaiuscula[i] === "U") { b[i] = "È" } if (letraMaiuscula[i] === "V") { b[i] = "É" } if (letraMaiuscula[i] === "X") { b[i] = "Ê" } if (letraMaiuscula[i] === "W") { b[i] = "Ü" }
			if (letraMaiuscula[i] === "Y") { b[i] = "Ë" } if (letraMaiuscula[i] === "Z") { b[i] = "Ì" } if (letraMaiuscula[i] === "1") { b[i] = "Í" } if (letraMaiuscula[i] === "2") { b[i] = "Î" } if (letraMaiuscula[i] === "3") { b[i] = "Ï" } if (letraMaiuscula[i] === "4") { b[i] = "Ð" } if (letraMaiuscula[i] === "5") { b[i] = "Ñ" } if (letraMaiuscula[i] === "6") { b[i] = "Ò" }
			if (letraMaiuscula[i] === "7") { b[i] = "Ó" } if (letraMaiuscula[i] === "8") { b[i] = "Ô" } if (letraMaiuscula[i] === "9") { b[i] = "Õ" } if (letraMaiuscula[i] === "0") { b[i] = "Ö" } if (letraMaiuscula[i] === " ") { b[i] = "×" } if (letraMaiuscula[i] === "&") { b[i] = "Ø" } if (letraMaiuscula[i] === "-") { b[i] = "Ù" } if (letraMaiuscula[i] === ".") { b[i] = "Ú" }
			if (letraMaiuscula[i] === "Ç") { b[i] = "°" } if (letraMaiuscula[i] === "'") { b[i] = "±" } if (letraMaiuscula[i] === "+") { b[i] = "Ý" } if (letraMaiuscula[i] === '"') { b[i] = "÷" } if (letraMaiuscula[i] === "(") { b[i] = "ÿ" } if (letraMaiuscula[i] === ")") { b[i] = "þ" } if (letraMaiuscula[i] === ",") { b[i] = "Û" } if (letraMaiuscula[i] === ":") { b[i] = "á" }
			if (letraMaiuscula[i] === "[") { b[i] = "_" } if (letraMaiuscula[i] === "]") { b[i] = "|" } if (letraMaiuscula[i] === "{") { b[i] = "@" } if (letraMaiuscula[i] === "}") { b[i] = ";" } if (letraMaiuscula[i] === "´") { b[i] = "=" } if (letraMaiuscula[i] === "Õ") { b[i] = "$" } if (letraMaiuscula[i] === "Ã") { b[i] = "#" } if (letraMaiuscula[i] === '"\\"') { b[i] = "ß" }
			if (letraMaiuscula[i] === "°") { b[i] = ">" } if (letraMaiuscula[i] === "º") { b[i] = "G" } if (letraMaiuscula[i] === "%") { b[i] = "T" } if (letraMaiuscula[i] === "É") { b[i] = "*" } if (letraMaiuscula[i] === "Á") { b[i] = "N" } if (letraMaiuscula[i] === "/") { b[i] = "²" } if (letraMaiuscula[i] === "<") { b[i] = "¤" }
		}
		const criptoSenhaFormatted = b.toString().replace(/,/g, "").replace(/\./g, "");
		setSenhaFornecedorCriptografado(criptoSenhaFormatted)
	}

	// Logo após o encerramento das duas funções (Criptografia do usuário e criptografia da senha ele chama o Verificador da quantidade de dígitos, para que ele insira automaticamente o '×' após o usuário e a senha do fornecedor, para que assim o banco possa reconhecer as credenciais do usuário)
	VerificarLengthSenha(senhaFornecedorCriptografado?.length)
	VerificarLengthEmail(emailFornecedorCriptografado?.length)


	const LoginUser = async () => {

		if (email.length === 0 || senha.length === 0) {
			setEmailEmpty(true)
			setSenhaEmpty(true)
			return 1;
		}

		if (email.search('@gmail.com') < 1 &&
			email.search('@hotmail.com') < 3 &&
			email.search('@outlook.com') < 3) {

			// Login do fornecedor
			await api.post("/auth/loginempresa", { emailFornecedorCriptografado, senhaFornecedorCriptografado }).then((response: any) => {
				localStorage.clear()
				localStorage.setItem('@App:Access_Token', response.data.access_token);
				notification.destroy()
				notification.open({
					duration: 2.5,
					placement: 'top',
					message: <Text textColor={'white'} fontFamily={'Roboto, sans-serif'} fontSize={16}><b>Logado com sucesso!</b></Text>,
					description:
						<Text textColor={'white'} fontSize={16} fontFamily={'Inter, sans-serif'}><b>Seja bem vindo!</b></Text>,
					style: { background: '#4aaf53', borderRadius: 6, boxShadow: '0px 0px 20px 4px #85858587' },
				})

				window.location.href = '/fornecedor';
			}).catch((error: any) => {
				if (error.response.status === 404) {
					setCodigoFornecedor(error.response.data.error)
					onOpenDadosFornecedor()
					return 1;
				}
				if (error.response.status === 401) {
					notification.destroy()
					notification.open({
						duration: 2.5,
						placement: 'top',
						message: <Text textColor={'white'} fontFamily={'Roboto, sans-serif'} fontSize={16}><b>Erro!</b></Text>,
						description:
							<Text textColor={'white'} fontSize={16} fontFamily={'Inter, sans-serif'}><b>Email ou senha incorretos!</b></Text>,
						style: { background: '#cc4242', borderRadius: 6, boxShadow: '0px 0px 20px 4px #6868688a' },
					})
				}
			})
			return 1;
		}

		else

			//quando clicar em LoginUser, a variavel isLogging passarar a ser true assim ativando o loading do button. Caso esteja true, ficará false, caso esteja false, ficará true.
			setIsLoadingLogin(!loginIsLoading);
		// Envia o email e senha digitados para o end point, caso eles sejam encontrados no banco o usuário é logado, o nome do usuário é salvo no localStorage e ele é redirecionado para a página principal

		await api.post("/auth/login", { email, senha })
			.then(async (result: { data: { access_token: string; email: string }; }) => {
				localStorage.clear();
				localStorage.setItem('@App:Access_Token', result.data.access_token);
				notification.destroy()
				notification.open({
					duration: 2.5,
					placement: 'top',
					message: <Text textColor={'white'} fontFamily={'Roboto, sans-serif'} fontSize={16}><b>Logado com sucesso!</b></Text>,
					description:
						<Text textColor={'white'} fontSize={16} fontFamily={'Inter, sans-serif'}><b>Seja bem vindo!</b></Text>,
					style: { background: '#4aaf53', borderRadius: 6, boxShadow: '0px 0px 20px 4px #85858587' },
				});


				// Procurando o NOME do usuário no banco de dados através do email e senha digitados

				await api.get("/me").then(async (result: { data: any; fornecedor: string }) => {
					localStorage.setItem('@App:NomeUser', result.data.name);
					setIsLoadingLogin(false);
					if (result.data.fornecedor !== undefined && result.data.fornecedor === 1) {
						window.location.href = '/fornecedor';
					}
					else
						navigate('/empresas/all');
				})
				// Caso ele não encontre o usuário no banco de dados ele retorna um unauthorized e não permite o login do usuário
			})

			.catch((error: any) => {
				if (error?.response?.status === 401 || error) {
					notification.destroy()
					notification.open({
						duration: 2.5,
						placement: 'top',
						message: <Text textColor={'white'} fontFamily={'Roboto, sans-serif'} fontSize={16}><b>Erro!</b></Text>,
						description:
							<Text textColor={'white'} fontSize={16} fontFamily={'Inter, sans-serif'}><b>Email ou senha incorretos!</b></Text>,
						style: { background: '#cc4242', borderRadius: 6, boxShadow: '0px 0px 20px 4px #6868688a' },
					});

					setIsLoadingLogin(false)
				}
			})

	}

	return (
		<>
			<VStack bgColor={'gray.100'} spacing={'1.5%'} w='full'>
				<NavBarLogin />
				<VStack>
					<motion.div
						className="box"
						initial={{ opacity: 0, scale: 0.5 }}
						animate={{ opacity: 1, scale: 1 }}
						transition={{
							duration: 0.5,
							delay: 0.1,
							ease: [0, 0.71, 0.2, 1.01]
						}}
					>

						<Box w={'100%'} p={12} mt={'4%'} bgColor={'#ffffff'} boxShadow='0px 0px 20px 4px #8d8d8d87;' borderRadius={20}>
							<VStack p={5} align={'initial'}>

								<Text whiteSpace={'break-spaces'} fontFamily={'Inter, sans-serif'} py={2} color={"gray.600"} fontSize={20}><b>Olá! Para continuar, digite o seu e-mail e senha</b></Text>

								<Input autoFocus maxLength={40} onKeyPress={handleKeyPress} variant={'filled'} type={'email'} borderColor={emailEmpty && email.length === 0 ? 'red' : 'gray.100'} onChange={(e: any) => setEmail(e.target.value)} fontFamily={'Poppins, sans-serif'} w={'100%'} mt={9} placeholder={'Email:'}></Input>
								{emailEmpty && email.length === 0 ? <Text textColor={'red'} fontFamily={'Inter, sans-serif'} fontSize={14}>Digite um email</Text> : <></>}
								<Input maxLength={40} variant={'filled'} onKeyPress={handleKeyPress} borderColor={senhaEmpty && senha.length === 0 ? 'red' : 'gray.100'} type={'password'} onChange={(e: any) => setSenha(e.target.value)} fontFamily={'Poppins, sans-serif'} w={'100%'} mt={9} placeholder={'Senha:'}></Input>
								{senhaEmpty && senha.length === 0 ? <Text textColor={'red'} fontFamily={'Inter, sans-serif'} fontSize={14}>Digite uma senha</Text> : <></>}

								<Button textColor={'white'} _loading={{ bgColor: '#40c955' }} _focus={{ bgColor: '#4067c9' }} _hover={{ bgColor: '#319de0' }} isLoading={loginIsLoading} onClick={LoginUser} w={'100%'} fontFamily={'Roboto, sans-serif'} bgColor={'#4067c9'} p={2} top={1} >Entrar <LoginOutlined style={{ padding: 5, fontSize: 16 }} /></Button>

								<Button p={3} onClick={onOpen} colorScheme={'blue'} textColor={'#508feb'} variant={'link'}>Esqueci minha senha <FrownOutlined style={{ padding: 5, fontSize: 17 }} /></Button>



							</VStack>
							<VStack pt={3}>

								<GoogleLogin clientId="457670064004-p72pesrup6ol6rhu2r1dbvii1ab6bhv3.apps.googleusercontent.com"
									render={renderProps => (
										<button onClick={renderProps.onClick} disabled={renderProps.disabled} className='g-sign-in-button'>
											<div className='content-wrapper'>
												<div className='logo-wrapper'>
													<img src='https://developers.google.com/identity/images/g-logo.png'></img>
												</div>
												<span className='text-container'>
													<span >Entrar com o Google</span>
												</span>
											</div>
										</button>

									)}
									onSuccess={FazerLoginGoogle}
									onFailure={FazerLoginGoogle}
								/>
								<HStack >
									<Text p={2} fontFamily={'Inter, sans-serif'} fontWeight={"medium"} color={"gray.600"}>Não possui uma conta?</Text>
									<Button variant={'link'} onClick={() => { navigate('/cadastro') }} fontWeight={'medium'} fontSize={14} cursor={"pointer"} fontFamily={'Inter, sans-serif'} color={"#508feb"}>Cadastre-se</Button>
								</HStack>
							</VStack>
						</Box>
					</motion.div>
				</VStack>
				<VStack pt={'3%'} w={'full'} >
					<Footer />
				</VStack>

			</VStack>

			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay zIndex={0} />
				<ModalContent mt={'5%'}>
					<ModalHeader>Esqueci minha senha</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<VStack>
							<Text fontFamily={'Inter'} textColor={'black'} fontSize={17}>Por favor, digite aqui o email da sua conta:</Text>
							<Input maxLength={40} autoFocus top={1} onKeyPress={EnviarEmailComEnter} onChange={(e: any) => setEmailRecuperacao(e.target.value)} placeholder="Email"></Input>
							<Text pt={2} textColor={'gray'} fontSize={16}>Só serão aceitos endereços de email que não tenham sido registrados usando o Google</Text>
						</VStack>
					</ModalBody>

					<ModalFooter>
						<Button isLoading={emailisLoading} onClick={RecuperarSenha} colorScheme={'blue'} >Enviar</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>

			<Modal isOpen={isOpenDadosFornecedor} onClose={onCloseDadosFornecedor}>
				<ModalOverlay zIndex={0} />
				<ModalContent mt={'5%'}>
					<ModalHeader>Cadastrar dados do fornecedor</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<VStack>
							<Text fontFamily={'Inter'} textColor={'black'} fontSize={17}>Por favor, digite seu nome no campo abaixo:</Text>
							<Input maxLength={40} top={1} onChange={(e: any) => setNomeFornecedor(e.target.value)} placeholder="Nome"></Input>
							<Text fontFamily={'Inter'} textColor={'black'} fontSize={17}>Por favor, digite seu email no campo abaixo:</Text>
							<Input maxLength={40} top={1} onChange={(e: any) => setEmailFornecedor(e.target.value)} placeholder="Email"></Input>

						</VStack>
					</ModalBody>

					<ModalFooter>
						<Button disabled={nomeFornecedor !== undefined && nomeFornecedor?.length > 1 && emailFornecedor !== undefined && emailFornecedor?.length > 8 ? false : true} onClick={() => CadastrarDadosFornecedor()}>Enviar</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>

	)
}
