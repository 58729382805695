/* eslint-disable no-useless-escape */
import { LockOutlined, QrcodeOutlined } from "@ant-design/icons"
import { Button, Divider, HStack, IconButton, Link, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useDisclosure, VStack } from "@chakra-ui/react"
import { useState } from "react"
import { FaExternalLinkAlt, FaInstagram, FaWhatsapp } from 'react-icons/fa'
import { useNavigate } from "react-router-dom"
import '../Styles/Globalcss.css'
import QRCodeHome from "./QRCodeHome"

export const Footer = () => {
  const navigate = useNavigate(); 

  const { isOpen: isOpenModalQrCode, onOpen: onOpenModalQrCode, onClose: onCloseModalQrCode } = useDisclosure();
  return (
    <>
      <VStack align={'center'} w='full' bgGradient={'linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);'}>

        <Divider borderColor={'gray.300'} minH={'90px'} marginTop={8} width={'50%'} />

        <HStack pb={2} wrap={"wrap"} spacing={3}>
          <Link href='https://www.instagram.com/successsistemas/' isExternal fontFamily={'Inter, sans-serif'} fontSize={13} _active={{}} _hover={{ textColor: 'black' }} bgColor={'transparent'} textColor={'gray.600'} _focus={{}}>Trabalhe conosco</Link>
          <Link p={1} onClick={() => navigate('/termos')} isExternal fontFamily={'Inter, sans-serif'} fontSize={13} _active={{}} _hover={{ textColor: 'black' }} bgColor={'transparent'} textColor={'gray.600'} _focus={{}}>Termos e condições</Link>
          <Link href={'tel:38997489630'} fontFamily={'Inter, sans-serif'} fontSize={13} _active={{}} _hover={{ textColor: 'black' }} bgColor={'transparent'} textColor={'gray.600'} _focus={{}}>Contato</Link>
        </HStack>


        <Text pl={3} fontFamily={'Inter, sans-serif'} fontSize={12} _active={{}} bgColor={'transparent'} _focus={{}}>Copyright © Success Sistemas (1993-2022) - Todos os direitos reservados</Text>
        <Text pl={3} fontFamily={'Inter, sans-serif'} fontSize={12} _active={{}} bgColor={'transparent'} _focus={{}}>CNPJ n.º 03.007.331/0001-41 / Rua Bernardo Caparucho, nº 12, Paracatu, MG -</Text>


        <HStack justify={'center'} paddingY={1}>

          <Link href='https://www.instagram.com/successsistemas/' isExternal>
            <IconButton bg={'transparent'} className="iconfooter" fontSize={20} _active={{}} _hover={{ textColor: '#eb3859' }} _focus={{ boxShadow: "none" }} aria-label='Instagram' icon={<FaInstagram />} />
          </Link>

          <Link href='https://api.whatsapp.com/send?phone=553888252024&text=' isExternal>
            <IconButton bg={'transparent'} className="iconfooter" fontSize={20} _active={{}} _hover={{ textColor: '#1eff00' }} _focus={{ boxShadow: "none" }} aria-label='WhatsApp' icon={<FaWhatsapp />} />
          </Link>

          <Link href='https://www.successsistemas.com/' isExternal>
            <IconButton bg={'transparent'} className="iconfooter" fontSize={18} _active={{}} _hover={{ textColor: 'black' }} _focus={{ boxShadow: "none" }} aria-label='Twitter' icon={<FaExternalLinkAlt />} />
          </Link>

          <IconButton bg={'transparent'} className="iconfooter" onClick={onOpenModalQrCode} fontSize={18} _active={{}} _hover={{ textColor: 'black' }} _focus={{ boxShadow: "none" }} aria-label='Twitter' icon={<QrcodeOutlined style={{ fontSize: 20 }} />} />
          <IconButton bg={'transparent'} className="iconfooter" onClick={() => navigate('/adm')} fontSize={18} _active={{}} _hover={{ textColor: 'black' }} _focus={{ boxShadow: "none" }} aria-label='Twitter' icon={<LockOutlined style={{ fontSize: 20 }} />} />

        </HStack>

      </VStack>


      <Modal isOpen={isOpenModalQrCode} onClose={onCloseModalQrCode}>
        <ModalOverlay bg={'blackAlpha.700'} />
        <ModalContent>
          <ModalHeader>QR Code Página Inicial</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack>
              <QRCodeHome />
              <Text fontSize={17} fontFamily={'Roboto, sans-serif'}>Envie o QRCode acima para outras pessoas visualizarem a página inicial</Text>
            </VStack>

          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onCloseModalQrCode}>
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

    </>
  )
}
