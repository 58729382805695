import useSWR from 'swr';

import { apiEndPoint } from '../util/api';

// Hook criado para buscar as avaliações do produto cujo parâmetro é o ProductId

export function GetProductRate(ProductId: number) {
	const token = localStorage.getItem('@App:Access_Token')
	const fetcher = (url: any) => fetch(apiEndPoint + `/rate/${ProductId}`, {
		headers: {
			'Authorization': `Bearer ${token}`,
		}
	}).then(response => response.json());

	const { data, error, mutate } = useSWR(apiEndPoint + `/rate/${ProductId}`, fetcher, { refreshInterval: 30000 })

	return {
		allRate: data,
		isLoadingRate: !error && !data,
		errorRate: error,
		mutateRate: mutate
	}
}