import { Text } from "@chakra-ui/react";
import { Produto } from "../../types/types";

type ProdutosType = {
    produtos: Produto
}

export const ModalDadosEmpresa = ({ produtos }: ProdutosType) => {
    return (
        <>
            <Text p={5} fontFamily={'Roboto, sans-serif'} textColor={'black'} fontSize={18}>Razão social: {produtos?.[0]?.title}</Text>
            <Text p={5} fontFamily={'Roboto, sans-serif'} textColor={'black'} fontSize={18}>Telefone: {produtos?.[0]?.phone}</Text>
            <Text p={5} fontFamily={'Roboto, sans-serif'} textColor={'black'} fontSize={18}>Endereço: {produtos?.[0]?.address}</Text>
            <Text p={5} fontFamily={'Roboto, sans-serif'} textColor={'black'} fontSize={18}>Email: {produtos?.[0]?.email}</Text>
        </>

    )
}