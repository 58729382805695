import { useMediaQuery } from "@chakra-ui/react"
import { NavBarMobile } from "../Mobile/NavbarMobile"
import { NavBar } from "../NavBar"
export const NavBarResponsiva = () => {
    const [MenorThan1260] = useMediaQuery('(min-width: 1136px)')
    return (

        <>
            {MenorThan1260 ? <NavBar /> : <NavBarMobile />}
        </>
    )
}
