import useSWR from "swr";
import { apiEndPoint } from "../util/api";

// Hook criado para buscar as respostas privadas do usuário

export function GetChatAnswers() {
	const token = localStorage.getItem('@App:Access_Token')
	const fetcher = (url: any) => fetch(apiEndPoint + `/chat/respostas`, {
		headers: {
			'Authorization': `Bearer ${token}`,
		}
	}).then(response => response.json());

	const { data, error, mutate } = useSWR(apiEndPoint + 'chat/respostas', fetcher, { refreshInterval: 30000 })

	return {
		allChatAnswers: data,
		isLoadingChatAnswers: !error && !data,
		error: error,
		mutateChatAnswers: mutate
	}
}