import axios, { AxiosError } from "axios";
import useSWR from "swr";

export const apiEndPoint = 'https://apicarrinhocompras.successsistemas.com';

export const api: any = axios.create({
	baseURL: apiEndPoint,
});


api.interceptors.request.use(
	(config: any) => {
		const token = localStorage.getItem('@App:Access_Token')

		if (token) {
			config.headers!['Authorization'] = 'Bearer ' + token
		}

		return config
	},
	(error: AxiosError) => {
		Promise.reject(error)
	}
)

export default function useSwr(url: string) {
	const { data, error, mutate } = useSWR(apiEndPoint + url, async url => {

		const response = await fetch(url);
		const data = await response.json();

		return data;
	})

	return { data, error, mutate };
}
