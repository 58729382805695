/* eslint-disable react-hooks/exhaustive-deps */
import { RightOutlined } from '@ant-design/icons';
import { Box, Divider, HStack, Image, Spacer, Spinner, Text, VStack } from '@chakra-ui/react';
import "@fontsource/poppins";
import "@fontsource/roboto";
import { Badge } from '@mantine/core';
import { AnimatePresence, motion } from 'framer-motion';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import '../Styles/Globalcss.css';
import { Empresas } from '../types/types';
import { api } from '../util/api';
import { EmpresasContext } from './Context/EmpresasContext';

export const Companies = () => {

    const { categoria }: any = useParams();
    const empresas: Empresas | any = useContext(EmpresasContext);
    const navigate = useNavigate();
    const [isProductLoading, setIsProductLoading] = useState(false);
    const [busca, setBusca]: any = useState(empresas);
    const textTitle = 16;

    useEffect(() => {

        // Iniciará o loading e encerrará o mesmo quando as empresas forem carregadas

        setIsProductLoading(true)
        BuscaEmpresa()
        TurnOffLoading()


    }, [empresas, categoria])

    function timeout(delay: number) {
        return new Promise(res => setTimeout(res, delay));
    }

    async function TurnOffLoading() {
        await timeout(10000);
        setIsProductLoading(false)
    }


    async function BuscaEmpresa() {

        // Se nenhuma categoria for selecionada ou se a categoria está no seu estado inicial (undefined) ele settará a busca para empresas (carregado do EmpresasContext)

        if (categoria === undefined) {
            setBusca(empresas)
        }

        if (empresas?.length > 0 && empresas !== undefined) {
            // Quando as empresas forem carregadas é interrompido o loading
            setIsProductLoading(false);
        }

        if (categoria === 'all') {
            setBusca(empresas)
        }

        if (categoria !== 'all') {

            // Caso o usuário selecionar alguma categoria ele faz o request passando como parâmetro a categoria selecionada retornando somente as empresas pertencentes a aquela categoria

            await api.post('/empresas/buscacategoria', { categoria }).then(async (response: any) => {

                if (response?.data?.length > 0) {
                    await setBusca(response.data);
                    setIsProductLoading(false);
                }

                else

                    await setBusca(empresas)
                setIsProductLoading(false);

            })
        }
    }


    function seeProducts(empresaId: any) {
        navigate('/' + empresaId + '/page=1/lista-produtos')
    }


    return (
        <>

            <Box w={'full'} minH={'250px'} h={busca?.length === 1  || isProductLoading ? '600px' : 'auto'} p={10} boxShadow='3px 3px 8px #b9b9b9' borderRadius={3} bgGradient={'linear-gradient(0deg, rgba(187,187,187,0.06766456582633051) 1%, rgba(255,255,255,0.927608543417367) 3%);'}>

                {isProductLoading &&
                    <VStack justify={'center'} pt={'10%'} align={'center'}>
                        <Spinner color='blue' speed="0.5s" thickness='3px' size='xl' />
                        <Text fontFamily={'Roboto, sans-serif'} fontSize={20}><b>Carregando...</b></Text>
                    </VStack>

                }
                {busca?.map((empresas: Empresas) => {

                    return (

                        <AnimatePresence key={empresas.image}>
                            <motion.div

                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 0.3, type: 'tween' }}
                                exit={{ opacity: 0 }}
                            >

                                <HStack pr={5} my={5} cursor={'pointer'} onClick={() => seeProducts(empresas.id)} pt={5} h={'100px'}>

                                    <Image alt='empresas' loading={'lazy'} borderRadius={"3px"} fit={"contain"} w={'120px'} mr={10} minH={'80px'} fallbackSrc={'/fail_load_image.png'} src={empresas.image}></Image>
                                    <VStack alignItems={"start"}>
                                        <Text fontFamily={'Inter'} textColor={'blackAlpha.800'} fontWeight={"semibold"} fontSize={textTitle}>{empresas.title}</Text>
                                        <Text fontFamily={'Lato, sans-serif'} textColor={'black'} fontSize={"md"}>{empresas.description}</Text>
                                        <Badge style={{ borderRadius: 2 }} color="green">Aberto</Badge>
                                    </VStack>
                                    <Spacer />

                                    <HStack>
                                        <button style={{ fontFamily: 'Roboto, sans-serif', whiteSpace: 'nowrap' }} className='BotaoAcessarEmpresas'>Acessar <RightOutlined style={{ top: 1, paddingLeft: 2 }} /></button>
                                    </HStack>

                                </HStack>

                                <Divider orientation="horizontal" borderColor={'gray.300'} w={'100%'} h={'40px'} />

                            </motion.div>
                        </AnimatePresence>
                    )
                })

                }
            </Box >

        </>
    )
}