import { CheckOutlined, SendOutlined } from "@ant-design/icons";
import { Box, Button, HStack, Image, Input, Link, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger, Portal, Select, Show, SimpleGrid, Spinner, Tab, TabList, TabPanel, TabPanels, Tabs, Text, useDisclosure, VStack } from "@chakra-ui/react";
import { Divider, message } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { textState } from "../components/ParamIndexFornecedorPage";
import { GetChatMessage } from "../hooks/GetChatMessages";
import { GetEmpresaFornecedor } from "../hooks/GetEmpresaFornecedor";
import { GetMe } from "../hooks/GetMe";
import { GetPerguntasProdutos } from "../hooks/GetPerguntasProdutos";
import { api } from "../util/api";

export const FornecedorPage = () => {
    const [text, setText]: any = useRecoilState(textState)
    const { me, isLoadingMe } = GetMe()
    const { allMessages, mutateMessages } = GetChatMessage()
    const { allPerguntas, mutatePerguntas } = GetPerguntasProdutos()
    const { empresafornecedor, isLoadingEmpresa } = GetEmpresaFornecedor()
    console.log(empresafornecedor)

    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isOpenLoadingModal, onOpen: onOpenLoadingModal, onClose: onCloseLoadingModal } = useDisclosure();

    const navigate = useNavigate();
    const [quantPerguntas, setQuantPerguntas]: any = useState()

    const [nome, setNome]: any = useState('')
    const [link, setLink]: any = useState('')
    const [descricao, setDescricao]: any = useState('')
    const [preco, setPreco]: any = useState('')
    const [quantidade, setQuantidade]: any = useState('')
    const [mostrarpreco, setMostrarPreco]: any = useState('')
    const [resposta, setResposta]: any = useState()
    const [pedidosrecebidos, setPedidosrecebidos]: any = useState()
    const initRef = React.useRef(null)
    const [isloading, setIsLoading]: any = useState()

    async function GetPedidosFornecedor() {

        api.get('pedido/pedidosrecebidos').then((response: any) => {
            setPedidosrecebidos(response.data)

        })
    }

    useEffect(() => {

        if (!isLoadingMe && me?.fornecedor !== 1) {
            navigate('/empresas/all')
        }

    })

    function MarcarComoLida(idMensagem: number) {
        setIsLoading(true)
        api.put('chat/marcarcomolida', { idMensagem }).then(() => {
            mutateMessages()
            message.destroy()
            message.success("Mensagem marcada como lida!")
            setIsLoading(false)
        }).catch((e: any) => {
            message.destroy()
            message.error("Não foi possível marcar essa mensagem como lida!")
        })
        setIsLoading(false)
    }

    useEffect(() => {
        GetPedidosFornecedor()
        onOpenLoadingModal()
        if (empresafornecedor?.length > 1 || empresafornecedor?.[0]?.title?.length > 1 || !isLoadingEmpresa) {
            onCloseLoadingModal()
        }

    }, [isLoadingEmpresa, empresafornecedor, onOpenLoadingModal, onCloseLoadingModal])


    useEffect(() => {

        setQuantPerguntas(allPerguntas)
        mutatePerguntas()
    }, [allPerguntas, mutatePerguntas, quantPerguntas, empresafornecedor])


    function CadastrarProduto(idEmpresa: number) {

        api.post('produtos/cadastrarproduto', { nome, link, descricao, preco, quantidade, mostrarpreco, idEmpresa }).then(() => {
            message.destroy()
            message.success({
                content: 'Produto cadastrado com sucesso!',
                className: 'custom-class',
                duration: 2,
                style: {
                    top: 3,
                    fontFamily: 'Roboto, sans-serif',
                    fontSize: 15,
                    zIndex: 10,

                },
            });
            onClose()
        }).catch((e: any) => {
            message.destroy()
            message.error({
                content: 'Não foi possível cadastrar esse produto!',
                className: 'custom-class',
                duration: 2,
                style: {
                    top: 3,
                    fontFamily: 'Roboto, sans-serif',
                    fontSize: 15,
                    zIndex: 10,

                },

            });
        })

    }

    const EnviarRespostaComEnter = (event: any, idMensagem: number, resposta: string, emailFornecedor: string, nome: string) => {
        if (event.key === 'Enter') {
            EnviarResposta(idMensagem, resposta, emailFornecedor, nome)
        }
    }

    function EnviarResposta(idMensagem: number, resposta: string, emailFornecedor: string, nome: string) {
        if (resposta.length < 10) {
            message.destroy();
            message.error("Mensagem muito curta, insira uma mensagem maior!");
            setIsLoading(false)
            return 1;
        }

        else

            setIsLoading(true)
        api.put('chat/enviarresposta', { idMensagem, resposta, emailFornecedor, nome }).then(async () => {
            message.destroy();
            message.success("Resposta enviada com sucesso!", 3)
            mutateMessages()
            setIsLoading(false)
            setResposta('')



        }).catch((error: any) => {
            if (error.response.status === 500) {
                message.destroy();
                message.warning("Email não enviado para o cliente!");
                setIsLoading(false);
                mutateMessages()
            }
        })

    }

    return (
        <VStack>
            <Tabs index={text.length < 1 ? 0 : text} w="full" minH={"300px"} bg={"white"}>
                <TabList borderRadius={5} p={3}>
                    <Tab boxShadow={'0px 0px 7px 0.7px #eeeeee;'} borderRadius={8} onClick={() => setText(0)} bg={text === 0 ? '#8eb5eb' : ''} tabIndex={0} fontWeight={"semibold"}>Pedidos</Tab>
                    <Tab boxShadow={'0px 0px 7px 0.7px #eeeeee;'} borderRadius={8} ml={1} onClick={() => setText(1)} bg={text === 1 ? '#93fa89' : ''} tabIndex={1} fontWeight={"semibold"}>Mensagens</Tab>
                    <Tab boxShadow={'0px 0px 7px 0.7px #eeeeee;'} borderRadius={8} ml={1} onClick={() => setText(2)} bg={text === 2 ? '#8ef2ff' : ''} tabIndex={2} fontWeight={"semibold"}>Perguntas em produtos</Tab>
                </TabList>
                <TabPanels p={0} w={'full'}>
                    <TabPanel tabIndex={1} p={0}>
                        {pedidosrecebidos?.length < 1 ?
                        <VStack>
                            <Text pt={8} textAlign={'center'} fontFamily={'Roboto, sans-serif'} fontSize={22}>Você ainda não possui pedidos!</Text>
                            <Button colorScheme={'blue'} onClick={onOpen} >Cadastrar novo produto</Button>
                        </VStack>
                            :
                            <Box p={2} w="full" bg="#fafeff" boxShadow='0px 0px 2px 1px #bbbbbb61' borderRadius={5}>
                                <VStack w={'full'} align={'center'}>
                                    <Text fontFamily={'Roboto, sans-serif'} p={3} textColor={'black'} fontSize={20}><b>Área do fornecedor</b></Text>
                                    <Text fontFamily={'Inter'} fontSize={18}>Sua empresa : <b><Link onClick={() => navigate(`/${empresafornecedor?.[0]?.empresa_id}/page=1/lista-produtos`)} fontFamily={'Inter'} textColor={'#2985ff'}>{empresafornecedor?.[0]?.title}</Link></b></Text>
                                    <Divider />
                                    <Text fontSize={18} textColor={'black'} fontFamily={'Roboto, sans-serif'} p={3}><b>Pedidos recebidos</b></Text>
                                    <VStack w="full" minH={"300px"} bg="white" borderRadius={2}>
                                        <SimpleGrid py={2} mt={4} columns={4} w="full">
                                            <VStack>
                                                <Text textColor={'blackAlpha.800'} fontWeight={"semibold"} fontSize="md">Código</Text>
                                            </VStack>
                                            <VStack>
                                                <Text textColor={'blackAlpha.800'} fontWeight={"semibold"} fontSize="md">Data</Text>
                                            </VStack>
                                            <VStack>
                                                <Text textColor={'blackAlpha.800'} fontWeight={"semibold"} fontSize="md">Situação</Text>
                                            </VStack>
                                            <VStack>
                                                <Text textColor={'blackAlpha.800'} fontWeight={"semibold"} fontSize="md">Valor total</Text>
                                            </VStack>

                                        </SimpleGrid>
                                        <Divider />
                                        <SimpleGrid mt={4} spacingX={5} columns={4} w="full">

                                            {pedidosrecebidos?.map((pedidosFornecedor: any) => {
                                                return (
                                                    <Show key={pedidosFornecedor?.id_pedido}>
                                                        <VStack>
                                                            <Text textColor={'blackAlpha.800'} fontSize="md">{pedidosFornecedor?.id_pedido}</Text>
                                                        </VStack>
                                                        <VStack>
                                                            <Text textColor={'blackAlpha.800'} fontSize="md">{pedidosFornecedor?.data_pedido}</Text>
                                                        </VStack>
                                                        <VStack>
                                                            <HStack py={2} justify={'center'} align={'center'}>
                                                                <Text textColor={'blackAlpha.800'} fontSize="md">
                                                                    {pedidosFornecedor?.status_pedido === 1 ?
                                                                        <Text>Recebido</Text>
                                                                        :
                                                                        pedidosFornecedor?.status_pedido === 2 ?
                                                                            <Text>Faturado</Text>
                                                                            :
                                                                            pedidosFornecedor?.status_pedido === 3 ?
                                                                                <Text>Nota fiscal emitida</Text>
                                                                                :
                                                                                pedidosFornecedor?.status_pedido === 4 ?
                                                                                    <Text>Despachado</Text>
                                                                                    :
                                                                                    pedidosFornecedor?.status_pedido === 5 &&
                                                                                    <Text>Entregue</Text>
                                                                    }

                                                                </Text>
                                                                {pedidosFornecedor?.status_pedido === 1 ?
                                                                    <Image w={'6%'} src="https://cdn-icons-png.flaticon.com/512/2558/2558180.png"></Image>
                                                                    :
                                                                    pedidosFornecedor?.status_pedido === 2 ?
                                                                        <Image w={'6%'} src="https://cdn.awsli.com.br/1356/1356417/arquivos/shopping-list.png"></Image>
                                                                        :
                                                                        pedidosFornecedor?.status_pedido === 3 ?
                                                                            <Image w={'6%'} src="https://cdn-icons-png.flaticon.com/512/2117/2117251.png"></Image>
                                                                            :
                                                                            pedidosFornecedor?.status_pedido === 4 ?
                                                                                <Image w={'8%'} src="https://w7.pngwing.com/pngs/827/790/png-transparent-e-commerce-computer-icons-order-fulfillment-trolley-angle-triangle-publishing.png"></Image>
                                                                                :
                                                                                pedidosFornecedor?.status_pedido === 5 ?
                                                                                    <Image w={'8%'} src="https://www.clipartmax.com/png/middle/189-1896179_how-e-commerce-startups-can-penetrate-a-saturated-market-pedidos-icon.png"></Image>
                                                                                    :
                                                                                    <></>
                                                                }
                                                            </HStack>
                                                        </VStack>
                                                        <HStack justify={'center'} align={'center'} py={2} pt={2}>
                                                            <Text fontFamily={'Roboto, sans-serif'} textColor={'blackAlpha.800'} fontSize="md">
                                                                <b>{(pedidosFornecedor.valor_total)?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</b>
                                                            </Text>
                                                        </HStack>
                                                    </Show>
                                                )

                                            })}

                                        </SimpleGrid>
                                        <Divider />
                                    </VStack>


                                    <Button colorScheme={'blue'} onClick={onOpen} >Cadastrar novo produto</Button>

                                    
                                </VStack>
                            </Box>
                        }
                    </TabPanel>
                    <TabPanel>


                        {allMessages?.length > 0 ?

                            <Text textAlign={'center'} fontFamily={'Roboto, sans-serif'} fontSize={20}>Você tem novas mensagens não respondidas!</Text>
                            :
                            <Text textAlign={'center'} mt={10} fontFamily={'Roboto, sans-serif'} fontSize={20}>Você não possui novas mensagens!</Text>
                        }
                        {isloading &&
                            <VStack>
                                <Spinner />
                            </VStack>
                           
                        }
                        {allMessages?.length > 0 &&
                            allMessages?.map((mensagens: any) => {
                                return (
                                    <Show key={mensagens?.nome}>
                                        <VStack p={3}>
                                            <HStack borderRadius={5} p={3} _hover={{ bg: '#fafafa' }}>
                                                <Text textColor={'gray'} fontFamily={'Inter, sans-serif'} fontSize={14}>[{mensagens?.nome}] - </Text>
                                                <Text textColor={'black'} fontFamily={'Roboto, sans-serif'} fontSize={16}><b>{mensagens?.mensagem}</b></Text>

                                                <Popover closeOnEsc returnFocusOnClose closeOnBlur initialFocusRef={initRef}  >
                                                    {({ isOpen, onClose }) => (
                                                        <>
                                                            <PopoverTrigger >
                                                                <Button fontSize={14} variant={"link"} colorScheme={"blue"}>Responder<SendOutlined style={{ fontSize: 15, padding: 1 }} /></Button>

                                                            </PopoverTrigger>
                                                            <Portal>
                                                                <PopoverContent>
                                                                    <PopoverArrow />
                                                                    <PopoverHeader><b>Responder</b></PopoverHeader>
                                                                    <PopoverCloseButton />
                                                                    <PopoverBody p={3}>
                                                                        <Text p={1} fontFamily={'Inter, sans-serif'} fontSize={14}><b>{mensagens?.mensagem}</b></Text>
                                                                        <Input maxLength={40} p={2} onChange={(e: any) => setResposta(e.target.value)} onKeyPress={(e: any) => EnviarRespostaComEnter(e, mensagens?.id_mensagem, resposta, mensagens?.email, me?.name)} placeholder="Responder..."></Input>
                                                                        <Button onClick={() => EnviarResposta(mensagens?.id_mensagem, resposta, mensagens?.email, me?.name)} isLoading={isloading ? true : false} ref={initRef} colorScheme={'green'} top={2}>Enviar</Button>
                                                                    </PopoverBody>

                                                                </PopoverContent>
                                                            </Portal>
                                                        </>
                                                    )}
                                                </Popover>

                                            </HStack>
                                            <Button fontSize={14} onClick={() => MarcarComoLida(mensagens?.id_mensagem)} variant={"link"} colorScheme={"blue"}>Marcar como lida e não responder<CheckOutlined style={{ fontSize: 15, padding: 1 }} /></Button>
                                        </VStack>
                                    </Show>
                                )
                            })

                            

                        }
                    </TabPanel>
                    <TabPanel>
                        <VStack p={3}>
                            <Text fontFamily={'Roboto, sans-serif'} textAlign={'center'} py={1} fontSize={18}>{quantPerguntas?.length > 0 ? 'Você possui perguntas não respondidas nos seguintes produtos' : ''}</Text>
                            {quantPerguntas?.length > 0 &&
                                quantPerguntas?.map((respostas: any) => {
                                    return (
                                        <VStack key={respostas?.nome} align={'initial'} >
                                            <HStack transition={'all .1s ease-in-out'} _hover={{ bg: '#fafafa' }} p={1} cursor={'pointer'} onClick={() => navigate(`/compras/${respostas?.id_produto}/${respostas?.empresa_id}`)}>
                                                <Image maxW={'80px'} src={respostas.image}></Image>
                                                <Text textColor={'black'} fontSize={14} fontFamily={'Inter, sans-serif'} w={'full'}>{respostas.nome}</Text>

                                            </HStack>
                                        </VStack>
                                    )
                                })
                                
                            }
                            {quantPerguntas?.length < 1 &&
                                <Text textAlign={'center'} fontFamily={'Roboto, sans-serif'} fontSize={18}>Você não possui novas perguntas em seus produtos</Text>
                                }
                        </VStack>


                    </TabPanel>
                </TabPanels>

            </Tabs>


            <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay zIndex={0} />
                <ModalContent zIndex={-1} top={3}>
                    <ModalHeader>Cadastrar novo produto</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text p={1} textColor={'black'} fontSize={15} px={1}>Nome</Text>
                        <Input onChange={(e: any) => setNome(e.target.value)} placeholder='Nome do produto' size='md' />
                        <Text p={1} textColor={'black'} fontSize={15} px={1}>Link imagem</Text>
                        <Input onChange={(e: any) => setLink(e.target.value)} placeholder='URL' size='md' />
                        <Text p={1} textColor={'black'} fontSize={15} px={1}>Descrição</Text>
                        <Input onChange={(e: any) => setDescricao(e.target.value)} placeholder='Descrição' size='md' />
                        <Text p={1} textColor={'black'} fontSize={15} px={1}>Preço</Text>
                        <Input type={'number'} onChange={(e: any) => setPreco(e.target.value)} placeholder='Preço' size='md' />
                        <Text p={1} textColor={'black'} fontSize={15} px={1}>Quantidade</Text>
                        <Input type={'number'} onChange={(e: any) => setQuantidade(e.target.value)} placeholder='Quantidade' size='md' />
                        <Text p={1} textColor={'black'} fontSize={15} px={1}>Mostrar preço?</Text>
                        <Select defaultValue={1} onChange={(e: any) => setMostrarPreco(e.target.value)} p={1} textColor={'black'} fontSize={15} px={1}>
                            <option value='1'>Selecionar:</option>
                            <option defaultValue='1' value='1'>Sim</option>
                            <option value='0'>Não</option>
                        </Select>
                    </ModalBody>

                    <ModalFooter>
                        <Button disabled={nome?.length < 3 || link?.length < 10 || descricao?.length < 3 || preco?.length < 1 || quantidade?.length < 1 || mostrarpreco?.length < 1 ? true : false} onClick={() => CadastrarProduto(empresafornecedor?.[0]?.empresa_id)} mr={3} colorScheme={'green'} variant='solid'>Cadastrar</Button>
                        <Button colorScheme='blue' onClick={onClose}>
                            Fechar
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <Modal closeOnOverlayClick={false} isOpen={isOpenLoadingModal} onClose={onCloseLoadingModal}>
                <ModalOverlay boxShadow={0} h={'full'} bg={'blackAlpha.400'} />
                <ModalContent boxShadow={0} p={0} top={'40%'} bg={'transparent'}>

                    <ModalBody h={'100%'} bg={'transparent'}>
                        <VStack h={'full'} bg={'transparent'}>
                            <Spinner
                                thickness='4px'
                                speed='0.65s'
                                emptyColor='gray.200'
                                color='blue.500'
                                size='xl'
                            />
                        </VStack>

                    </ModalBody>
                </ModalContent>
            </Modal>

        </VStack>

    )
}   