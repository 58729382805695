/* eslint-disable jsx-a11y/anchor-is-valid */
import { CaretUpOutlined, LeftOutlined, QrcodeOutlined, RightOutlined, SearchOutlined } from "@ant-design/icons";
import { Box, Button, Center, HStack, Image, Input, InputGroup, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, SimpleGrid, Spinner, Text, useDisclosure, VStack } from "@chakra-ui/react";
import { message } from "antd";
import { motion } from "framer-motion";
import { memo, useContext, useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { useNavigate, useParams } from "react-router-dom";
import { GetCarrinho } from "../hooks/GetCarrinho";
import '../Styles/Globalcss.css';
import { Produto } from "../types/types";
import { api } from "../util/api";
import { ProdutosContext } from "./Context/ProdutosContext";
import { ModalDadosEmpresa } from "./modals/ModalDadosEmpresa";
import { ProductItem } from "./ProductItem";
import QRCodeComponent from "./QRCodeEmpresas";

const Produtos = () => {
    window.scrollTo(0, 0)
    const { mutateCarrinho } = GetCarrinho();
    const { isOpen: isOpenModal, onOpen: onOpenModal, onClose: onCloseModal } = useDisclosure();
    const { isOpen: isOpenModalQrCode, onOpen: onOpenModalQrCode, onClose: onCloseModalQrCode } = useDisclosure();
    const { isOpen: isOpenLoadingModal, onOpen: onOpenLoadingModal, onClose: onCloseLoadingModal } = useDisclosure();
    const navigate = useNavigate();
    const { empresaId, page }: any = useParams();

    var nmrPage = page.replace(/[^0-9]/g, '');
    parseInt(nmrPage)
    var pagina_anterior: any = Number(nmrPage) - 1;
    var proxima_pagina: any = Number(nmrPage) + 1;
    const produtos: any = useContext(ProdutosContext);
    const [listaProdutos, setListaProdutos]: any = useState(produtos)
    const [pesquisa, setPesquisa]: any = useState()
    useEffect(() => {
        setListaProdutos(produtos)
        if (listaProdutos !== undefined && listaProdutos?.length === 0)
            setListaProdutos(produtos)
    }, [produtos])

    async function SearchProductByNameOrCode() {
        // Função que irá buscar a empresa de acordo com o nome da empresa digitada pelo usuário e mostrará a lista como resposta da requisição

        if (pesquisa?.length > 0) {
            onOpenLoadingModal()
            await api.post('/produtos/buscaprodutos', { pesquisa }).then((response: any) => {

                if (response?.data?.length > 0) {
                    setListaProdutos(response.data);
                    onCloseLoadingModal()

                }
                else
                    setListaProdutos(null)
                onCloseLoadingModal()
            })

        }
        else
            setListaProdutos(produtos)
        onCloseLoadingModal()
    }

    function PesquisarComEnter(e: any) {
        if (e.keyCode === 13) {

            SearchProductByNameOrCode()
        }
    }

    function PreviousPage() {
        navigate(`/${empresaId}/page=${pagina_anterior}/lista-produtos`)
        setListaProdutos(produtos)
    }

    function NextPage() {
        navigate(`/${empresaId}/page=${proxima_pagina}/lista-produtos`)
        setListaProdutos(produtos)
    }

    function seeProductDetails(productId: string) {
        //Navega para uma rota específica passando o Id do produto como parâmetro.
        navigate("/compras/" + productId + `/${empresaId}`);
        window.scrollTo(0, 0);
    }

    const variants = {
        open: { rotate: 180 },
        closed: { rotate: 360, paddingTop: 1 },
    }


    const [name, setName] = useState('')
    const [price, setPrice]: any = useState('')
    const [image, setImage] = useState('')
    const [erroproduto, setErroProduto] = useState<any>(0);
    const [value, setValue]: any = useState(0)
    const emdestaque = produtos.length > 0 ? produtos?.filter((produtos: any) => produtos?.destaque) : [];
    const busca_categoria = produtos.length > 0 ? produtos?.filter((produtos: any) => produtos).reverse((produtos: any) => produtos.preco) : [];

    function addToCart(productname: string, produtoimage: string, productprice: any, productId: number, quantity: number, corProduto: string) {
        // Função criada para adicionar o produto no carrinho e adicionar uma contagem de +1 item no ícone do carrinho
        setErroProduto(0);
        setName(productname);
        setPrice(productprice);
        setImage(produtoimage);
        api.post(`/user/cart/${productId}`, { productname, productprice, produtoimage, productId, quantity, corProduto }).then(() => {
            setErroProduto(false)
            mutateCarrinho();
        }).catch((error: any) => {
            if (error.response.status === 401) {

                message.warning("Por favor, faça login para adicionar esse produto ao carrinho!")
                navigate('/login')
            }

        })
    }

    return (

        <>
            <Text pt={8} fontFamily={'Roboto, sans-serif'} fontSize={20} textColor={'black'}><b>Produtos em destaque!</b></Text>

            <HStack p={3} id="csscarrossel" borderRadius={6} h={'auto'} bg={'white'} w={'auto'}>
                <Carousel useKeyboardArrows={true} preventMovementUntilSwipeScrollTolerance emulateTouch interval={2000} key={emdestaque} stopOnHover={true} width={'auto'} dynamicHeight autoPlay={true} infiniteLoop={true} showStatus={false} showThumbs={false}>

                    {emdestaque?.map((product: Produto) => {
                        return (
                            <Center w={'full'} key={product.id} cursor={'pointer'} onClick={() => { seeProductDetails(product.id.toString()) }} justifyContent={'center'} textAlign={'center'}>
                                <Image alt="imagem-produtos" ml={'15%'} fallbackStrategy="beforeLoadOrError" fallbackSrc={'/fail_load_image.png'} maxW={'280px'} align={'center'} fit={'contain'} h={'200px'} src={product?.image} />
                                <VStack align={'center'} justify={'center'} w={'full'}>

                                    <Text textColor='black' fontFamily={'Inter'} fontSize={"20px"}>{product.nome}</Text>
                                    <Text fontSize={"lg"} textDecoration='line-through' textColor='gray'>{(product.preco + (10 / 100) * product.preco).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</Text>


                                    <Text style={{ color: "#373737" }} fontSize={"26px"}>{(product.preco).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</Text>
                                    <HStack pb={2}>
                                        {/* {product.quantidade === 0 ?
                                                <Badge fontSize={15} colorScheme={'red'}>Produto esgotado!</Badge>
                                                :
                                                <Badge fontSize={15} colorScheme={'green'}>Disponível!</Badge>
                                            } */}
                                        <Text textColor={'black'} fontSize={17}>({product?.salitoqtd5} disponíveis)</Text>
                                    </HStack>
                                </VStack>
                            </Center>

                        )
                    })}

                </Carousel>

            </HStack>

            <HStack w={'full'} p={3} px={'10%'}>
                <Text whiteSpace={'nowrap'} fontFamily={'Roboto, sans-serif'} fontSize={20} textColor={'black'}><b>Outros produtos!</b></Text>
                <Button _active={{ colorScheme: '' }} variant={'outline'} colorScheme={'blue'} rightIcon={
                    <>
                        <motion.nav
                            animate={value === 1 ? "open" : "closed"}
                            variants={variants}
                        >

                            <CaretUpOutlined style={{ marginTop: 3 }} />
                        </motion.nav>

                    </>
                }

                    onClick={() => value === 1 ? setValue(0) : value === 0 ? setValue(1) : ''}>{value === 1 ? 'Menor preço' : 'Maior preço'}</Button>
                <InputGroup p={3} w={'40%'} >

                    <Input boxShadow='0px 0px 20px 4px #8d8d8d87;' bg={'white'} size={'lg'} textColor={'black'} _focusVisible={{}} onKeyDown={PesquisarComEnter} _active={{}} _focus={{}} onChange={(e: any) => setPesquisa(e.target.value)} placeholder="Digite o nome ou código do produto que você procura" />
                    <Button size={'lg'} value={pesquisa} onClick={() => SearchProductByNameOrCode()} textColor={'black'} colorScheme={'blue'} ><SearchOutlined /></Button>
                </InputGroup>

            </HStack>

            {listaProdutos === null ?
                <Box boxShadow='0px 0px 20px 4px #8d8d8d87;' borderRadius={6} p={10} bg={'#fff'} h={'auto'} w={'50%'}>
                    <HStack p={4} spacing={20} justify={'center'} align={'center'}>
                        <svg className="ui-search-icon ui-search-icon--not-found ui-search-rescue__icon" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80"><g fill="none" fill-rule="evenodd"><path stroke="#484848" stroke-linecap="round" d="M37.891 50.705c4.584-1.85 8.61-5.256 11.216-9.957m2.764-9.071c.456-5.499-1.142-10.977-4.48-15.29a21.276 21.276 0 0 0-6.53-5.599c-5.845-3.24-12.566-3.444-18.403-1.139-4.65 1.836-8.739 5.265-11.375 10.022a22.488 22.488 0 0 0-2.832 10.308 22.102 22.102 0 0 0 3.058 11.86 21.288 21.288 0 0 0 8.02 7.79 21.275 21.275 0 0 0 8.427 2.594 21.853 21.853 0 0 0 10.135-1.518"></path><path stroke="#484848" stroke-linecap="round" d="M28.774 45.86a16.046 16.046 0 0 1-9.688-4.642m-3.693-5.7c-1.4-3.695-1.38-7.782.065-11.41a15.238 15.238 0 0 1 3.392-5.144c3.384-3.384 7.97-4.852 12.444-4.417 3.564.346 7.056 1.9 9.81 4.654 1.9 1.9 3.23 4.153 3.984 6.538a15.83 15.83 0 0 1 .236 8.768 15.246 15.246 0 0 1-3.984 6.947 15.237 15.237 0 0 1-5.289 3.449 15.651 15.651 0 0 1-7.277.956"></path><g fill="#484848" fill-rule="nonzero"><path d="M35.644 35.95l-12-12 .572-.572 12 12z"></path><path d="M36.215 23.95l-12 12-.57-.572 11.999-12z"></path></g><path stroke="#484848" stroke-linecap="square" d="M52.267 52.61l-6.646-6.647"></path><path fill="#FFDB15" d="M61.601 54.585l-2.823-2.824c-1.405-1.405-3.988-1.099-5.768.682-1.78 1.78-2.087 4.363-.682 5.768l9.599 9.599 8.89 8.89c1.403 1.404 3.987 1.098 5.767-.682 1.78-1.78 2.086-4.364.683-5.768"></path><path stroke="#484848" stroke-linecap="round" d="M52.095 58.273c-1.404-1.405-1.283-3.803.27-5.356s3.951-1.674 5.356-.27l9.6 9.6 8.89 8.89"></path></g></svg>
                        <VStack spacing={0} p={0} align={'initial'}>
                            <Text fontFamily={'Roboto, sans-serif'} fontSize={20}><b>Não há produtos que correspondem à sua busca.</b></Text>
                            <li style={{ paddingTop: 10, fontSize: 16, fontFamily: 'Inter, sans-serif' }}><b>Revise a ortografia</b> da palavra.</li>
                            <li style={{ fontSize: 16, fontFamily: 'Inter, sans-serif' }}>Utilize <b>palavras mais genéricas</b> ou menos palavras.</li>
                            <li style={{ fontSize: 16, fontFamily: 'Inter, sans-serif' }}><a onClick={() => navigate('/empresas/all')} style={{ color: 'blue' }} >Navegue pelas empresas</a> para encontrar outros produtos.</li>
                        </VStack>
                    </HStack>

                </Box>
                :
                <>
                    <SimpleGrid pt={3} pl={8} spacing={5} w={'full'} columns={{ sm: 2, xl: 4, lg: 3, md: 2 }}>
                        {listaProdutos?.map((produtosmap: any) => {
                            return (

                                <VStack key={produtosmap.codigo5} h={'435px'} w={'full'} >
                                    <motion.div

                                        key={produtosmap.quantidade}
                                        className="box"
                                        initial={{ opacity: 0, scale: 0.5 }}
                                        animate={{ opacity: 1, scale: 1 }}
                                        transition={{
                                            duration: 0.5,
                                            delay: 0.1,
                                            ease: [0, 0.71, 0.2, 1.01]
                                        }}
                                    >

                                        <ProductItem product={produtosmap} seeProductDetails={seeProductDetails} showProdutoComprado={addToCart} />
                                    </motion.div>
                                </VStack>

                            )
                        })
                        }
                    </SimpleGrid>
                </>
            }

            {listaProdutos !== null &&
                <>

                    <HStack spacing={20}>
                        <Button _hover={{}} bg={'transparent'} fontSize={20} top={2} disabled={pagina_anterior === 0 ? true : false} onClick={() => PreviousPage()}><LeftOutlined style={{ fontSize: 30 }} /></Button>
                        <Button _hover={{}} bg={'transparent'} fontSize={20} top={2} onClick={() => NextPage()}><RightOutlined style={{ fontSize: 30 }} /></Button>
                    </HStack>

                    <Button top={5} onClick={onOpenModal} _focus={{}} textColor={'black'} colorScheme={"messenger"} variant={"solid"} >Dados da empresa</Button>
                    <Button bg={'transparent'} p={2} w={20} top={12} onClick={onOpenModalQrCode} _focus={{}} textColor={'black'} ><QrcodeOutlined style={{ fontSize: 50 }} /></Button>
                </>
            }

            <Modal isOpen={isOpenModal} onClose={onCloseModal}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Informações {produtos?.[0]?.title}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <ModalDadosEmpresa produtos={produtos} />
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={onCloseModal}>
                            Fechar
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <Modal isOpen={isOpenModalQrCode} onClose={onCloseModalQrCode}>
                <ModalOverlay bgPosition={'bottom'} bgRepeat={'no-repeat'} bgSize={'15%'} bgImage={'/logosuc.png'} bg={'whiteAlpha.900'} />
                <ModalContent borderRadius={6}>
                    <ModalHeader>QR Code Empresa</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <VStack>
                            <QRCodeComponent />
                            <Text fontSize={17} fontFamily={'Roboto, sans-serif'}>Envie o QRCode acima para outras pessoas visualizarem os produtos dessa empresa</Text>
                        </VStack>

                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={onCloseModalQrCode}>
                            Fechar
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <Modal closeOnOverlayClick={false} isOpen={isOpenLoadingModal} onClose={onCloseLoadingModal}>
                <ModalOverlay boxShadow={0} h={'full'} bg={'blackAlpha.300'} />
                <ModalContent boxShadow={0} p={0} top={'40%'} bg={'transparent'}>

                    <ModalBody h={'100%'} bg={'transparent'}>
                        <VStack h={'full'} bg={'transparent'}>
                            <Spinner
                                thickness='4px'
                                speed='0.65s'
                                emptyColor='gray.200'
                                color='black'
                                size='xl'
                            />
                            <Text fontSize={18} textColor={'black'} fontFamily={'Inter'}>Carregando produtos...</Text>
                        </VStack>
                    </ModalBody>

                </ModalContent>
            </Modal>

        </>

    );

}
export default memo(Produtos);