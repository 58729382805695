import useSWR from "swr";
import { apiEndPoint } from "../util/api";

// Hook usado para buscar as mensagens recebidas em determinado produto

export function GetPerguntasProdutos() {
	const token = localStorage.getItem('@App:Access_Token')
	const fetcher = (url: any) => fetch(apiEndPoint + `/mensagensrecebidas`, {
		headers: {
			'Authorization': `Bearer ${token}`,

		}
	}).then(response => response.json());

	const { data, error, mutate } = useSWR(apiEndPoint + '/mensagensrecebidas', fetcher, { refreshInterval: 30000 })

	return {
		allPerguntas: data,
		isLoadingPerguntas: !error && !data,
		error: error,
		mutatePerguntas: mutate
	}
}
