import { AuditOutlined, CloseOutlined, HomeOutlined, LockOutlined, ProfileOutlined, ShopOutlined, ShoppingCartOutlined, UserAddOutlined, UserOutlined, UserSwitchOutlined } from '@ant-design/icons';
import { ChevronDownIcon, HamburgerIcon } from '@chakra-ui/icons';
import { Avatar, Box, Button, Drawer, DrawerBody, DrawerContent, HStack, IconButton, Image, Menu, MenuButton, MenuItem, MenuList, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger, Spacer, Text, useDisclosure, VStack } from '@chakra-ui/react';
import "@fontsource/poly";
import "@fontsource/poppins";
import "@fontsource/quando";
import '@fontsource/roboto';
import { Badge, message, notification } from 'antd';
import 'antd/dist/antd.css';
import { motion } from "framer-motion";
import { memo, useEffect, useRef, useState } from 'react';
import { FiShoppingCart } from 'react-icons/fi';
import { IoIosNotificationsOutline } from 'react-icons/io';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { useOnClickOutside } from 'usehooks-ts';
import { GetCarrinho } from '../../hooks/GetCarrinho';
import { GetChatAnswers } from '../../hooks/GetChatAnswers';
import { GetChatMessage } from '../../hooks/GetChatMessages';
import { GetMe } from '../../hooks/GetMe';
import { GetPerguntasProdutos } from '../../hooks/GetPerguntasProdutos';
import { GetRespostasProdutos } from '../../hooks/GetRespostasProdutos';
import '../../Styles/Globalcss.css';
import { MotionBox } from '../../Styles/motion';
import { ChatPerguntas, ChatRespostas, Respostas } from '../../types/types';
import { api } from '../../util/api';
import { BuscaEmpresas } from '../BuscaEmpresa';
import { LoadingCircle } from '../loading/LoadingCircle';
import { textState } from '../ParamIndexFornecedorPage';

export const NavBarMobile = memo(() => {
  const { me } = GetMe();
  const { allRespostas, mutateRespostas } = GetRespostasProdutos();
  const { allMessages, mutateMessages } = GetChatMessage()
  const { allChatAnswers, mutateChatAnswers } = GetChatAnswers()
  const { allPerguntas, mutatePerguntas } = GetPerguntasProdutos()
  const { categoria }: any = useParams()
  const firstLetterUpperCase = categoria?.charAt(0)?.toUpperCase() + categoria?.slice(1);

  const [isLoading, setIsLoading]: any = useState()

  const { isOpen: isOpenDrawer, onOpen: onOpenDrawer, onClose: onCloseDrawer }: any = useDisclosure();
  const { isOpen: isOpenPopOver, onOpen: onOpenPopover, onClose: OnClosePopover }: any = useDisclosure();

  //Variável criada para receber o Avatar e o nome do usuário que estão salvos no localStorage
  var [AvatarUser] = useState<any>('0')
  AvatarUser = localStorage.getItem('@App:ImageUser');
  const name: any = localStorage.getItem('@App:NomeUser');

  const [setBarraPesquisa] = useState<any>(false)
  const [openedDrawer, setOpenedDrawer]: any = useState<any>(true)

  const ref = useRef(null);

  const toolbarHeigth = "70px";


  const handleClickOutside = () => {
    setBarraPesquisa(false)
  }
  useOnClickOutside(ref, handleClickOutside)

  function MarcarComoLida(idMensagem: number) {
    setIsLoading(true)
    api.put('chat/marcarcomolida', { idMensagem }).then(() => {
      mutateMessages()
      mutateChatAnswers()
      message.destroy()
      notification.open({
        duration: 2.5,
        placement: 'top',
        message: <Text textColor={'white'} fontFamily={'Roboto, sans-serif'} fontSize={16}><b>Sucesso!</b></Text>,
        description:
          <Text textColor={'white'} fontSize={16} fontFamily={'Inter, sans-serif'}><b>Mensagem marcada como lida!</b></Text>,
        style: { background: '#4aaf53', borderRadius: 6, boxShadow: '0px 0px 20px 4px #6868688a' },
      });
      setIsLoading(false)
    }).catch((e: any) => {
      message.destroy()
      message.error("Não foi possível marcar essa mensagem como lida!")
    })
    setIsLoading(false)
  }

  function OpenDrawer() {
    setOpenedDrawer(false)
    onOpenDrawer()

  }

  function CloseDrawer() {
    setOpenedDrawer(true)
    onCloseDrawer()
  }

  function NavigateUser(value: any) {
    navigate(`/${value}`)
    onCloseDrawer()
    setOpenedDrawer(true)
  }

  function DeslogarConta() { // Limpa o localStorage removendo o nome, avatar e token do usuário que estão salvos no próprio localStorage.
    localStorage.clear();
    navigate('/empresas/all')
    onCloseDrawer()
    setOpenedDrawer(true)
  }

  function VisualizarNotificacao(id_produto: number, id_empresa: number, id_mensagem: number) {

    navigate(`/compras/${id_produto}/${id_empresa}`)

    api.put('visualizarnotificacao', { id_mensagem }).then(() => {
      mutateRespostas()
    })

  }

  function SelectCategory(categoria: string) {
    navigate(`/empresas/${categoria}`)
    onCloseDrawer()
    setOpenedDrawer(true)
  }

  function VerPerguntaProduto(id_produto: number, id_empresa: number) {
    navigate(`/compras/${id_produto}/${id_empresa}`)
    mutateRespostas()
  }

  function NavigateFornecedor(index: number) {
    setText(index)
    navigate('/fornecedor')
  }


  const [text, setText]: any = useRecoilState(textState);
  const navigate = useNavigate()

  const [quantCart, setQuantCart] = useState<any>(0)
  const [quantRespostas, setQuantRespostas]: any = useState()
  const { dataCarrinho, mutateCarrinho } = GetCarrinho()

  useEffect(() => {
    mutateChatAnswers()
    setQuantRespostas(allRespostas)
    mutateRespostas()

    setQuantCart(dataCarrinho?.length);
    mutateCarrinho();
  }, [dataCarrinho, mutateCarrinho, allRespostas, mutateRespostas, mutateChatAnswers, allChatAnswers, allPerguntas, mutatePerguntas]);


  return (

    <>

      <HStack w="full" h={toolbarHeigth} bg={"#3a5fcb"}>

        <Spacer w={'full'}>
          <VStack pt={1} align={'center'} w={"70px"} mr={2} h={"100px"}>
            <Image onClick={() => { navigate('/empresas/all') }} cursor={"pointer"} width={'50px'} src='/logo só com o s branca.png'></Image>
          </VStack>
        </Spacer>

        <MotionBox
          opacity='0'
          initial={{
            translateX: 10,
            opacity: 0,

          }}
          animate={{
            translateX: 0,
            opacity: 1,
            transition: {
              duration: '0.3'
            },
          }}

        >

          <BuscaEmpresas />


        </MotionBox>

        :
        <MotionBox
          opacity='0'
          initial={{
            translateX: 0,
            opacity: 0,
          }}
          animate={{
            translateX: 10,
            opacity: 1,
            transition: {
              duration: '0.3'
            },
          }}
        >
        </MotionBox>


        {AvatarUser !== null || name !== null ?

          <>

            <HStack spacing={3}>
              <Badge size="small" color="green" count={quantCart?.length}>
                <FiShoppingCart color='white' onClick={() => { navigate('/carrinho') }} cursor={"pointer"} size={"24px"} />
              </Badge>
              {quantRespostas?.length > 0 || allMessages?.length > 0 || allChatAnswers?.length > 0 || allPerguntas?.length > 0 ?
                <Popover onClose={OnClosePopover} onOpen={onOpenPopover} isOpen={isOpenPopOver}>

                  <PopoverTrigger >

                    <Button _focus={{}} _active={{}} _hover={{}} bg={'transparent'} size={'sm'} p={0} rightIcon={<Badge style={{ right: 1 }} size="small" color="green" count={quantRespostas?.length + allMessages?.length + allChatAnswers?.length + allPerguntas?.length}>
                      <IoIosNotificationsOutline color='white' size={25} /></Badge>}></Button>

                  </PopoverTrigger>

                  <PopoverContent>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverHeader><b>Você tem {quantRespostas?.length + allMessages?.length + allChatAnswers?.length + allPerguntas?.length} notificações!</b></PopoverHeader>
                    <PopoverBody w={'full'}>
                      <Text py={1}>{quantRespostas?.length > 0 ? <b>Sua pergunta foi respondida nos seguintes produtos:</b> : ''}</Text>
                      {quantRespostas?.length > 0 &&
                      quantRespostas?.map((respostas: Respostas) =>
                        <VStack key={respostas?.id} align={'initial'}>
                          <HStack _hover={{ bg: '#fafafa' }} p={2} cursor={'pointer'} onClick={() => VisualizarNotificacao(respostas?.id_produto, respostas?.empresa_id, respostas?.id)}>
                            <Image maxW={'60px'} w={'20%'} src={respostas.image}></Image>
                            <Text fontFamily={'Inter, sans-serif'}>{respostas.nome}</Text>
                          </HStack>
                        </VStack>
                      )}

                      <Text textColor={'black'} fontFamily={'Inter'} textAlign={'center'} py={1} fontSize={16}>{allPerguntas?.length > 0 ? <>Você possui perguntas não respondidas nos seguintes produtos:</> : ''}</Text>
                      {allPerguntas?.map((mensagens: ChatPerguntas) =>
                        <VStack key={mensagens?.descricao5} align={'initial'} >
                          <HStack transition={'all .1s ease-in-out'} _hover={{ bg: '#fafafa' }} p={2} cursor={'pointer'} onClick={() => VerPerguntaProduto(mensagens?.id_produto, mensagens?.empresa_id)}>
                            <Image maxW={'50px'} w={'20%'} src={mensagens?.image}></Image>
                            <Text w={'full'}>{mensagens?.descricao5}</Text>
                          </HStack>

                        </VStack>
                      )}

                      {isLoading ?
                        <LoadingCircle />
                        :
                        <></>
                      }
                      {allMessages?.length > 0 ?
                        <VStack onClick={() => NavigateFornecedor(1)} pt={5} align={'center'} >
                          <Text>Você possui novas mensagens!</Text>
                        </VStack>
                        :
                        <></>
                      }
                      {allChatAnswers?.length > 0 && allChatAnswers !== undefined ?
                        allChatAnswers.map((respostas: ChatRespostas) => {
                          return (
                            <VStack key={respostas.allAnswers} py={3} align={'initial'}>
                              <Text>Você possui novas respostas!</Text>
                              <Text fontSize={12} fontFamily={'Inter, sans-serif'}>{respostas?.resposta}</Text>
                              <Button size={'sm'} onClick={() => MarcarComoLida(respostas?.id_mensagem)}>Marcar como lida!</Button>
                            </VStack>
                          )
                        })
                        :
                        <></>
                      }
                    </PopoverBody>


                  </PopoverContent>
                </Popover>
                :
                <></>
              }
            </HStack>

          </>
          :
          <Badge size="small" color="green" >
            <FiShoppingCart onClick={() => { navigate('/login') }} cursor={"pointer"} size={"24px"} />



          </Badge>

        }

        <Menu>

          <MenuButton
            as={IconButton}
            aria-label='Options'
            icon={openedDrawer ?

              <motion.div
                initial={{ y: '-50%', opacity: 0, scale: 0.5 }}
                transition={{ duration: 0.2, ease: 'backOut' }}

                animate={{
                  y: 0, opacity: 1,
                  scale: 1


                }}

              >
                <HamburgerIcon />
              </motion.div>

              :

              <CloseOutlined />

            }
            onClick={OpenDrawer}
            fontSize='23px'
            variant='unset' />

        </Menu>

      </HStack>
      <Drawer placement='right' size={'full'} onClose={CloseDrawer} isOpen={isOpenDrawer}>

        <DrawerContent h={'full'} mt={toolbarHeigth}>
          <DrawerBody w={'100%'} bg={'#fafafa'}>
            <VStack>

              <Button onClick={(e: any) => NavigateUser(e.target.value)} value={'empresas/all'} w={'full'} color={'#3483fa'} isActive variant={'ghost'}><HomeOutlined style={{ padding: 5 }} />Home</Button>
              <Menu>
                <MenuButton p={0} textColor={'black'} w={'full'} bgColor={'whitesmoke'} as={Button} rightIcon={<ChevronDownIcon fontSize={20} />}>
                  Categorias
                  <Box p={1} borderRadius={5}>
                    <VStack>
                      <Text textColor={'black'} fontSize={16}>{categoria === 'all' || categoria === undefined ? 'Todas' : firstLetterUpperCase}</Text>
                    </VStack>

                  </Box>
                </MenuButton>
                <MenuList>
                  <MenuItem value={'all'} onClick={(e: any) => SelectCategory(e.target.value)} minH='40px'>
                    TODAS
                  </MenuItem>
                  <MenuItem value={'restaurantes'} onClick={(e: any) => SelectCategory(e.target.value)} minH='40px'>
                    RESTAURANTES
                  </MenuItem>
                  <MenuItem value={'eletrodomesticos'} onClick={(e: any) => SelectCategory(e.target.value)} minH='40px'>
                    ELETRODOMÉSTICOS
                  </MenuItem>
                  <MenuItem value={'agricola'} onClick={(e: any) => SelectCategory(e.target.value)} minH='40px'>
                    AGRÍCOLA
                  </MenuItem>
                  <MenuItem value={'roupas'} onClick={(e: any) => SelectCategory(e.target.value)} minH='40px'>
                    ROUPAS
                  </MenuItem>
                  <MenuItem value={'acessorios'} onClick={(e: any) => SelectCategory(e.target.value)} minH='40px'>
                    ACESSÓRIOS PARA VEÍCULOS
                  </MenuItem>

                </MenuList>
              </Menu>
              <Menu>

                <MenuList>
                  <MenuItem minH='40px'>
                    <Text>Paracatu</Text>
                  </MenuItem>
                  <MenuItem minH='40px'>
                    <Text >Unaí</Text>
                  </MenuItem>
                  <MenuItem minH='40px'>
                    <Text>Araxá</Text>
                  </MenuItem>
                  <MenuItem minH='40px'>
                    <Text>Brasilândia</Text>
                  </MenuItem>
                  <MenuItem minH='40px'>
                    <Text>João Pinheiro</Text>
                  </MenuItem>
                  <MenuItem minH='40px'>
                    <Text>Vazante</Text>
                  </MenuItem>
                </MenuList>
              </Menu>

              <Button justifyContent={'initial'} onClick={(e: any) => NavigateUser(e.target.value)} value={'empresas/all'} w={'full'} textColor={'black'} top={1} color={'#3483fa'} leftIcon={<ShopOutlined style={{ fontSize: 20 }} />} isActive variant={'ghost'}>Empresas</Button>
              {AvatarUser !== null || name !== null ?

                <Button justifyContent={'initial'} onClick={(e: any) => NavigateUser(e.target.value)} value={'pedidos'} w={'full'} textColor={'black'} top={1} color={'#3483fa'} leftIcon={<ProfileOutlined style={{ fontSize: 20 }} />} isActive variant={'ghost'}>Pedidos</Button>
                :
                <Button justifyContent={'initial'} onClick={(e: any) => NavigateUser(e.target.value)} value={'login'} w={'full'} textColor={'black'} top={1} color={'#3483fa'} leftIcon={<ProfileOutlined style={{ fontSize: 20 }} />} isActive variant={'ghost'}>Pedidos</Button>
              }
              <Button justifyContent={'initial'} onClick={(e: any) => { AvatarUser !== null || name !== null ? NavigateUser(e.target.value) : NavigateUser('login') }} value={'carrinho'} w={'full'} textColor={'black'} top={1} color={'#3483fa'} leftIcon={<ShoppingCartOutlined style={{ fontSize: 20 }} />} isActive variant={'ghost'}>Carrinho {quantCart === 0 ? '' : quantCart === undefined ? '' : quantCart === 1 ? `(${quantCart} item)` : `(${quantCart} itens)`}</Button>
              {me?.fornecedor === 1 ?
                <Button justifyContent={'initial'} value={'fornecedor'} onClick={(e: any) => NavigateUser(e.target.value)} w={'full'} textColor={'black'} top={1} color={'#3483fa'} isActive variant={'ghost'} leftIcon={<AuditOutlined style={{ fontSize: 20 }} />} >Portal Fornecedor</Button>
                :
                <></>
              }
              <Button justifyContent={'initial'} onClick={(e: any) => NavigateUser(e.target.value)} value={'perfil'} w={'full'} top={1} textColor={'black'} color={'#3483fa'} leftIcon={AvatarUser?.length > 3 ? <Avatar size={'sm'} src={AvatarUser} /> : <UserSwitchOutlined style={{ fontSize: 20 }} />} isActive variant={'ghost'}>Perfil {name?.length > 3 ? `(${name})` : ''}</Button>
              <Button justifyContent={'initial'} onClick={(e: any) => NavigateUser(e.target.value)} value={'login'} w={'full'} textColor={'black'} top={15} color={'#3483fa'} leftIcon={<UserOutlined style={{ fontSize: 20 }} />} isActive variant={'ghost'}>Entre</Button>
              <Button justifyContent={'initial'} onClick={(e: any) => NavigateUser(e.target.value)} value={'adm'} w={'full'} textColor={'black'} top={15} color={'#3483fa'} leftIcon={<LockOutlined style={{ fontSize: 20 }} />} isActive variant={'ghost'}>Acesso administrador</Button>
              <Button justifyContent={'initial'} onClick={(e: any) => NavigateUser(e.target.value)} value={'cadastro'} w={'full'} textColor={'black'} top={15} color={'#3483fa'} leftIcon={<UserAddOutlined style={{ fontSize: 20 }} />} isActive variant={'ghost'}>Crie sua conta</Button>
              <Button justifyContent={'center'} onClick={() => DeslogarConta()} value={'cadastro'} w={'full'} textColor={'black'} top={15} colorScheme={'red'} leftIcon={<UserAddOutlined style={{ fontSize: 20 }} />} isActive variant={'ghost'}>Sair da conta</Button>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>

  )
})

