import { Route, Routes } from "react-router-dom";
import { RecoilRoot } from "recoil";
import { AdmPage } from "../pages/AdmPage";
import { Compras } from "../pages/Compras";
import { FornecedorPage } from "../pages/FornecedorPage";
import { LoginAdm } from "../pages/LoginAdm";
import { Profile } from "../pages/Profile";
import { RedefinirSenha } from "../pages/RedefinirSenha";
import { SignIn } from "../pages/SignIn";
import { SignUp } from "../pages/SignUp";
import { SignUpGoogle } from '../pages/SignUpGoogle';
import { TermosDeUsoPage } from "../pages/TermosdeUsoPage";
import { Teste } from "../pages/TestandoComponentes";
import { EmpresasProvidersPage } from "./EmpresasProvidersPage";
import { Layout } from "./Layout";
import { ListaProdutosProvidersPage } from "./ListaProdutosProvidersPage";
import { PedidosProvidersPage } from "./PedidosProvidersPage";
import { CarrinhoComprasPageReponsivo } from "./Responsivos/CarrinhoResponsivo";
import { RotaNotFound } from "./RotaNotFound";


export const RoutesComponents = () => {
	return (

		<RecoilRoot>
			<Routes>
				<Route path={"login"} element={<SignIn />} />
				<Route path={"cadastro"} element={<SignUp />} />
				<Route path={"cadastro-google"} element={<SignUpGoogle />} />
				<Route path={'*'} element={<RotaNotFound />} />
				<Route path={"teste"} element={<Teste />} />
				<Route path={"termos"} element={<TermosDeUsoPage />} />
				<Route path={"redefinir-senha/:param_token"} element={<RedefinirSenha />} />
				<Route path={"perfil"} element={<Profile />} />
				<Route path={"loginadm"} element={<LoginAdm />} />
				<Route path={'/'} element={<Layout />}>

					<Route path={"empresas/:categoria"} element={<EmpresasProvidersPage />} />
					<Route path={"compras/:id/:empresaId"} element={<Compras />} />
					<Route path={"carrinho"} element={<CarrinhoComprasPageReponsivo />} />
					<Route path={"pedidos"} element={<PedidosProvidersPage />} />
					<Route path={"adm"} element={<AdmPage />} />

					<Route path={"fornecedor"} element={<FornecedorPage />} />


					<Route path={":empresaId/:page/lista-produtos"} element={<ListaProdutosProvidersPage />} />
					<Route index element={<EmpresasProvidersPage />} />

					<Route path={'*/:'} element={<RotaNotFound />} />
				</Route>
			</Routes>
		</RecoilRoot>

	)
}