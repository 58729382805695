import { WhatsAppOutlined } from "@ant-design/icons";
import { Badge, Button, Center, Divider, Heading, HStack, Image, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Show, SimpleGrid, Spacer, Text, useDisclosure, VStack } from "@chakra-ui/react";
import '@fontsource/inter';
import { message } from "antd";
import jsPDF from 'jspdf';
import QRCode from "qrcode.react";
import { useContext, useState } from "react";
import { PedidosContext } from "../components/Context/PedidosContext";
import { Pedidos } from "../types/types";
import { api } from '../util/api';

export const PedidosPage = () => {
	const { isOpen, onOpen, onClose }: any = useDisclosure()
	const { isOpen: isOpenModalWhatsApp, onOpen: onOpenModalWhatsApp, onClose: onCloseModalWhatsApp }: any = useDisclosure()

	const [numero, setNumero]: any = useState()
	const pedidos: any = useContext(PedidosContext);
	const [details, setDetails]: any = useState<any>();


	function OpenModal(valor_total: any, data_pedido: any, id_pedido: any, forma_pagamento: any, hora_pedido: any) {

		//Recebe os parâmetros do pedido que o usuário clicar, e setta eles na variável "details", onde será acessada dentro do Modal para mostrar os detalhes do Pedido.

		setDetails({ valor_total, data_pedido, id_pedido, forma_pagamento, hora_pedido })
		// Aqui abre o Modal
		onOpen();

	}

	function EnviarPedidoWhatsApp(valor_total: any, dataPedido: any, idPedido: any, forma_pagamento: string, hora_pedido: any, numero: number) {

		api.post('pedido/enviarpedidoWhatsApp', { valor_total, dataPedido, idPedido, hora_pedido, forma_pagamento, numero }).then(() => {
			onCloseModalWhatsApp()
			message.success(`Pedido enviado com sucesso para ${numero}`)
		})
			.catch((error: any) => {
				message.error("Não foi possível enviar o pedido para +55 (38)997489630")
			})
	}

	const generatePDF = (valor_total: any, data_pedido: any, id_pedido: number, forma_pagamento: string, nome: string, hora_pedido: string) => {
		var doc = new jsPDF('p', 'pt');
		doc.addFont('Roboto', 'Roboto', 'normal')
		doc.setFont('Roboto')
		doc.text(`DETALHES DO PEDIDO ${id_pedido}`, 20, 20)
		doc.text(`Id do pedido = ${id_pedido}`, 20, 40)
		doc.text(`Nome: ${nome}`, 20, 60)
		doc.setFont('Arial')
		doc.text(`Hora de realizacao do pedido: ${hora_pedido}`, 20, 100)
		doc.text(`Forma de pagamento: ${forma_pagamento}`, 20, 120)
		doc.text(`Data do pedido = ${data_pedido}`, 20, 140)
		doc.text(`Valor do pedido = ${valor_total?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}`, 20, 160)
		doc.addImage('./logosuc.png', 'JPEG', 100, 200, 380, 120)
		doc.save(`StatusPedido${id_pedido}.pdf`)

	}

	return (
		<>

			<VStack bg={"gray.100"}>
				<VStack w="full" minH={"400px"} bg="white" boxShadow={'0px 0px 8px 1px #6868688a'} borderRadius={6}>

					{pedidos?.[0]?.id_pedido > 0 ?

						<>
							<VStack my={5}>
								<Text textColor={'blackAlpha.800'} fontSize={"lg"} fontWeight={"semibold"}>Último pedido</Text>
							</VStack>
							<HStack px={4} mt={4} w="full">
								<VStack>
									<Text textColor={'blackAlpha.800'} fontWeight={"semibold"} fontSize="md">
										Número do pedido
									</Text>
									<Heading size={"md"}>
										{pedidos?.[0]?.id_pedido}
									</Heading>
								</VStack>
								<Spacer />
								<VStack>
									<Text textColor={'blackAlpha.800'} fontWeight={"semibold"} fontSize="md">
										Situação
									</Text>
									<Heading size={"md"}>
										<HStack justify={'center'}>
											<Text>Pedido enviado</Text>
											<Image w={'8%'} src="https://cdn-icons-png.flaticon.com/512/411/411763.png"></Image>
										</HStack>
									</Heading>


								</VStack>
								<Spacer />
								<Center>
									<Button onClick={() => OpenModal(pedidos?.[0]?.valor_total, pedidos?.[0]?.data_pedido, pedidos?.[0]?.id_pedido, pedidos?.[0]?.forma_pagamento, pedidos?.[0]?.hora_pedido)} fontSize={"md"} fontWeight={"normal"} colorScheme={"green"}>Ver detalhes</Button>
								</Center>
							</HStack>
							<Button onClick={onOpenModalWhatsApp} fontSize={"md"} fontWeight={"normal"} rightIcon={<WhatsAppOutlined />} colorScheme={"whatsapp"}>Enviar Pedido Whatsapp</Button>
							<Divider />
							<VStack borderRadius={3} pt={5} pl={5} w="full" alignItems={"start"}>
								<HStack>
									<Text textColor={'blackAlpha.800'} fontWeight={"semibold"} fontSize="md">
										Data do Pedido:
									</Text>
									<Text fontSize={"md"}>
										{pedidos?.[0]?.data_pedido}
									</Text>

								</HStack>
								<HStack>
									<Text textColor={'blackAlpha.800'} fontWeight={"semibold"} fontSize="md">
										Hora do Pedido:
									</Text>
									<Text fontSize={"md"}>
										{pedidos?.[0]?.hora_pedido}
									</Text>

								</HStack>
								<HStack>
									<Text textColor={'blackAlpha.800'} fontWeight={"semibold"} fontSize="md">
										Forma de pagamento:
									</Text>
									<Text fontSize={"md"}>
										{pedidos?.[0]?.forma_pagamento === 'CARTÃO' ?
											<HStack>
												<Text fontSize={16}>Cartão</Text>
												<Image w={'20%'} src="https://i.ibb.co/0qCZNjT/debit-card.png" /> <Image />
											</HStack>

											:
											pedidos?.[0]?.forma_pagamento === 'PIX' ?
												<HStack>
													<Text fontSize={16}>Pix</Text>
													<Image w={'20%'} src="https://i.ibb.co/C5dwN4L/99538099-3b7a5d00-298b-11eb-9f.png" /> <Image />
												</HStack>
												:
												pedidos?.[0]?.forma_pagamento === 'DINHEIRO' &&
												<HStack>
													<Text fontSize={16}>Dinheiro</Text>
													<Image w={'20%'} src="https://i.ibb.co/98XcKjK/cash-payment-1.png" /> <Image />
												</HStack>

										}
									</Text>

								</HStack>
								<HStack>
									<Badge fontFamily={'Roboto, sans-serif'} mb={2} colorScheme={'telegram'} textColor={'blackAlpha.800'} fontWeight={"semibold"} fontSize="md">
										Valor Total =⠀
										{(pedidos?.[0]?.valor_total)?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
									</Badge>
								</HStack>
								<Button onClick={() => generatePDF(pedidos?.[0]?.valor_total, pedidos?.[0]?.data_pedido, pedidos?.[0]?.id_pedido, pedidos?.[0]?.forma_pagamento, pedidos?.[0]?.nome, pedidos?.[0]?.hora_pedido)} variant={'outline'} colorScheme={'blackAlpha'} bottom={2}>Download XML</Button>

							</VStack></>
						:
						<Text p={5} textColor={'black'} fontSize={25}>Você ainda não têm pedidos confirmados!</Text>

					}
				</VStack>
				<VStack w="full" minH={"300px"} bg="white" borderRadius={2}>
					<SimpleGrid mt={4} columns={5} w="full">
						<VStack>
							<Text textColor={'blackAlpha.800'} fontWeight={"semibold"} fontSize="md">
								Código
							</Text>
						</VStack>
						<VStack>
							<Text textColor={'blackAlpha.800'} fontWeight={"semibold"} fontSize="md">
								Data
							</Text>
						</VStack>
						<VStack>
							<Text textColor={'blackAlpha.800'} fontWeight={"semibold"} fontSize="md">
								Situação
							</Text>
						</VStack>
						<VStack>
							<Text textColor={'blackAlpha.800'} fontWeight={"semibold"} fontSize="md">
								Valor total
							</Text>
						</VStack>
						<VStack>
							<Text textColor={'blackAlpha.800'} fontWeight={"semibold"} fontSize="md">
								Ação
							</Text>
						</VStack>
					</SimpleGrid>
					<Divider />
					<SimpleGrid columns={5} w="full">

						{pedidos?.map((allpedidos: Pedidos) => {
							return (
								<Show key={allpedidos.id_pedido}>
									<VStack>
										<Text textColor={'blackAlpha.800'} fontSize="md">
											{allpedidos?.id_pedido}
										</Text>
									</VStack>
									<VStack>
										<Text textColor={'blackAlpha.800'} fontSize="md">
											{allpedidos?.data_pedido}
										</Text>
									</VStack>
									<VStack>
										<HStack justify={'center'} align={'center'}>
											<Text textColor={'blackAlpha.800'} fontSize="md">
												{allpedidos?.status_pedido === 1 ?
													<Text>Recebido</Text>
													:
													allpedidos?.status_pedido === 2 ?
														<Text>Faturado</Text>
														:
														allpedidos?.status_pedido === 3 ?
															<Text>Nota fiscal emitida</Text>
															:
															allpedidos?.status_pedido === 4 ?
																<Text>Despachado</Text>
																:
																allpedidos?.status_pedido === 5 &&
																<Text>Entregue</Text>

												}
											</Text>
											{allpedidos?.status_pedido === 1 ?
												<Image w={'6%'} src="https://cdn-icons-png.flaticon.com/512/2558/2558180.png"></Image>
												:
												allpedidos?.status_pedido === 2 ?
													<Image w={'6%'} src="https://cdn.awsli.com.br/1356/1356417/arquivos/shopping-list.png"></Image>
													:
													allpedidos?.status_pedido === 3 ?
														<Image w={'6%'} src="https://cdn-icons-png.flaticon.com/512/2117/2117251.png"></Image>
														:
														allpedidos?.status_pedido === 4 ?
															<Image w={'8%'} src="https://w7.pngwing.com/pngs/827/790/png-transparent-e-commerce-computer-icons-order-fulfillment-trolley-angle-triangle-publishing.png"></Image>
															:
															allpedidos?.status_pedido === 5 &&
															<Image w={'8%'} src="https://www.clipartmax.com/png/middle/189-1896179_how-e-commerce-startups-can-penetrate-a-saturated-market-pedidos-icon.png"></Image>

											}
										</HStack>
									</VStack>
									<HStack mb={3} pl={'40%'}>
										<Text fontFamily={'Roboto, sans-serif'} textColor={'blackAlpha.800'} fontSize="md">
											<b>{(allpedidos?.valor_total)?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</b>
										</Text>
									</HStack>
									<VStack align={'center'}>
										<HStack align={'initial'}>

											<Button onClick={() => OpenModal(allpedidos?.valor_total, allpedidos?.data_pedido, allpedidos?.id_pedido, allpedidos?.forma_pagamento, allpedidos?.hora_pedido)} colorScheme={"whatsapp"} fontSize={"md"} fontWeight={"normal"} variant={"ghost"}>
												Ver detalhes
											</Button>
											<Button size={'sm'} variant={'outline'} colorScheme={'blackAlpha'} onClick={() => generatePDF(allpedidos?.valor_total, allpedidos?.data_pedido, allpedidos?.id_pedido, allpedidos?.forma_pagamento, allpedidos?.nome, allpedidos?.hora_pedido)}>Download XML</Button>
										</HStack>

									</VStack>
								</Show>
							)

						})}

					</SimpleGrid>
					<Divider />
				</VStack>
			</VStack>

			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent borderRadius={10}>
					<ModalHeader>Detalhes</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<VStack align={'initial'}>
							<Text textColor={'black'} fontFamily={'Roboto, sans-serif'} fontSize={18}>ID do pedido: {details?.id_pedido}</Text>
							<Text textColor={'gray.700'} fontFamily={'Roboto, sans-serif'} fontSize={18}>Data do pedido: {details?.data_pedido}</Text>
							<Text textColor={'gray.700'} fontFamily={'Roboto, sans-serif'} fontSize={18}>Hora de confirmação do pedido: {details?.hora_pedido}</Text>
							<HStack justify={'initial'} align={'initial'}>
								<Text textColor={'gray.700'} fontFamily={'Roboto, sans-serif'} fontSize={18}>Forma de pagamento: {details?.forma_pagamento}</Text>
								{details?.forma_pagamento === 'PIX' &&
									<Image w={'10%'} src="https://i.ibb.co/C5dwN4L/99538099-3b7a5d00-298b-11eb-9f.png"></Image>

								}
								{details?.forma_pagamento === 'DINHEIRO' &&
									<Image w={'10%'} src="https://i.ibb.co/98XcKjK/cash-payment-1.png"></Image>

								}
								{details?.forma_pagamento === 'CARTÃO' &&
									<Image w={'10%'} src="https://i.ibb.co/0qCZNjT/debit-card.png"></Image>

								}
							</HStack>
							<Text textColor={'black'} fontFamily={'Roboto, sans-serif'} fontSize={18}>Valor total = <b>{details?.valor_total?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</b></Text>
						</VStack>
					</ModalBody>

					<ModalFooter>
						<Button colorScheme='blue' mr={3} onClick={onClose}>
							Fechar
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>


			<Modal isOpen={isOpenModalWhatsApp} onClose={onCloseModalWhatsApp}>
				<ModalOverlay />
				<ModalContent >
					<ModalHeader>Instruções</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<VStack>
							<QRCode
								id="qrCodeElToRender"
								size={200}
								value={'https://api.whatsapp.com/send?phone=14155238886&text=join%20angry-machine'}
								bgColor="white"
								fgColor="#1a1d24"
								level="H"

							/>
							<Text fontSize={17}><b>Por favor, faça o scan do QRCode acima e envie a mensagem que estará digitada para o número informado para que seu numero seja confirmado na API!</b></Text>
							<Text textColor={'black'} fontSize={16} p={2}>Digite seu numero do whatsapp no campo abaixo (incluindo DDD)</Text>
							<Input type={'number'} onChange={(e: any) => setNumero(e.target.value)} placeholder="Digite seu numero aqui!" p={3}></Input>
							<Button onClick={() => EnviarPedidoWhatsApp(pedidos?.[0]?.valor_total, pedidos?.[0]?.data_pedido, pedidos?.[0]?.id_pedido, pedidos?.[0]?.forma_pagamento, pedidos?.[0]?.hora_pedido, numero)} variant={'solid'} colorScheme={'green'}>Enviar</Button>
						</VStack>
					</ModalBody>

					<ModalFooter>
						<Button colorScheme='blue' mr={3} onClick={onCloseModalWhatsApp}>
							Fechar
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>

		</>
	);
}