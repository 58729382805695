import useSWR from "swr";
import { apiEndPoint } from "../util/api";

// Hook criado para buscar todos os usuários cadastrados no banco de dados (usado na rota /adm)

export function GetAllUsers() {
	const token = localStorage.getItem('@App:Access_Token')
	const fetcher = (url: any) => fetch(apiEndPoint + `/admin/allusers`, {
		headers: {
			'Authorization': `Bearer ${token}`,
		}
	}).then(response => response.json());

	const { data, error, mutate } = useSWR(apiEndPoint + 'admin/allusers', fetcher)

	return {
		allusers: data,
		isLoading: !error && !data,
		error: error,
		mutateusers: mutate
	}
}