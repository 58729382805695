import { Skeleton, VStack } from "@chakra-ui/react";
export const SkeletonLoadingTopRatedProducts = () => {
	return (

		<VStack h={'full'} w={{ "lg": "70%", "xl": "100%" }} className="animacao-bg" >
			<VStack justify={'center'} pt={16} align={'center'} w={"full"} >
				<Skeleton borderRadius={5} h={"240px"} w={"99%"} />
			</VStack>
		</VStack >


	);
}