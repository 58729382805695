/* eslint-disable @typescript-eslint/no-unused-vars */
import { BgColorsOutlined, CopyTwoTone, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { Alert, AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, AlertIcon, Button, Divider, HStack, Image, Input, Menu, MenuButton, MenuItem, MenuList, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Progress, Spacer, Spinner, Tab, TabList, TabPanel, TabPanels, Tabs, Text, Tooltip, useDisclosure, useToast, VStack } from '@chakra-ui/react';
import { Avatar, Group, Select } from '@mantine/core';
import { message } from 'antd';
import axios from 'axios';
import copy from "copy-to-clipboard";
import React, { forwardRef, useEffect, useState } from 'react';
import { FiTrash } from 'react-icons/fi';
import { IoIosArrowBack } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import { GetCarrinho } from '../../hooks/GetCarrinho';
import { GetMe } from '../../hooks/GetMe';
import { Produto } from '../../types/types';
import { api } from '../../util/api';


export const CarrinhoComprasPageMobile = () => {

    const { me, isLoadingMe, mutateMe } = GetMe();
    const [corProduct, setCorProduct]: any = useState();
    const [isProductLoading, setIsProductLoading] = useState(false);
    const [somaitens, setSomaItens] = useState(0);
    var [copyText, setCopyText]: any = useState(null);
    var [qrcode, setQrCode] = useState<any>(0);
    const [quantidademaior, setQuantidadeMaior]: any = useState();

    const { dataCarrinho, error, mutateCarrinho } = GetCarrinho()

    useEffect(() => {
        // Verificará se existe um usuário logado, caso contrário ele redirecionará o usuário de volta para a página inicial
        if (!isLoadingMe && me?.statusCode === 401) {
            navigate('/empresas/all');
        }
    })

    useEffect(() => {

        // Inicia o loading até que o carrinho seja carregado ou que ocorra algum erro

        setIsProductLoading(true)

        if (dataCarrinho?.length === 0)
            setIsProductLoading(false);
        if (dataCarrinho?.length > 0)
            setIsProductLoading(false);
        if (error)
            setIsProductLoading(false)


    }, [dataCarrinho, error])

    interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
        image: string;
        label: string;
        description: string;
    }

    function delay(n: any) {
        return new Promise(function (resolve) {
            setTimeout(resolve, n * 1000);
        });
    }


    const [inputUnidades, setInputUnidades]: any = useState(false)
    const [edit, setEdit]: any = useState();
    const [quantity, setQuantity]: any = useState(1);

    const [editLoading, setEditLoading]: any = useState()
    const [alterarProduto, setAlterarProduto]: any = useState(0)

    const toast = useToast();
    const [editQuantityLoading, setEditQuantityLoading]: any = useState()
    const { isOpen: isOpenFazerPedido, onOpen: onOpenFazerPedido, onClose: onCloseFazerPedido } = useDisclosure();
    const { isOpen: isOpenEdit, onOpen: onOpenEdit, onClose: onCloseEdit } = useDisclosure();
    const { isOpen: isOpenPixMethod, onOpen: onOpenPixMethod, onClose: onClosePixMethod } = useDisclosure();
    const { isOpen: isOpenCardMethod, onOpen: onOpenCardMethod, onClose: onCloseCardMethod } = useDisclosure();
    const { isOpen: isOpenMoneyMethod, onOpen: onOpenMoneyMethod, onClose: onCloseMoneyMethod } = useDisclosure();
    const { isOpen: isOpenLoadingModal, onOpen: onOpenLoadingModal, onClose: onCloseLoadingModal } = useDisclosure();
    const initialRef = React.useRef(null);
    const finalRef = React.useRef(null);


    const [paymentmethod, setPaymentMethod]: any = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        var allPrices = dataCarrinho?.map((products: any) => products.preco_produto * products.quantidade_produto);
        let somaPrices = 0;

        for (let i = 0; i < allPrices?.length; i++) {
            somaPrices += allPrices[i];
        }
        setSomaItens(somaPrices);


    }, [dataCarrinho])

    function EditProduct(id_produto: any, nome_produto: string, preco: number, quantidade_selecionada: number, quantidade_total: number, cor_produto: string) {
        setEdit({ id_produto, nome_produto, preco, quantidade_selecionada, quantidade_total, cor_produto })
        onOpenEdit()
    }


    function deleteFromCart(productsId: any) {

        // Remove um item do carrinho, passando para a query o parâmetro ID do produto

        const route: string = `/user/removerproduto/${productsId}`;
        setIsProductLoading(true);
        api.delete(route).then(() => {
            mutateCarrinho();
            setIsProductLoading(false);

        })

    }

    function clearCart() {

        // Limpa o carrinho removendo todos os produtos nele existentes.

        onClose();
        const route: string = `/user/clearcart`;
        setIsProductLoading(true);
        api.delete(route).then(() => {
            mutateCarrinho();
            setIsProductLoading(false);
        }).catch((error: any) => {
            message.error("Não foi possível limpar o carrinho! Por favor tente novamente mais tarde!")
            setIsProductLoading(false)
        })
    }

    async function EditarQuantidadeProduto(idProduto: number, quantidade_selecionada: number, quantidade_disponivel: number) {
        if (quantidade_selecionada === undefined) {
            message.destroy()
            message.error("Por favor, selecione uma quantidade para adicionar o produto ao carrinho!");
            return 1;
        }
        else
            if (quantidade_selecionada > quantidade_disponivel) {
                toast.closeAll()
                toast({
                    title: 'Erro ao alterar quantidade!',
                    description: `Estoque disponível de apenas ${quantidade_disponivel} itens!`,
                    status: 'error',
                    duration: 3000,
                    isClosable: true
                })
                return 1;
            }
            else if (quantidade_selecionada == 0) {
                toast.closeAll()
                toast({
                    title: 'Erro ao alterar quantidade!',
                    description: `Não é possível alterar para essa quantidade (0)!`,
                    status: 'error',
                    duration: 3000,
                    isClosable: true
                })
                return 1;
            }
            else
                onCloseEdit()
        onOpenLoadingModal()
        await delay(1)
        api.put('/user/cart/editarquantproduto', { idProduto, quantidade_selecionada }).then(() => {
            mutateCarrinho()
            onCloseLoadingModal()
        })
        await delay(0.5)
        setEditQuantityLoading(false)
    }



    const { isOpen, onOpen, onClose } = useDisclosure();
    const cancelRef = React.useRef(null);


    const dadosSelect = [
        {
            image: 'https://i.ibb.co/C5dwN4L/99538099-3b7a5d00-298b-11eb-9f.png',
            label: 'Pix',
            value: 'PIX',
            description: 'Pix é o pagamento instantâneo brasileiro. O meio de pagamento criado pelo Banco Central (BC)',
        },

        {
            image: 'https://i.ibb.co/0qCZNjT/debit-card.png',
            label: 'Cartão',
            value: 'CARTÃO',
            description: 'Cartões de crédito, débito ou vale alimentação.',
        },
        {
            image: 'https://i.ibb.co/98XcKjK/cash-payment-1.png',
            label: 'Dinheiro',
            value: 'DINHEIRO',
            description: 'Pagamento a vista com dinheiro em espécie.',
        }
    ];



    function ConfirmOrder() {

        // Essa função verifica se o carrinho está vazio (enquanto a variável somaitens < 1)
        // Se a condição for "true" ele confirma o pagamento e envia o método de pagamento e o preço de todos os produtos no carrinho para a api


        if (somaitens < 1) {
            onCloseFazerPedido();
            message.destroy();
            message.error({
                content: 'Adicione produtos no carrinho antes de confirmar o pedido!',
                className: 'custom-class',
                duration: 2,
                style: {
                    fontFamily: 'Roboto, sans-serif',
                    fontSize: 15,
                    zIndex: 10,

                },

            });
        }
        if (paymentmethod !== null && somaitens > 1) {
            message.destroy();
            onCloseFazerPedido();
            onClosePixMethod();
            onCloseCardMethod();
            onCloseMoneyMethod();
            message.loading('Confirmando pedido...')
            const route: string = 'pedido';
            api.post(route, { dataCarrinho, paymentmethod, somaitens }).then(() => {
                message.destroy();
                message.success("Pedido efetuado com sucesso!");
                clearCart();

            }).catch((error: any) => {
                message.destroy();

                message.error("Não foi possível confirmar sua compra!")
            })

        }
    }



    function VerifyPaymentMethod() {

        // Verifica se o método de pagamento é igual a "Pix", "Cartão" ou "Dinheiro", chamando para cada um sua respectiva modal

        if (paymentmethod === 'PIX')
            PixPayment();
        if (paymentmethod === 'CARTÃO')
            CardPayment();
        if (paymentmethod === 'DINHEIRO')
            MoneyPayment();
    }

    async function PixPayment() {

        // Fecha a modal do método de pagamento e abre a modal do pagamento por PIX

        onCloseFazerPedido();
        onOpenPixMethod();

        setQrCode(0);
        await delay(3);
        axios.get("https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=Example").then(async (response: any) => {
            setQrCode(response?.config?.url)

        })

    }
    async function CardPayment() {

        // Fecha a modal do método de pagamento e abre a modal do pagamento por cartão

        onCloseFazerPedido();
        onOpenCardMethod();

    }

    async function MoneyPayment() {

        // Fecha a modal do método de pagamento e abre a modal do pagamento em dinheiro

        onCloseFazerPedido();
        onOpenMoneyMethod();

    }


    function getRandomInt(min: any, max: any) {
        // Gera um número aleatório
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min)) + min;
    }

    const generated_number = getRandomInt(100000000000, 10000000000010000);

    const copyToClipboard = () => {

        // Copia a chave pix (número aleatório gerado) para a área de transferência
        copyText = generated_number
        copy(copyText);
        message.destroy();
        message.success({
            content: 'Chave PIX copiada!',
            className: 'custom-class',

            style: {
                fontFamily: 'Roboto, sans-serif',
                fontSize: 14,
                zIndex: 10,

            },

        });

    }

    function AlterarQuantidade(value: any) {
        if (value > edit?.quantidade_total) {
            message.destroy();
            message.error({
                content: 'A quantidade selecionada não está disponível no estoque!',
                className: 'custom-class',
                duration: 2,
                style: {
                    fontFamily: 'Roboto, sans-serif',
                    fontSize: 15,
                    zIndex: 1000,
                    top: 10

                },

            });
        }
        else {
            setQuantity(value)
            setInputUnidades(false)
        }
    }

    async function EditarCorProduto(idProduto: number, cor: string) {
        setEditLoading(true)
        setAlterarProduto(0)
        await delay(1)
        api.put('/user/cart/editarcorproduto', { idProduto, cor }).then(() => {
            mutateCarrinho()
        })
        await delay(0.5)
        setEditLoading(false)
    }

    const [numerocartao, SetNumeroCartao]: any = useState<any>();
    const [nomecompleto, SetNomeCompleto] = useState();
    const [datavenc, SetDataVenc] = useState();
    const [codigoseguranca, SetCodigoSeguranca] = useState();
    const [cpf, SetCpf] = useState();


    const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
        ({ image, label, description, ...others }: ItemProps, ref) => (
            <div ref={ref} {...others}>
                <Group noWrap>
                    <Avatar src={image} />

                    <div>
                        <Text size="sm">{label}</Text>
                        <Text size="xs" color="dimmed">
                            {description}
                        </Text>
                    </div>
                </Group>
            </div>
        )
    );

    return (

        <VStack key={dataCarrinho?.id_carrinho} h={isProductLoading ? '450px' : 'full'} mx={2}>

            <HStack w="full" bg="white" py={5}>

                <Button fontSize={14} onClick={() => navigate('/')} leftIcon={<IoIosArrowBack />} variant={"outline"} colorScheme={"gray"}>Continuar comprando</Button>
                <Spacer />
                <Button p={5} colorScheme={"whatsapp"} onClick={onOpenFazerPedido}>Finalizar compra</Button>


            </HStack>
            {isProductLoading &&
                <VStack pt={'30%'}>
                    <Spinner speed="0.5s" thickness='3px' size='xl' />
                </VStack>
               
            }
            {dataCarrinho?.length > 0 &&
                <>
                    <Alert w={'auto'} fontSize={14} status='warning'>
                        <AlertIcon />
                        Antes de finalizar a compra verifique se os produtos no seu carrinho estão com a quantidade e preços corretos!
                    </Alert>
                    <Tabs w="full" minH={"300px"} bg={"white"}>
                        {editQuantityLoading &&

                            <Progress w={'full'} h={'5px'} size='xs' isIndeterminate />
                            
                        }
                        <TabList>
                            <Tab fontWeight={"semibold"}>Carrinho</Tab>
                        </TabList>
                        <TabPanels>
                            <TabPanel>

                                {dataCarrinho?.map((products: Produto) => {
                                    return (
                                        <VStack key={products?.id_produto} py={2} >

                                            <HStack w={'100%'} >
                                               
                                                <Image onClick={() => navigate(`/compras/${products?.id_produto}/${products?.id_empresa}`)} cursor={'pointer'} w="120px" src={products.image_produto} pr={10} />
                                                <VStack alignItems={"start"} pr={3}>
                                                    <Text fontFamily={'Inter, sans-serif'} textColor={'black'} fontSize={14}><b>{products.descricao5}</b></Text>
                                                    <Text fontFamily={'Inter, sans-serif'} textColor={'gray'} fontSize={14}>Id do produto: {products.id_produto}</Text>
                                                    <Text py={1} lineHeight={1.05} fontFamily={'Inter, sans-serif'} textColor={'black'} fontSize={17}><b>{(products?.preco_produto * products?.quantidade_produto)?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</b></Text>
                                                    <Text fontFamily={'Inter, sans-serif'} textColor={'gray'} fontSize={16}>Unidades: <b>{products.quantidade_produto}</b> ({products.salitoqtd5} disponíveis)</Text>

                                                    {products?.cor_produto !== 'undefined' &&

                                                        <Text textAlign={'center'} fontFamily={'Inter, sans-serif'} textColor={'black'} fontSize={16}> <b>Cor do produto: {products?.cor_produto}</b></Text>
                                                    }
                                                    {products?.cor1?.length > 0 &&
                                                        <>
                                                            <Button w={'full'} textAlign={'center'} isLoading={editLoading === true ? true : false} onClick={() => setAlterarProduto(1)} colorScheme={'blue'} variant={'link'}>Alterar cor<BgColorsOutlined style={{ marginLeft: 4 }} /></Button>
                                                            {alterarProduto === 1 &&
                                                                <Menu>
                                                                    <MenuButton disabled={products?.[0]?.quantidade === 0 ? true : false} value={products?.cor_produto} _hover={{}} as={Button} rightIcon={<ChevronDownIcon />}>

                                                                        {corProduct !== undefined ? corProduct : 'Selecionar cor'}
                                                                    </MenuButton>
                                                                    <MenuList fontSize={16}>
                                                                        <MenuItem >Selecionar cor:</MenuItem>
                                                                        <MenuItem onClick={() => EditarCorProduto(products?.id_produto, products?.cor1)}>{products?.cor1}</MenuItem>
                                                                        <MenuItem onClick={() => EditarCorProduto(products?.id_produto, products?.cor2)}>{products?.cor2}</MenuItem>
                                                                        <MenuItem onClick={() => EditarCorProduto(products?.id_produto, products?.cor3)}>{products?.cor3}</MenuItem>
                                                                    </MenuList>
                                                                </Menu>
                                                                    
                                                            }
                                                        </>
                                                    }
                                                    <HStack py={2}>
                                                        <Button onClick={() => EditProduct(products?.id_produto, products?.descricao5, products?.preco_produto, products?.quantidade_produto, products?.quantidade, products?.cor_produto)} variant={"link"} colorScheme={"blue"}>Editar<EditOutlined /></Button>

                                                        <Button pl={2} onClick={() => deleteFromCart(products.id_produto)} variant={"link"} colorScheme={"blue"}>Excluir<DeleteOutlined /></Button>

                                                    </HStack>
                                                </VStack>
                                                <Spacer />
                                                <VStack alignItems={"end"}>
                                                </VStack>
                                            </HStack>
                                            <Divider orientation="horizontal" borderColor={'gray.300'} />
                                        </VStack>
                                    )
                                })}
                                <VStack>
                                    <Text fontSize={16} whiteSpace={'nowrap'} fontWeight={"semibold"}>Subtotal ({dataCarrinho?.length} {dataCarrinho?.length === 1 ? 'item' : 'itens'}):</Text>
                                    <Text fontSize={18} whiteSpace={'nowrap'} fontWeight={"semibold"} >{dataCarrinho?.length > 0 ? somaitens?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : ''}</Text>
                                </VStack>
                                <VStack w="full" bg="white" py={5}>
                                    <Button onClick={() => {

                                        onOpen();

                                    }}
                                        leftIcon={<FiTrash />} variant={"ghost"} colorScheme={"red"}>Limpar carrinho

                                    </Button>
                                </VStack>

                            </TabPanel>
                            <TabPanel>
                                <p>two!</p>
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </>
            }
            {dataCarrinho < 1 &&

                <VStack w="full" bg={"white"} minH={"300px"}>
                    <Text p={3} pt={3} fontFamily={'Inter, sans-serif'} align={'center'} fontSize={25} textColor='gray.700'>O seu carrinho está vazio.</Text>
                    <Text p={3} pt={1} fontFamily={'Inter, sans-serif'} align={'center'} fontSize={22} textColor='gray'>Não sabe o que comprar? Centenas de produtos esperam por você</Text>
                </VStack>


            }

            <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Confirmação!
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            <Text fontSize={"lg"}>Tem certeza de que deseja limpar o carrinho?</Text>
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                Cancelar
                            </Button>
                            <Button colorScheme="red" onClick={clearCart} ml={3}>
                                Deletar
                            </Button>
                        </AlertDialogFooter>

                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>


            {/* Modal que abre tela de Selecionar método de pagamento */}
            <Modal
                initialFocusRef={initialRef}
                finalFocusRef={finalRef}
                isOpen={isOpenFazerPedido}
                onClose={onCloseFazerPedido}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Efetuar pedido</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>


                        <Select
                            onChange={((e: any) => setPaymentMethod(e))}
                            zIndex={4000}
                            label="Forma de pagamento"
                            placeholder="Escolha um"
                            itemComponent={SelectItem}
                            data={dadosSelect}
                            searchable
                            maxDropdownHeight={400}
                            nothingFound="Não encontrado!"
                            filter={(value, item) =>
                                item?.label?.toLowerCase().includes(value.toLowerCase().trim()) ||
                                item.description.toLowerCase().includes(value.toLowerCase().trim())
                            }
                        />
                    </ModalBody>

                    <ModalFooter>
                        <Button onClick={() => VerifyPaymentMethod()} colorScheme='whatsapp' mr={3}>
                            Continuar
                        </Button>
                        <Button onClick={onCloseFazerPedido}>Cancelar</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>




            {/* Modal que abre tela do pagamento por pix */}
            <Modal closeOnOverlayClick={false} isOpen={isOpenPixMethod} onClose={onClosePixMethod}>
                <ModalOverlay zIndex={0} />
                <ModalContent >
                    <ModalHeader >SELECIONADO: PIX</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <VStack>
                            {somaitens < 1 ?
                                <>
                                    <Text textColor={'black'} fontFamily={'Roboto, sans-serif'} fontSize={20}>Seu carrinho está vazio!</Text>
                                    <Text textColor={'gray.600'} fontFamily={'Roboto, sans-serif'} fontSize={16}>Adicione produtos no carrinho e volte aqui mais tarde!</Text>
                                </>

                                :
                                <>
                                    <Text textColor={'black'} fontFamily={'Roboto, sans-serif'} fontSize={17}>Seu pedido tem um preço total de {somaitens?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</Text>
                                    <Text fontSize={17}>Gerando o seu QR-Code...</Text>
                                    <Image p={3} src={qrcode === 0 ?
                                        'https://cdn.dribbble.com/users/902865/screenshots/4814970/loading-opaque.gif'
                                        :
                                        qrcode}
                                    >
                                    </Image>

                                    {qrcode !== 0 &&
                                        <>
                                            <Text fontSize={18} textColor={'green'} fontFamily={'Roboto, sans-serif'}>QR Code gerado com sucesso!</Text>
                                            <HStack>
                                                <Text onChange={((e: any) => setCopyText(generated_number))} fontSize={18}>Chave aleatória: {generated_number}
                                                </Text>

                                                <Tooltip fontSize={14} p={2} label={'Copiar chave PIX'} style={{ cursor: 'pointer' }}>
                                                    <CopyTwoTone style={{ fontSize: 18 }} onClick={copyToClipboard} />
                                                </Tooltip>
                                            </HStack>
                                        </>
                                       
                                    }
                                </>
                            }
                        </VStack>
                    </ModalBody>

                    <ModalFooter>
                        <Button onClick={ConfirmOrder} mr={3} colorScheme={'green'} >Continuar</Button>
                        <Button colorScheme='blue' onClick={onClosePixMethod}>
                            Fechar
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>


            {/* Modal que abre tela do pagamento por cartão */}
            <Modal closeOnOverlayClick={false} isOpen={isOpenCardMethod} onClose={onCloseCardMethod}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>SELECIONADO: Cartão</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {somaitens < 1 ?
                            <>
                                <Text textColor={'black'} fontFamily={'Roboto, sans-serif'} fontSize={20}>Seu carrinho está vazio!</Text>
                                <Text textColor={'gray.600'} fontFamily={'Roboto, sans-serif'} fontSize={16}>Adicione produtos no carrinho e volte aqui mais tarde!</Text>
                            </>
                            :
                            <>
                                <Text mt={2} textColor={'black'} fontFamily={'Roboto, sans-serif'} fontSize={18}>Seu pedido tem um preço total de {somaitens?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</Text>
                                <Text mt={2} textColor={'black'} fontFamily={'Roboto, sans-serif'} fontSize={17}>Por favor adicione um novo cartão</Text>
                                <Text mt={2} fontSize={17} fontFamily={'Roboto, sans-serif'} textColor={'black'}><b>Número do cartão</b></Text>

                                <HStack>
                                    <Input maxLength={16} onChange={((e: any) => SetNumeroCartao(e.target.value))} p={3} mt={2} placeholder='Número do cartão'></Input>
                                    {numerocartao?.substring(0, 2) === '41' ?
                                        <Image w={'18%'} src='https://logodownload.org/wp-content/uploads/2016/10/visa-logo-2.png'></Image>
                                        :
                                        <></>
                                    }

                                    {numerocartao?.substring(0, 2) === '51' || numerocartao?.substring(0, 2) === '52'
                                        || numerocartao?.substring(0, 2) === '53'
                                        || numerocartao?.substring(0, 2) === '54'
                                        || numerocartao?.substring(0, 2) === '55' ?
                                        <Image w={'18%'} src='https://cashfree.com.br/wp-content/uploads/2020/09/Mastercard-Standart.png'></Image>
                                        :
                                        <></>
                                    }
                                    {numerocartao?.substring(0, 2) === '36'
                                        || numerocartao?.substring(0, 2) === '38' ?
                                        <Image w={'22%'} src='https://logodownload.org/wp-content/uploads/2016/10/Diners-Club-Logo.png'></Image>
                                        :
                                        <></>
                                    }
                                    {numerocartao?.substring(0, 4) === '6011'
                                        || numerocartao?.substring(0, 2) === '65' ?
                                        <Image w={'20%'} src='https://emalta.com.br/wp-content/uploads/2016/10/elo-compras-internacionais.jpg'></Image>
                                        :
                                        <></>
                                    }
                                    {numerocartao?.substring(0, 2) === '35' ?
                                        <Image w={'18%'} src='https://logodownload.org/wp-content/uploads/2018/05/jcb-logo.png'></Image>
                                        :
                                        <></>
                                    }
                                    {numerocartao?.substring(0, 2) === '34'
                                        || numerocartao?.substring(0, 2) === '37' ?
                                        <Image w={'18%'} src='https://0800bancos.com.br/wp-content/uploads/2019/08/American-Express-Amex.jpg'></Image>
                                        :
                                        <></>
                                    }
                                </HStack>

                                <Text mt={2} fontSize={17} fontFamily={'Roboto, sans-serif'} textColor={'black'}><b>Nome Completo</b></Text>
                                <Input onChange={((e: any) => SetNomeCompleto(e.target.value))} p={3} mt={2} placeholder='Nome completo'></Input>

                                <Text mt={2} fontSize={17} fontFamily={'Roboto, sans-serif'} textColor={'black'}><b>Data de validade</b></Text>
                                <Input type={'month'} onChange={((e: any) => SetDataVenc(e.target.value))} p={3} mt={2} placeholder='Data de vencimento'></Input>

                                <Text mt={2} fontSize={17} fontFamily={'Roboto, sans-serif'} textColor={'black'}><b>Código de segurança</b></Text>
                                <Input maxLength={3} onChange={((e: any) => SetCodigoSeguranca(e.target.value))} p={3} mt={2} placeholder='Código de segurança (SVV)'></Input>

                                <Text mt={2} fontSize={17} fontFamily={'Roboto, sans-serif'} textColor={'black'}><b>CPF</b></Text>
                                <Input maxLength={11} p={3} mt={2} placeholder='CPF do titular do cartão'></Input>
                            </>
                        }
                    </ModalBody>

                    <ModalFooter>
                        <Button onClick={ConfirmOrder} mr={3} colorScheme='green'>
                            Confirmar
                        </Button>
                        <Button colorScheme={'blue'} onClick={onCloseCardMethod}>Fechar</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>


            <Modal closeOnOverlayClick={false} isOpen={isOpenMoneyMethod} onClose={onCloseMoneyMethod}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>SELECIONADO: Dinheiro</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {somaitens < 1 ?
                            <>
                                <Text textColor={'black'} fontFamily={'Roboto, sans-serif'} fontSize={20}>Seu carrinho está vazio!</Text>
                                <Text textColor={'gray.600'} fontFamily={'Roboto, sans-serif'} fontSize={16}>Adicione produtos no carrinho e volte aqui mais tarde!</Text>
                            </>
                            :
                            <>
                                <Text textColor={'black'} fontFamily={'Roboto, sans-serif'} fontSize={17}>Seu pedido tem um preço total de {somaitens?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</Text>
                                <Text textColor={'gray.800'} fontSize={17}>Você precisará de quantos reais de troco?</Text>
                                <Input mt={2} placeholder='Valor do troco'></Input>
                            </>
                        }
                    </ModalBody>

                    <ModalFooter>
                        <Button onClick={ConfirmOrder} mr={3} colorScheme='green'>
                            Confirmar
                        </Button>
                        <Button colorScheme={'blue'} onClick={onCloseMoneyMethod}>Fechar</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <Modal closeOnOverlayClick={true} isOpen={isOpenEdit} onClose={onCloseEdit}>
                <ModalOverlay zIndex={0} />
                <ModalContent top={8} >
                    <ModalHeader>Editando: </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody >
                        <Text fontFamily={'Inter, sans-serif'} textColor={'black'} fontSize={16}><b>{edit?.nome_produto}</b></Text>
                        <Menu>

                            <MenuButton top={3} minW={'190px'} textAlign={'center'} disabled={edit?.quantidade_selecionada === 0 ? true : false} _hover={{}} bg={'transparent'} as={Button} rightIcon={<ChevronDownIcon fontSize={20} color={'blue'} />}>

                                Quantidade: <b>{quantity}</b> unidad{quantity === 1 ? 'e' : 'es'}
                            </MenuButton>
                            <MenuList fontSize={16}>
                                <MenuItem _focus={{ color: 'blue' }} onClick={() => AlterarQuantidade(1)}>1 unidade</MenuItem>
                                <MenuItem _active={{ color: 'blue' }} onClick={() => AlterarQuantidade(2)}>2 unidades</MenuItem>
                                <MenuItem _active={{ color: 'blue' }} onClick={() => AlterarQuantidade(3)}>3 unidades</MenuItem>
                                <MenuItem _active={{ color: 'blue' }} onClick={() => AlterarQuantidade(4)}>4 unidades</MenuItem>
                                <MenuItem _active={{ color: 'blue' }} onClick={() => AlterarQuantidade(5)}>5 unidades</MenuItem>
                                <MenuItem _active={{ color: 'blue' }} onClick={() => AlterarQuantidade(6)}>6 unidades</MenuItem>
                                {inputUnidades === false ?
                                    <MenuItem closeOnSelect={false} _active={{ color: 'blue' }} onClick={() => setInputUnidades(true)}>Mais de 6 unidades</MenuItem>
                                    :
                                    <VStack align={'initial'} py={6}>
                                        <Text>Quantidade:</Text>
                                        <Input type={'number'} maxLength={2} onChange={(e: any) => setQuantidadeMaior(e.target.value)} autoFocus></Input>

                                        <Button w={'40%'} onClick={() => AlterarQuantidade(quantidademaior)}>Aplicar</Button>
                                    </VStack>
                                }
                            </MenuList>
                        </Menu>

                    </ModalBody>

                    <ModalFooter>
                        <Button onClick={() => EditarQuantidadeProduto(edit?.id_produto, quantity, edit?.quantidade_disponivel)} mr={3} colorScheme='green'>
                            Confirmar
                        </Button>
                        <Button colorScheme={'blue'} onClick={onCloseEdit}>Fechar</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>


            <Modal closeOnOverlayClick={false} isOpen={isOpenLoadingModal} onClose={onCloseLoadingModal}>
                <ModalOverlay boxShadow={0} h={'full'} bg={'blackAlpha.200'} />
                <ModalContent boxShadow={0} p={0} top={'40%'} bg={'transparent'}>

                    <ModalBody h={'100%'} bg={'transparent'}>
                        <VStack h={'full'} bg={'transparent'}>
                            <Spinner
                                thickness='4px'
                                speed='0.65s'
                                emptyColor='gray.200'
                                color='blue.500'
                                size='xl'
                            />
                        </VStack>

                    </ModalBody>
                </ModalContent>
            </Modal>

        </VStack>

    )
}