import { VStack } from "@chakra-ui/react";
import { CompaniesResponsivo } from "../components/Responsivos/CompaniesResponsivo";

export const Empresas = () => {
	return (

		<VStack bg={"gray.100"} w={"full"} spacing={3}>
			<CompaniesResponsivo />
		</VStack>

	)
}