import { CaretUpOutlined, LeftOutlined, QrcodeOutlined, RightOutlined, SearchOutlined } from "@ant-design/icons";
import { Avatar, Badge, Box, Button, Divider, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, HStack, Image, Input, InputGroup, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger, Select, Show, SimpleGrid, Spinner, Text, useDisclosure, VStack } from "@chakra-ui/react";
import '@fontsource/inter';
import { message } from "antd";
import { motion } from "framer-motion";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { GetCarrinho } from "../../hooks/GetCarrinho";
import '../../Styles/Globalcss.css';
import { Produto } from "../../types/types";
import { api } from "../../util/api";
import { ProdutosContext } from "../Context/ProdutosContext";
import QRCodeComponent from "../QRCodeEmpresas";

export const ItemMobile = () => {
    const { empresaId, page }: any = useParams();
    const { mutateCarrinho } = GetCarrinho();
    const produtos: any = useContext(ProdutosContext);
    const navigate = useNavigate();
    const [listaProdutos, setListaProdutos]: any = useState(produtos)

    const { isOpen: isOpenModalQrCode, onOpen: onOpenModalQrCode, onClose: onCloseModalQrCode } = useDisclosure();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [name, setName] = useState('');
    const [price, setPrice]: any = useState('');
    const [image, setImage] = useState('');
    const [erroproduto, setErroProduto] = useState<any>(0);
    const [value, setValue]: any = React.useState(0);
    const [quantity, setQuantity]: any = useState();
    const [corProduto, setCorProduto]: any = useState();
    // const busca_categoria = produtos.length > 0 ? produtos.filter((produtos: any) => produtos).reverse((produtos: any) => produtos.preco) : [];
    const [pesquisa, setPesquisa]: any = useState();
    const { isOpen: isOpenLoadingModal, onOpen: onOpenLoadingModal, onClose: onCloseLoadingModal } = useDisclosure();


    // const emdestaqueproducts = produtos.length > 0 ? produtos?.filter((produtos: any) => produtos?.destaque) : [];
    var nmrPage = page.replace(/[^0-9]/g, '');
    parseInt(nmrPage);

    var pagina_anterior: any = Number(nmrPage) - 1;
    var proxima_pagina: any = Number(nmrPage) + 1;

    const nomeuser: any = localStorage.getItem('@App:NomeUser');
    const userAvatarGoogle: any = localStorage.getItem('@App:ImageUser');

    useEffect(() => {
        setListaProdutos(produtos)
        if (listaProdutos !== undefined && listaProdutos?.length === 0)
            setListaProdutos(produtos)
    }, [produtos])

    function PesquisarComEnter(e: any) {
        if (e.keyCode === 13) {
            SearchProductByNameOrCode()
        }
    }

    function seeProductDetails(productId: string) {
        navigate("/compras/" + productId + `/${empresaId}`);
    }

    function PreviousPage() {
        navigate(`/${empresaId}/page=${pagina_anterior}/lista-produtos`)
        setListaProdutos(produtos)
    }

    function NextPage() {
        navigate(`/${empresaId}/page=${proxima_pagina}/lista-produtos`)
        setListaProdutos(produtos)
    }

    async function SearchProductByNameOrCode() {
        onOpenLoadingModal()
        // Função que irá buscar a empresa de acordo com o nome da empresa digitada pelo usuário e mostrará a lista como resposta da requisição

        if (pesquisa?.length > 0) {

            await api.post('/produtos/buscaprodutos', { pesquisa }).then((response: any) => {

                if (response?.data?.length > 0) {
                    setListaProdutos(response.data);
                    onCloseLoadingModal();

                }
                else
                    setListaProdutos(null);
                onCloseLoadingModal();

            })

        }
        else
            setListaProdutos(produtos)
        onCloseLoadingModal()

    }

    function AddToCart(productname: string, produtoimage: string, productprice: any, productId: string, quantity: number, corProduto: string) {
        // Adiciona um produto ao carrinho, mostrando um Drawer para o usuário e passando como parâmetros o nome, preço, imagem, ID...
        setErroProduto(0);
        setName(productname);
        setPrice(productprice);
        setImage(produtoimage);
        onOpen();
        api.post(`/user/cart/${productId}`, { productname, productprice, produtoimage, productId, quantity, corProduto }).then(() => {
            setErroProduto(false)
            mutateCarrinho();
        }).catch((error: any) => {
            if (error.response.status === 401) {

                message.warning("Por favor, faça login para adicionar esse produto ao carrinho!")
                navigate('/login')
                message.destroy()
            }
        })

    }

    const variants = {
        open: { rotate: 180 },
        closed: { rotate: 360, paddingTop: 1 },
    }

    return (
        <>
            <VStack w={'full'} pt={3}>
                <Text fontFamily={'Roboto, sans-serif'} fontSize={20} textColor={'black'}><b>Produtos em destaque!</b></Text>
            </VStack>
            {/* <SimpleGrid boxShadow={'0px 0px 9px 2px #58585838'} borderRadius={3} width={'100%'} columns={1} bgColor={'white'}> */}


            {/* <Carousel key={emdestaqueproducts} preventMovementUntilSwipeScrollTolerance emulateTouch interval={2000} autoFocus stopOnHover={false} width={'full'} dynamicHeight autoPlay={true} infiniteLoop={true} showStatus={false} showThumbs={false}> */}

            {/* {emdestaqueproducts?.map((products: Produto) => {
                        return (
                            <Center key={products?.nome} cursor={'pointer'} onClick={() => { seeProductDetails(products.id.toString()) }} textAlign={'initial'}>

                                <Image maxW={'120px'} align={'initial'} pr={8} fit={'contain'} h={'200px'} src={products.image} />
                                <VStack align={'initial'} justify={'initial'}>

                                    <Text textColor='black' fontFamily={'Inter'} fontSize={"14px"}>{products.nome}</Text>
                                    <Text fontSize={"17px"} textDecoration='line-through' textColor='gray'>{(products.preco + (10 / 100) * products.preco)?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</Text>
                                    <Text style={{ color: "#373737" }} fontSize={"20px"}>{(products.preco)?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</Text>
                                    <HStack pb={6}>
                                        {products.quantidade === 0 ?
                                            <Badge colorScheme={'red'}>Produto esgotado!</Badge>
                                            :
                                            <Badge colorScheme={'green'}>Disponível!</Badge>
                                        }
                                        <Text textColor={'black'} fontSize={13}>({products?.quantidade} disponíveis)</Text>
                                    </HStack>
                                </VStack>

                            </Center>

                        )
                    })
                    } */}


            {/* </Carousel> */}



            {/* </SimpleGrid> */}
            <HStack align={'center'} justify={'center'} w={'full'} pt={3}>
                <Text fontFamily={'Roboto, sans-serif'} fontSize={20} textColor={'black'}><b>Outros produtos</b></Text>


                <Button _active={{ colorScheme: '' }} variant={'outline'} colorScheme={'blue'} rightIcon={
                    <>
                        <motion.nav
                            animate={value === 1 ? "open" : "closed"}
                            variants={variants}
                        >

                            <CaretUpOutlined style={{ marginTop: 3 }} />
                        </motion.nav>

                    </>
                }

                    onClick={() => value === 1 ? setValue(0) : value === 0 ? setValue(1) : ''}>{value === 1 ? 'Menor preço' : 'Maior preço'}</Button>

            </HStack>
            <InputGroup w={'full'}>
                <Input onKeyDown={PesquisarComEnter} boxShadow='0px 0px 20px 4px #8d8d8d87;' bg={'white'} size={'md'} textColor={'black'} _focusVisible={{}} onChange={(e: any) => setPesquisa(e.target.value)} placeholder="Digite o nome ou código do produto que você procura" />
                <Button size={'md'} value={pesquisa} onClick={() => SearchProductByNameOrCode()} textColor={'black'} colorScheme={'blue'} ><SearchOutlined /></Button>
            </InputGroup>
            {listaProdutos === null ?
                <Box boxShadow='0px 0px 20px 4px #8d8d8d87;' borderRadius={6} p={3} bg={'#fff'} h={'auto'} w={'full'}>
                    <HStack p={3} spacing={10} justify={'center'} align={'center'}>
                        <svg className="ui-search-icon ui-search-icon--not-found ui-search-rescue__icon" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80"><g fill="none" fill-rule="evenodd"><path stroke="#484848" stroke-linecap="round" d="M37.891 50.705c4.584-1.85 8.61-5.256 11.216-9.957m2.764-9.071c.456-5.499-1.142-10.977-4.48-15.29a21.276 21.276 0 0 0-6.53-5.599c-5.845-3.24-12.566-3.444-18.403-1.139-4.65 1.836-8.739 5.265-11.375 10.022a22.488 22.488 0 0 0-2.832 10.308 22.102 22.102 0 0 0 3.058 11.86 21.288 21.288 0 0 0 8.02 7.79 21.275 21.275 0 0 0 8.427 2.594 21.853 21.853 0 0 0 10.135-1.518"></path><path stroke="#484848" stroke-linecap="round" d="M28.774 45.86a16.046 16.046 0 0 1-9.688-4.642m-3.693-5.7c-1.4-3.695-1.38-7.782.065-11.41a15.238 15.238 0 0 1 3.392-5.144c3.384-3.384 7.97-4.852 12.444-4.417 3.564.346 7.056 1.9 9.81 4.654 1.9 1.9 3.23 4.153 3.984 6.538a15.83 15.83 0 0 1 .236 8.768 15.246 15.246 0 0 1-3.984 6.947 15.237 15.237 0 0 1-5.289 3.449 15.651 15.651 0 0 1-7.277.956"></path><g fill="#484848" fill-rule="nonzero"><path d="M35.644 35.95l-12-12 .572-.572 12 12z"></path><path d="M36.215 23.95l-12 12-.57-.572 11.999-12z"></path></g><path stroke="#484848" stroke-linecap="square" d="M52.267 52.61l-6.646-6.647"></path><path fill="#FFDB15" d="M61.601 54.585l-2.823-2.824c-1.405-1.405-3.988-1.099-5.768.682-1.78 1.78-2.087 4.363-.682 5.768l9.599 9.599 8.89 8.89c1.403 1.404 3.987 1.098 5.767-.682 1.78-1.78 2.086-4.364.683-5.768"></path><path stroke="#484848" stroke-linecap="round" d="M52.095 58.273c-1.404-1.405-1.283-3.803.27-5.356s3.951-1.674 5.356-.27l9.6 9.6 8.89 8.89"></path></g></svg>
                        <VStack spacing={0} p={0} align={'initial'}>
                            <Text fontFamily={'Roboto, sans-serif'} fontSize={20}><b>Não há produtos que correspondem à sua busca.</b></Text>
                            <li style={{ paddingTop: 10, fontSize: 16, fontFamily: 'Inter, sans-serif' }}><b>Revise a ortografia</b> da palavra.</li>
                            <li style={{ fontSize: 16, fontFamily: 'Inter, sans-serif' }}>Utilize <b>palavras mais genéricas</b> ou menos palavras.</li>
                            <li style={{ fontSize: 16, fontFamily: 'Inter, sans-serif' }}><a onClick={() => navigate('/empresas/all')} style={{ color: 'blue' }}>Navegue pelas empresas</a> para encontrar outros produtos.</li>
                        </VStack>
                    </HStack>

                </Box>
                :
                <>
                    <SimpleGrid borderRadius={10} width={'100%'} bgColor={'#ffffff'}>

                        {listaProdutos.map((produtos: Produto) => {
                            return (

                                <Show key={produtos?.codigo5}>
                                    <HStack margin={1} cursor={'pointer'}>
                                        <motion.div

                                            className="box"
                                            initial={{ opacity: 0, scale: 0.5 }}
                                            animate={{ opacity: 1, scale: 1 }}
                                            transition={{
                                                duration: 0.5,
                                                delay: 0.1,
                                                ease: [0, 0.71, 0.2, 1.01]
                                            }}
                                        >

                                            <HStack margin={3} cursor={'pointer'}>


                                                <Image fallbackStrategy="beforeLoadOrError" fallbackSrc={'/fail_load_image.png'} alt="imagem-produtos" onClick={() => {
                                                    //aqui vai ser passado o id do produto, no caso quando estivermos pegando o id do bando de dados.
                                                    seeProductDetails((produtos.codigo5).toString());
                                                }} height={'40%'} maxW='80px' src={produtos.image} />

                                                <VStack paddingLeft={6} alignItems={"start"} w={"full"} display={"block"} align={'left'}>
                                                    <Text textColor='GrayText' fontFamily={'Inter'} fontSize={"md"}>{produtos.descricao5}</Text>
                                                    <HStack>
                                                        {produtos.salitoqtd5 === null ?
                                                            <Badge colorScheme={'red'}>Produto esgotado!</Badge>
                                                            :
                                                            <Badge colorScheme={'green'}>Disponível!</Badge>
                                                        }
                                                        <Text textColor={'black'} fontSize={15}>({produtos?.salitoqtd5 === null ? 0 : produtos?.salitoqtd5} disponíveis)</Text>
                                                    </HStack>
                                                    <Text fontSize={"md"} textDecoration='line-through' textColor='gray'>{(produtos.preco + (10 / 100) * produtos.preco)?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</Text>
                                                    <Text style={{ color: "#373737" }} fontSize={"24px"}>{(produtos.preco)?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</Text>

                                                    <Popover>
                                                        <PopoverTrigger>
                                                            <Button disabled={produtos.salitoqtd5 === null || produtos?.salitoqtd5 < 0 ? true : false} onClick={(e: any) => setQuantity(1)} w={"full"} _focus={{}} color={'#0aaaf5'} variant={"solid"} >Adicionar ao carrinho</Button>
                                                        </PopoverTrigger>
                                                        <PopoverContent >
                                                            <PopoverArrow />
                                                            <PopoverCloseButton />
                                                            <PopoverHeader fontSize={15} textAlign={'center'}><b>Selecione a quantidade de unidades</b></PopoverHeader>
                                                            <PopoverBody>
                                                                <VStack>

                                                                    <NumberInput w={'40%'} onInput={(e: any) => e.target.value = e.target.value.slice(0, 2)} onChange={(e: any) => setQuantity(e)} defaultValue={1} min={1}>
                                                                        <NumberInputField type={'number'} />
                                                                        <NumberInputStepper >
                                                                            <NumberIncrementStepper />
                                                                            <NumberDecrementStepper />
                                                                        </NumberInputStepper>
                                                                    </NumberInput>
                                                                    <Text fontFamily={'Inter, sans-serif'} textColor={produtos.quantidade === 0 ? 'red' : 'black'} fontSize={16}>Unidades: <b>({produtos.salitoqtd5} disponíveis)</b></Text>

                                                                    {produtos?.cor1?.length > 0 &&
                                                                        <>
                                                                            <Text fontSize={14} textAlign={'center'}><b>Escolha a cor do produto</b></Text>
                                                                            <Select onChange={(e: any) => setCorProduto(e.target.value)} >

                                                                                <option value={'Escolha a cor'}>Escolha a cor do produto</option>
                                                                                <option value={produtos?.cor1}>{produtos?.cor1}</option>
                                                                                <option value={produtos?.cor2}>{produtos?.cor2}</option>
                                                                                <option value={produtos?.cor3} >{produtos?.cor3}</option>
                                                                            </Select>
                                                                        </>

                                                                    }
                                                                </VStack>
                                                                <Button disabled={produtos.quantidade === 0 ? true : false} w={"full"} top={1} _focus={{}} colorScheme={"blue"} variant={"ghost"} onClick={() => AddToCart(produtos.descricao5, produtos.image, produtos.preco, produtos.codigo5, quantity, corProduto)}>Adicionar ao carrinho</Button>

                                                            </PopoverBody>
                                                        </PopoverContent>
                                                    </Popover>

                                                </VStack>


                                            </HStack>
                                        </motion.div>
                                    </HStack>

                                    <Divider bgColor='gray.400' outlineColor={'gray.400'} />


                                </Show>
                            )
                        })
                        }

                    </SimpleGrid>
                </>
            }
            {listaProdutos !== null &&
                <>
                    <HStack spacing={20}>

                        <Button top={2} disabled={pagina_anterior === 0 ? true : false} onClick={() => PreviousPage()}><LeftOutlined /></Button>
                        <Button top={2} onClick={() => NextPage()}><RightOutlined /></Button>

                    </HStack>
                    <Button top={12} onClick={onOpenModalQrCode} _focus={{}} textColor={'black'} colorScheme={"green"} variant={"outline"} ><QrcodeOutlined style={{ fontSize: 30 }} /></Button>
                </>
            }

            <Drawer blockScrollOnMount={false} lockFocusAcrossFrames id="AddedProduct" placement="top" onClose={onClose} isOpen={isOpen}>
                <DrawerContent borderRadius={5} boxShadow={'0px 0px 15px 3px #0000008b;'} p={1} bgColor={'white'}>
                    <DrawerBody>
                        <HStack justify={'center'} align={'center'} px={3} spacing={5}>
                            <Avatar size={'md'} src={image}></Avatar>

                            <VStack align={'center'}>
                                <Text fontFamily={'Inter, sans-serif'} textColor={erroproduto === 0 ? 'black' : '' || erroproduto === false ? '#0bbd1d' : '#ff680f'} fontSize={12}><b>{erroproduto === 0 ? 'Carregando...' : '' || erroproduto === false ? 'Adicionado ao carrinho!' : 'Produto já adicionado ao carrinho!'}</b></Text>
                                <Text fontSize={10} fontFamily={'Inter, sans-serif'}><b>{name}</b></Text>
                            </VStack>
                            <Divider orientation="vertical" borderColor={'gray.300'} h={'80px'} />
                            <VStack>
                                <Text fontFamily={'Roboto, sans-serif'} fontSize={18}>{price?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</Text>
                                {userAvatarGoogle !== null || nomeuser !== null ?
                                    <Button borderRadius={5} fontSize={15} p={3} height={'35px'} colorScheme={'blue'} onClick={() => { navigate('/carrinho') }}>Ver carrinho</Button>
                                    :
                                    <Button borderRadius={5} fontSize={15} p={3} height={'35px'} colorScheme={'blue'} onClick={() => { navigate('/login') }}>Ver carrinho</Button>
                                }
                            </VStack>

                            <DrawerCloseButton _focus={{}} fontSize={12} />
                        </HStack>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>

            <Modal isOpen={isOpenModalQrCode} onClose={onCloseModalQrCode}>
                <ModalOverlay bgPosition={'bottom'} bgRepeat={'no-repeat'} bgSize={'380px'} bgImage={'/logosuc.png'} bg={'whiteAlpha.900'} />

                <ModalContent>
                    <ModalHeader>QR Code Empresa</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <VStack>
                            <QRCodeComponent />
                            <Text fontSize={17} fontFamily={'Roboto, sans-serif'}>Envie o QRCode acima para outras pessoas visualizarem os produtos dessa empresa</Text>
                        </VStack>

                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={onCloseModalQrCode}>
                            Fechar
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <Modal closeOnOverlayClick={false} isOpen={isOpenLoadingModal} onClose={onCloseLoadingModal}>
                <ModalOverlay boxShadow={0} h={'full'} bg={'blackAlpha.500'} />
                <ModalContent boxShadow={0} p={0} top={'40%'} bg={'transparent'}>

                    <ModalBody h={'100%'} bg={'transparent'}>
                        <VStack h={'full'} bg={'transparent'}>
                            <Spinner
                                thickness='4px'
                                speed='0.65s'
                                emptyColor='gray.200'
                                color='black'
                                size='xl'
                            />
                            <Text fontSize={18} textColor={'black'} fontFamily={'Inter'}>Carregando produtos...</Text>
                        </VStack>

                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}