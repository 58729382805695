import { ChevronDownIcon } from "@chakra-ui/icons";
import { Avatar, Box, Button, Center, HStack, Image, Menu, MenuButton, MenuItem, MenuList, Spacer, Text, VStack } from "@chakra-ui/react";
import { Badge, message, notification, Popover } from "antd";
import { memo, useEffect, useRef, useState } from "react";
import { AiOutlineUser } from 'react-icons/ai';
import { FiShoppingCart } from "react-icons/fi";
import { IoIosNotificationsOutline } from 'react-icons/io';
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { useOnClickOutside } from 'usehooks-ts';
import { GetCarrinho } from "../hooks/GetCarrinho";
import { GetChatAnswers } from "../hooks/GetChatAnswers";
import { GetChatMessage } from "../hooks/GetChatMessages";
import { GetMe } from "../hooks/GetMe";
import { GetPerguntasProdutos } from "../hooks/GetPerguntasProdutos";
import { GetRespostasProdutos } from "../hooks/GetRespostasProdutos";
import { ChatRespostas, Mensagens, Respostas } from "../types/types";
import { api } from "../util/api";
import { BuscaEmpresas } from "./BuscaEmpresa";
import { LoadingCircle } from "./loading/LoadingCircle";
import { textState } from "./ParamIndexFornecedorPage";



export const NavBar = memo(() => {

	function NavigateFornecedor(index: number) {
		setText(index)
		navigate('/fornecedor')
	}

	const [text, setText]: any = useRecoilState(textState);
	const { categoria }: any = useParams()
	const firstLetterUpperCase = categoria?.charAt(0)?.toUpperCase() + categoria?.slice(1);
	const { me, mutateMe } = GetMe()

	const { allPerguntas, mutatePerguntas } = GetPerguntasProdutos()

	const { allMessages, mutateMessages } = GetChatMessage()
	const { allChatAnswers, mutateChatAnswers } = GetChatAnswers()
	const { dataCarrinho, mutateCarrinho } = GetCarrinho()

	const { allRespostas, mutateRespostas } = GetRespostasProdutos()
	const navigate = useNavigate();

	// Função criada para receber o nome e o avatar do usuário diretamente do LocalStorage e setta o nome ao lado do ícone


	const [setBarraPesquisa] = useState<any>(false)
	const [quantCart, setQuantCart] = useState<any>()
	const [quantRespostas, setQuantRespostas]: any = useState()
	const toolbarHeigth = "70px";
	const textMenuSize = 15;
	const [isLoading, setIsLoading]: any = useState();


	function VisualizarNotificacao(id_produto: number, id_empresa: number, id_mensagem: number) {

		// Setta o parâmetro visualizada = 1 para a mensagem correspondente ao id recebido da mensagem

		navigate(`/compras/${id_produto}/${id_empresa}`)

		api.put('visualizarnotificacao', { id_mensagem }).then(() => {
			mutateRespostas()
		})

	}

	function VerPerguntaProduto(id_produto: number, id_empresa: number) {
		navigate(`/compras/${id_produto}/${id_empresa}`)
		mutateRespostas()


	}

	function MarcarComoLida(idMensagem: number) {

		// Setta o parâmetro lida = 1 para a mensagem recebida correspondente ao ID da mensagem no chat 

		setIsLoading(true)
		api.put('chat/marcarcomolida', { idMensagem }).then(() => {
			mutateMessages()
			mutateChatAnswers()
			message.destroy()
			notification.destroy()
			notification.open({
				duration: 2.5,
				placement: 'top',
				message: <Text textColor={'white'} fontFamily={'Roboto, sans-serif'} fontSize={16}><b>Sucesso!</b></Text>,
				description:
					<Text textColor={'white'} fontSize={16} fontFamily={'Inter, sans-serif'}><b>Mensagem marcada como lida!</b></Text>,
				style: { background: '#4aaf53', borderRadius: 6, boxShadow: '0px 0px 20px 4px #6868688a' },
			});
			setIsLoading(false)
		}).catch((e: any) => {
			message.destroy()
			message.error("Não foi possível marcar essa mensagem como lida!")
		})
		setIsLoading(false)
	}



	useEffect(() => {
		setQuantRespostas(allRespostas)
		mutateRespostas()
		mutateMessages()
		mutatePerguntas()
		mutateMe()
		setQuantCart(dataCarrinho?.length);
		mutateCarrinho()

	}, [dataCarrinho, mutateCarrinho, allRespostas, mutateRespostas, mutateChatAnswers, allChatAnswers, allPerguntas, mutatePerguntas, mutateMe, mutateMessages])


	const ref = useRef(null)

	const handleClickOutside = () => {
		setBarraPesquisa(false)
	}
	useOnClickOutside(ref, handleClickOutside)

	return (

		<VStack w="full" h={toolbarHeigth} bg={"#3a5fcb"}>
			<HStack w="full" h={toolbarHeigth} >
				<HStack spacing={5} alignItems={"start"} h={toolbarHeigth} >
					<VStack w={"170px"} mr={2} h={"100px"}>
						<Center w={"full"} h={toolbarHeigth}>
							<Image onClick={() => { navigate('/empresas/all') }} cursor={"pointer"} width={'370px'} src='/logo branca.svg'></Image>
						</Center>
					</VStack>
					<VStack mr={2} h={"100px"}>
						<Center w={"full"} h={toolbarHeigth}>
							<Text onClick={() => { navigate('/empresas/all') }} fontSize={textMenuSize} cursor={"pointer"} color={"white"} fontWeight={"semibold"}>Home</Text>
						</Center>
					</VStack>
					<VStack mr={2} h={"100px"}>
						<Center w={"full"} h={toolbarHeigth}>
							<HStack cursor={"pointer"}>
								<Menu>
									<MenuButton fontSize={15} p={0} _active={{ bg: 'transparent' }} _focus={{ bg: 'transparent' }} _hover={{ bg: 'transparent' }} textColor={'white'} bgColor={'transparent'} as={Button} rightIcon={<ChevronDownIcon fontSize={15} />}>
										Categorias
										<Box p={1} borderRadius={5}>
											<VStack>
												<Text fontFamily={'Roboto, sans-serif'} textColor={'white'} fontSize={16}>{categoria === 'all' || categoria === undefined ? 'Todas' : firstLetterUpperCase}</Text>
											</VStack>
										</Box>
									</MenuButton>
									<MenuList>
										<MenuItem onClick={() => { navigate('/empresas/all') }} minH='40px'>
											<Text>TODAS</Text>
										</MenuItem>
										<MenuItem onClick={() => { navigate('/empresas/restaurantes') }} minH='40px'>
											<Text>RESTAURANTES</Text>
										</MenuItem>
										<MenuItem onClick={() => { navigate('/empresas/eletrodomésticos') }} minH='40px'>
											<Text>ELETRODOMÉSTICOS</Text>
										</MenuItem>
										<MenuItem onClick={() => { navigate('/empresas/agrícola') }} minH='40px'>
											<Text>AGRÍCOLA</Text>
										</MenuItem>
										<MenuItem onClick={() => { navigate('/empresas/roupas') }} minH='40px'>
											<Text>ROUPAS</Text>
										</MenuItem>
										<MenuItem onClick={() => { navigate('/empresas/acessórios') }} minH='40px'>
											<Text>ACESSÓRIOS PARA VEÍCULOS</Text>
										</MenuItem>

									</MenuList>
								</Menu>

							</HStack>
						</Center>
					</VStack>
					<VStack mr={2} h={"100px"}>
						<Center w={"full"} h={toolbarHeigth}>
							<Text onClick={() => { navigate('/empresas/all') }} fontSize={textMenuSize} cursor={"pointer"} color={"white"} fontWeight={"semibold"}>Empresas</Text>
						</Center>
					</VStack>
					<VStack mr={2} h={"100px"}>
						<Center w={"full"} h={toolbarHeigth}>
							{me?.statusCode !== 401 ?
								<Text onClick={() => { navigate('/pedidos') }} fontSize={textMenuSize} cursor={"pointer"} color={"white"} fontWeight={"semibold"}>Pedidos</Text>
								:
								<Text onClick={() => { navigate('/login') }} fontSize={textMenuSize} cursor={"pointer"} color={"white"} fontWeight={"semibold"}>Pedidos</Text>
							}
						</Center>
					</VStack>

					<VStack h={"100px"}>
						<Center w={"full"} h={toolbarHeigth}>
							<HStack pr={3} cursor={"pointer"}>
								<Menu>

									<MenuList>
										<MenuItem minH='40px'>
											<Text>Paracatu</Text>
										</MenuItem>
										<MenuItem minH='40px'>
											<Text>Unaí</Text>
										</MenuItem>
										<MenuItem minH='40px'>
											<Text>Araxá</Text>
										</MenuItem>
										<MenuItem minH='40px'>
											<Text>Brasilândia</Text>
										</MenuItem>
										<MenuItem minH='40px'>
											<Text>João Pinheiro</Text>
										</MenuItem>
										<MenuItem minH='40px'>
											<Text>Vazante</Text>
										</MenuItem>

									</MenuList>
								</Menu>
								{me?.fornecedor === 1 ?
									<Text p={2} onClick={() => NavigateFornecedor(0)} fontSize={textMenuSize} cursor={"pointer"} color={"white"} fontWeight={"semibold"}>Portal Fornecedor</Text>
									:
									<></>
								}
							</HStack>
						</Center>
					</VStack>
				</HStack>

				<Spacer />

				<Spacer minW={'190px'} maxW={'250px'} w={'full'} pt={4}>
					<BuscaEmpresas />
				</Spacer>
				<HStack pl={3} spacing={5} alignItems={"start"} h={toolbarHeigth} >
					<VStack ml={3} h={"100px"}>
						<Center w={"full"} h={toolbarHeigth}>
							<Text onClick={() => { navigate('/login') }} fontSize={textMenuSize} cursor={"pointer"} color={"white"} fontWeight={"semibold"}>Entre</Text>
						</Center>
					</VStack>
					<VStack h={"100px"}>
						<Center w={"full"} h={toolbarHeigth}>
							<Text onClick={() => { navigate('/cadastro') }} whiteSpace={'nowrap'} fontSize={textMenuSize} cursor={"pointer"} color={"white"} fontWeight={"semibold"}>Crie sua conta</Text>
						</Center>
					</VStack>
					<VStack position={'relative'} h={"100px"}>

					</VStack>
					<VStack px={1} h={"100px"}>
						<Center w={"full"} h={toolbarHeigth}>

							<Badge size="small" color="green" count={quantCart}>
								{me?.statusCode !== 401 ?
									<FiShoppingCart onClick={() => { navigate('/carrinho') }} color="white" cursor={"pointer"} size={"24px"} />
									:
									<FiShoppingCart onClick={() => { navigate('/login') }} color="white" cursor={"pointer"} size={"24px"} />
								}
							</Badge>

						</Center>
					</VStack>

					<VStack px={1} h={"100px"}>
						<Center w={"full"} h={toolbarHeigth}>
							{allMessages?.length > 0 || allChatAnswers?.length > 0 || allPerguntas?.length > 0 || quantRespostas?.length > 0 ?
								<Popover placement="rightBottom" content={
									<>
										<Text textAlign={'center'} py={1} fontSize={16}>{quantRespostas?.length > 0 ? <b>Sua pergunta foi respondida nos seguintes produtos:</b> : ''}</Text>
										{quantRespostas?.length > 0 && quantRespostas !== undefined ?
											quantRespostas?.map((allrespostas: Respostas) => (
												<VStack key={allrespostas?.nome} align={'initial'} >
													<HStack transition={'all .1s ease-in-out'} _hover={{ bg: '#fafafa' }} p={2} cursor={'pointer'} onClick={() => VisualizarNotificacao(allrespostas?.id_produto, allrespostas?.empresa_id, allrespostas?.id)}>
														<Image maxW={'50px'} w={'20%'} src={allrespostas?.image}></Image>
														<Text w={'full'}>{allrespostas?.nome}</Text>
													</HStack>

												</VStack>
											))
											:
											<></>
										}

										<Text textColor={'black'} fontFamily={'Inter'} textAlign={'center'} py={1} fontSize={16}>{allPerguntas?.length > 0 ? <>Você possui perguntas não respondidas nos seguintes produtos:</> : ''}</Text>
										{allPerguntas?.length > 0 && allPerguntas !== undefined ?
											allPerguntas?.map((mensagens: Mensagens) => (
												<VStack key={mensagens?.id_produto} align={'initial'} >
													<HStack transition={'all .1s ease-in-out'} _hover={{ bg: '#fafafa' }} p={2} cursor={'pointer'} onClick={() => VerPerguntaProduto(mensagens?.id_produto, mensagens?.empresa_id)}>
														<Image maxW={'50px'} w={'20%'} src={mensagens?.image}></Image>
														<Text w={'full'}>{mensagens?.descricao5}</Text>
													</HStack>

												</VStack>
											))
											:
											<></>
										}
										{isLoading ?
											<LoadingCircle />
											:
											<></>
										}
										{allMessages?.length > 0 && allMessages !== undefined ?
											<VStack _hover={{ backgroundColor: '#fafafa' }} onClick={() => NavigateFornecedor(1)} cursor={'pointer'} p={5}>
												<Text fontSize={15} textColor={'black'} textAlign={'center'}>Você possui novas mensagens no chat!</Text>
											</VStack>
											:
											<></>
										}
										{allChatAnswers?.length > 0 ?
											<VStack>
												<Text>Você possui novas respostas!</Text>
											</VStack>
											:
											<></>
										}
										{allChatAnswers?.length > 0 && allChatAnswers !== undefined ?
											allChatAnswers.map((respostas: ChatRespostas) => {
												return (
													<VStack key={respostas.id_mensagem} py={3}>

														<Button textColor={'blue.500'} variant={'link'} onClick={() => navigate(`/${respostas?.id_empresa}/lista-produtos`)} fontSize={13} fontFamily={'Inter, sans-serif'}>{respostas?.title}</Button>
														<Text fontSize={12} fontFamily={'Inter, sans-serif'}>[{respostas?.mensagem}]</Text>
														<Text fontSize={13} textColor={'black'} fontFamily={'Inter, sans-serif'}>{respostas?.resposta}</Text>
														<Button size={'sm'} onClick={() => MarcarComoLida(respostas?.id_mensagem)}>Marcar como lida!</Button>
													</VStack>
												)
											})
											:
											<></>
										}
									</>
								} title={quantRespostas?.length > 0 || allMessages?.length > 0 ? `Você tem ${quantRespostas?.length + allMessages?.length + allChatAnswers?.length + allPerguntas?.length} notificações!` : `Você tem ${quantRespostas?.length + allMessages?.length + allChatAnswers?.length + allPerguntas?.length} notificação!`}>
									<Badge size="small" color={"green"} count={quantRespostas?.length + allMessages?.length + allChatAnswers?.length + allPerguntas?.length}>
										<IoIosNotificationsOutline color="white" cursor={"pointer"} size={"24px"} />
									</Badge>
								</Popover>

								:
								<Popover placement="bottomRight" autoAdjustOverflow content={''} title={"Você não possui notificações!"}>
									<IoIosNotificationsOutline color="white" cursor={"pointer"} size={"24px"} />
								</Popover>
							}
						</Center>
					</VStack>
					<VStack px={1} h={"100px"}>
						<Center w={"full"} h={toolbarHeigth}>
							{me?.statusCode !== 401 ?
								<Avatar onClick={() => { navigate('/perfil') }} cursor={"pointer"} borderColor={"white"} borderWidth={2} size={'sm'} src={me?.avatar}></Avatar>
								:
								<AiOutlineUser onClick={() => { navigate('/login') }} color="white" cursor={"pointer"} size={"24px"} />
							}

						</Center>
					</VStack>

				</HStack>
			</HStack>




		</VStack>
	);
})