import { useMediaQuery } from "@chakra-ui/react"
import { Companies } from "../Companies"
import { ListCompanyMobile } from "../Mobile/ListCompanyMobile"

export const CompaniesResponsivo = () => {
    const [MenorThan1260] = useMediaQuery('(min-width: 1136px)')
    return (

        <>
            {MenorThan1260 ? <Companies /> : <ListCompanyMobile />}
        </>
    )
}
