import { SimpleGrid, Skeleton, VStack } from "@chakra-ui/react";
export const SkeletonLoadingDetail = () => {
	return (

		<VStack w={{ "sm": "100%", "lg": "70%" }}>
			<SimpleGrid columns={{ "sm": 1, md: 2 }} spacing={2} w="full" bgColor={'#fafafa'} padding={3} borderRadius={5} boxShadow='0.3px 0.3px 3px rgb(158, 158, 158)'>
				
				<VStack w={"full"} >
					<Skeleton borderRadius={8} h={"400px"} w={"335px"} />
				</VStack>
				
				<VStack pl={5} pr={5} alignItems={"intial"} w={"full"} >
					<Skeleton h={"30px"} w={"full"} />
					<Skeleton h={"30px"} w={"50%"} />
					<Skeleton h={"30px"} w={"40%"} />
					<Skeleton h={"30px"} w={"50%"} />
					<Skeleton h={"40px"} w={"100%"} />
					<Skeleton h={"30px"} w={"25%"} />
					<Skeleton h={"40px"} w={"100%"} />
					<Skeleton h={"30px"} w={"20%"} />
				</VStack>
				
			</SimpleGrid >
		</VStack>


	);
}