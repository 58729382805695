import { DeleteOutlined, DownOutlined, EditOutlined, EnterOutlined, SendOutlined, ShoppingCartOutlined, UserOutlined } from "@ant-design/icons"
import { ChevronDownIcon } from "@chakra-ui/icons"
import { Avatar, Badge, Button, Divider, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, HStack, Image, Input, InputGroup, Menu, MenuButton, MenuItem, MenuList, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalOverlay, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger, Portal, Show, SimpleGrid, Spinner, Text, Tooltip, useDisclosure, VStack } from "@chakra-ui/react"
import '@fontsource/lato'
import '@fontsource/quando'
import '@fontsource/roboto'
import { Comment, message } from "antd"
import { AxiosError } from "axios"
import { motion } from "framer-motion"
import React, { memo, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import useSWR, { useSWRConfig } from "swr"
import { GetCarrinho } from "../hooks/GetCarrinho"
import { GetPerguntasProdutos } from "../hooks/GetPerguntasProdutos"
import '../Styles/Globalcss.css'
import { Produto } from "../types/types"
import { api, apiEndPoint } from "../util/api"
import { OpinioesProdutos } from "./OpinioesProdutos"

export type ProductType = {
    product: Produto | any
}

export const ProductExpanded = memo(({ product }: ProductType) => {

    const { empresaId } = useParams();
    const route: string = apiEndPoint;
    const navigate = useNavigate();
    const { mutate } = useSWRConfig()
    const { mutateCarrinho, dataCarrinho } = GetCarrinho()
    const { id }: any = useParams()
    const fetcher = (url: any) => fetch(route + `/mensagens/${id}`).then(r => r.json())
    const { data } = useSWR(route + `/mensagens/${id}`, fetcher, { refreshInterval: 20000 })

    const { isOpen: isOpenDrawerProdutos, onOpen: onOpenDrawerProdutos, onClose: onCloseDrawerProdutos } = useDisclosure();
    const { isOpen: isOpenModalImagem, onOpen: onOpenModalImagem, onClose: onCloseModalImagem } = useDisclosure();

    const [empresaFornecedor, setEmpresaFornecedor]: any = useState();
    const [fornecedor, setFornecedor]: any = useState();
    const [name, setName] = useState('');
    const [price, setPrice]: any = useState('');
    const [image, setImage] = useState('');
    const [quantity, setQuantity]: any = useState(1);
    const [quantidademaior, setQuantidadeMaior]: any = useState();
    const [corProduct, setCorProduct]: any = useState();
    const [tamanhoProduto, setTamanhoProduto]: any = useState();
    const { mutatePerguntas } = GetPerguntasProdutos();
    const [erroproduto, setErroProduto] = useState<any>(0);


    const [inputUnidades, setInputUnidades]: any = useState(false);

    const [mensagem, setMensagem]: any = useState();
    const [resposta, setResposta]: any = useState();

    const [isloading, setIsLoading]: any = useState();

    const [showallResults, setShowAllResults]: any = useState(0);

    const initRef = React.useRef(null);

    const divisao = product?.[0]?.preco / 12

    // Envia um request para a api e caso o retorno for igual a 1 ele setta o usuário logado como fornecedor
    useEffect(() => {
        api.get('auth/verificarfornecedor').then(async (response: any) => {
            if (response.data.fornecedor === 1) {
                setEmpresaFornecedor(response.data.empresa_id)

                setFornecedor(true)
            }
            else
                setFornecedor(false)
        })

    }, [])

    const FilterProduct = dataCarrinho?.length > 0 ? dataCarrinho?.filter((produtos: any) => produtos.id_produto === id) : [];

    function AlterarQuantidade(value: any) {
        if (Number(value) > product?.[0]?.salitoqtd5 || Number(value) + FilterProduct?.[0]?.quantidade_produto > product?.[0]?.salitoqtd5) {
            message.destroy();
            message.error("A quantidade selecionada não está disponível no estoque!")
        }
        else
            setQuantity(value)
        setInputUnidades(false)

    }

    function addToCart(productname: string, produtoimage: string, productprice: any, productId: number, quantity: number, corProduto: string) {
        if (quantity === undefined) {
            message.destroy();
            message.error("Por favor, selecione uma quantidade para adicionar o produto ao carrinho!");
            return 1;
        }
        if (Number(quantity) + FilterProduct?.[0]?.quantidade_produto > product?.[0]?.salitoqtd5) {
            message.destroy();
            message.error("Não é possível adicionar mais unidades desse produto no carrinho");
            return 1;
        }
        else
            setErroProduto(0);
        setName(productname);
        setPrice(productprice);
        setImage(produtoimage);
        setCorProduct(corProduto)
        onOpenDrawerProdutos();
        api.post(`/user/cart/${productId}`, { productname, productprice, produtoimage, productId, quantity, corProduto, tamanhoProduto }).then(() => {
            setErroProduto(false)
            mutateCarrinho();
        }).catch((error: any) => {
            if (error.response.status === 401) {
                message.warning("Por favor, faça login para adicionar esse produto ao carrinho!", 2)
                navigate('/login')

            }
        })
    }

    const EnviarMensagemComEnter = (event: any, idProduto: number) => {
        if (event.key === 'Enter') {
            EnviarMensagem(idProduto, mensagem)
        }
    }
    const EnviarRespostaComEnter = (event: any, idProduto: number) => {
        if (event.key === 'Enter') {
            EnviarResposta(idProduto, resposta)
        }
    }

    function EnviarMensagem(idProduto: number, mensagem: string) {


        if (mensagem.length < 10) {
            message.destroy();
            message.error("Mensagem muito curta, insira uma mensagem maior!");
            return 1;
        }
        else
            setIsLoading(true)
        api.interceptors.request.use(
            (config: any) => {

                const token = localStorage.getItem('@App:Access_Token')

                if (token) {

                    config.headers!['Authorization'] = 'Bearer ' + token
                }

                return config
            },
            (error: AxiosError) => {
                Promise.reject(error)
            }
        )
        api.post('/enviarmensagem', { idProduto, mensagem }).then(async () => {

            message.destroy();
            message.success("Mensagem enviada com sucesso!", 3)
            setMensagem('')
            mutate(route + `/mensagens/${id}`)
            setIsLoading(false);

        }).catch((error: any) => {
            if (error.response.status === 401 || error.response.status === 400) {
                message.destroy();
                message.error("Por favor, faça login para enviar uma mensagem!")
                setIsLoading(false);
            }
            if (error.response.status === 500) {
                message.destroy();
                message.error("Essa mensagem já foi enviada ao fornecedor!")
                setIsLoading(false);
            }
        })
    }

    function EnviarResposta(idMensagem: number, resposta: string) {
        if (resposta.length < 10) {
            message.destroy();
            message.error("Mensagem muito curta, insira uma mensagem maior!");
            setIsLoading(false)
            return 1;
        }

        else

            setIsLoading(true)
        api.post('/enviarresposta', { idMensagem, resposta }).then(async () => {
            message.destroy();
            message.success("Resposta enviada com sucesso!", 3)
            setIsLoading(false)
            setResposta('')
            mutatePerguntas()
            mutate(route + `/mensagens/${id}`)


        }).catch((error: any) => {
            if (error.response.status === 401) {
                message.destroy();
                message.error("Essa resposta já foi enviada ao fornecedor!")
                setIsLoading(false);
            }
        })
    }

    function DeletarResposta(idMensagem: number) {
        api.post('/deletarresposta', { idMensagem }).then(async () => {
            message.destroy();
            message.success("Resposta deletada com sucesso!", 3)
            setIsLoading(false)
            mutate(route + `/mensagens/${id}`)

        }).catch((error: any) => {
            if (error.response.status === 401) {
                message.destroy();
                message.error("Não foi possível deletar essa resposta!")
                setIsLoading(false);
            }
        })
    }

    return (
        <VStack w={{ "sm": "100%", "lg": "60%" }}>
            <motion.div
                className="box"
                initial={{ opacity: -1 }}
                animate={{ opacity: 1 }}
                transition={{
                    duration: 1.5,
                    delay: 0.1,
                    ease: [0, 0.71, 0.2, 1.01]
                }}
            >
                <SimpleGrid columns={{ "sm": 1, md: 2 }} spacing={2} mb={2} w="full" bgColor={'#fdfdfd'} padding={3} borderRadius={8} boxShadow='0.1px 0.3px 1px rgb(158, 158, 158)'>

                    <VStack maxW={'full'} h={'auto'}>
                        <Image fallbackSrc={'/fail_load_image.png'} cursor={'pointer'} onClick={() => onOpenModalImagem()} h={"360px"} w={"325px"} src={product?.[0]?.image} />
                        <Text p={3} textColor={'black'} fontSize={16} fontFamily={'Roboto, sans-serif'}>Dica: Clique em cima da imagem para aplicar efeito de zoom!</Text>

                    </VStack>

                    <VStack align={'initial'}>

                        <Text fontFamily={'Lato, sans-serif'} fontSize={18}><b>{product?.[0]?.descricao5}</b></Text>
                        <Text fontFamily={'Roboto, sans-serif'} fontSize={20}><b>{product?.[0]?.preco?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</b></Text>
                        <Text fontFamily={'Roboto, sans-serif'} fontSize={16}><b>em 12x {divisao.toFixed(2)} </b></Text>
                        <Text fontFamily={'Quando, sans-serif'} fontSize={16}><b>Marca: {product?.[0]?.marca5}</b></Text>

                        {/* Verifica se o item possui fração */}
                        {product?.[0]?.fracao28 === 'S' ?

                            <>
                                <HStack align={'center'}>
                                    <Input w={'70%'} onChange={(e: any) => AlterarQuantidade(e.target.value)} type={'number'} placeholder="Digite a quantidade que deseja"></Input>
                                    <Text textColor={'black'}><b>({product?.[0]?.un5})</b></Text>
                                </HStack>

                                <Text fontFamily={'Inter'} textColor={'black'} fontSize={15}>{FilterProduct?.[0]?.quantidade_produto > 0 && <b>({FilterProduct?.[0]?.quantidade_produto} no carrinho)</b>}</Text>
                                {FilterProduct?.[0]?.quantidade_produto > 0 &&
                                    <Button fontSize={15} colorScheme={'blue'} p={0} onClick={() => navigate('/carrinho')} variant={'link'}>Ver carrinho <ShoppingCartOutlined style={{ padding: 3, fontSize: 16 }} /></Button>
                                }
                            </>
                            :
                            <HStack>
                                <Menu>
                                    <MenuButton p={0} w={'45%'} minW={'170px'} textAlign={'left'} _hover={{}} bg={'transparent'} as={Button} rightIcon={<ChevronDownIcon fontSize={20} color={'blue'} />}>

                                        Quantidade: <b>{quantity}</b> {product?.[0]?.un5}
                                    </MenuButton>
                                    <MenuList fontSize={16}>
                                        <MenuItem _focus={{ color: 'blue' }} onClick={() => AlterarQuantidade(1)}>1 {product?.[0]?.un5}</MenuItem>
                                        <MenuItem _active={{ color: 'blue' }} onClick={() => AlterarQuantidade(2)}>2 {product?.[0]?.un5}s</MenuItem>
                                        <MenuItem _active={{ color: 'blue' }} onClick={() => AlterarQuantidade(3)}>3 {product?.[0]?.un5}s</MenuItem>
                                        <MenuItem _active={{ color: 'blue' }} onClick={() => AlterarQuantidade(4)}>4 {product?.[0]?.un5}s</MenuItem>
                                        <MenuItem _active={{ color: 'blue' }} onClick={() => AlterarQuantidade(5)}>5 {product?.[0]?.un5}s</MenuItem>
                                        <MenuItem _active={{ color: 'blue' }} onClick={() => AlterarQuantidade(6)}>6 {product?.[0]?.un5}s</MenuItem>
                                        {inputUnidades === false ?
                                            <MenuItem closeOnSelect={false} _active={{ color: 'blue' }} onClick={() => setInputUnidades(true)}>Mais de 6 {product?.[0]?.un5}</MenuItem>
                                            :
                                            <VStack align={'initial'} p={3}>
                                                <Text>Quantidade:</Text>
                                                <Input type={'number'} maxLength={2} onChange={(e: any) => setQuantidadeMaior(e.target.value)} autoFocus />

                                                <Button w={'40%'} onClick={() => AlterarQuantidade(quantidademaior)}>Aplicar</Button>
                                            </VStack>

                                        }
                                    </MenuList>
                                </Menu>
                                <Text fontFamily={'Inter'} textColor={'black'} fontSize={15}>{FilterProduct?.[0]?.quantidade_produto > 0 && <b>({FilterProduct?.[0]?.quantidade_produto} no carrinho)</b>}</Text>
                                {FilterProduct?.[0]?.quantidade_produto > 0 &&
                                    <Button fontSize={15} colorScheme={'blue'} p={0} onClick={() => navigate('/carrinho')} variant={'link'}>Ver carrinho <ShoppingCartOutlined style={{ padding: 3, fontSize: 16 }} /></Button>
                                }
                            </HStack>
                        }
                        <Text align={'center'} fontSize={17} textColor={product?.[0]?.salitoqtd5 === null || product?.[0]?.salitoqtd5 < 0 ? 'red' : 'black'} fontFamily={'Roboto, sans-serif'}>({product?.[0]?.salitoqtd5 === null ? '0' : product?.[0]?.salitoqtd5} disponíveis)</Text>
                        {product?.[0]?.cor6?.length > 0 &&
                            <>
                                <Menu >
                                    <MenuButton disabled={product?.[0]?.quantidade === 0 ? true : false} value={corProduct} _hover={{}} bg={'telegram.100'} as={Button} rightIcon={<ChevronDownIcon />}>
                                        {corProduct !== undefined ? corProduct : 'Escolha a cor do produto'}
                                    </MenuButton>
                                    <MenuList fontSize={16}>
                                        <MenuItem >Escolha a cor do produto</MenuItem>
                                        <MenuItem onClick={() => setCorProduct(product?.[0]?.cor6)}>{product?.[0]?.cor6}</MenuItem>
                                    </MenuList>
                                </Menu>
                                {corProduct === undefined &&
                                    <Badge textAlign={'center'} colorScheme={'yellow'} p={3} fontFamily={'Roboto, sans-serif'} fontSize={12}><b>Para adicionar esse item ao carrinho, escolha a cor do produto!</b></Badge>
                                }
                            </>

                        }
                        {product?.[0]?.altura6 > 0 &&
                            <>
                                <Menu>
                                    <MenuButton value={tamanhoProduto} placeholder="da" _hover={{}} bg={'telegram.100'} as={Button} rightIcon={<ChevronDownIcon />}>
                                        {tamanhoProduto !== undefined ? tamanhoProduto : 'Escolha o tamanho do produto'}
                                    </MenuButton>
                                    <MenuList fontSize={16}>
                                        <MenuItem >Escolha a altura do produto</MenuItem>
                                        <MenuItem onClick={() => setTamanhoProduto(product?.[0]?.altura6)} >{product?.[0]?.altura6}</MenuItem>
                                    </MenuList>
                                </Menu>
                                {tamanhoProduto === undefined &&
                                    <Badge textAlign={'center'} colorScheme={'yellow'} p={3} fontFamily={'Roboto, sans-serif'} fontSize={12}><b>Para adicionar esse item ao carrinho, escolha o tamanho do produto!</b></Badge>
                                }
                            </>

                        }

                        {product?.[0]?.salitoqtd5 === null || product?.[0]?.salitoqtd5 < 0 ?
                            <>
                                <Text fontFamily={'Inter, sans-serif'} fontSize={15} p={3}><b>Clique no botão abaixo para solicitar esse produto com o fornecedor</b></Text>
                                <Button colorScheme={'blue'}>Solicitar produto</Button>
                            </>
                            :
                            <Button top={2} disabled={(product?.[0]?.cor6?.length > 0 && corProduct === undefined) || (product?.[0]?.altura6 > 0 && tamanhoProduto === undefined) ? true : false || product?.[0]?.salitoqtd5 === 0 || product?.[0]?.salitoqtd5 === null} size={'md'} onClick={() => addToCart((product?.[0]?.descricao5), product?.[0]?.image, product?.[0]?.preco, product?.[0]?.codigo5, quantity, corProduct)} variant={'solid'} colorScheme={'twitter'} textColor={'white'}>Adicionar ao carrinho</Button>


                        }

                        <Text fontFamily={'Roboto, sans-serif'} paddingY={3} fontSize={18}><b>Descrição</b></Text>
                        <Text fontFamily={'Poppins, sans-serif'} align={"justify"} fontSize={15} textColor={'gray'}>{product?.[0]?.descricao}</Text>
                        {product?.[0]?.salitoqtd5 === 0 ?
                            <Badge textAlign={'center'} w={'50%'} fontSize={14} colorScheme={'red'}>Produto esgotado!</Badge>
                            :
                            <Badge textAlign={'center'} w={'30%'} fontSize={14} colorScheme={'green'}>Disponível!</Badge>
                        }
                    </VStack>
                </SimpleGrid >
                <VStack bg={'#fdfdfd'} borderRadius={6} align={'initial'} w={'full'} p={3}>
                    <Text fontFamily={'Roboto, sans-serif'} textColor={'black'} fontSize={22} p={3}>Perguntas e respostas {data?.length > 0 ? `(${data?.length})` : ''}</Text>
                    <Text fontFamily={'Roboto, sans-serif'} textColor={'black'} fontSize={18} pl={3} pt={5}><b>Envie uma pergunta ao fornecedor</b></Text>
                    <InputGroup onKeyPress={(e: any) => EnviarMensagemComEnter(e, product?.[0]?.codigo5)} p={2} w={'70%'} size='lg'>

                        <Input _hover={{}} _active={{}} _focus={{}} value={mensagem} onChange={(e: any) => setMensagem(e.target.value)} maxLength={100} fontSize={16} placeholder='Digite sua pergunta para o fornecedor...' />
                        <Button _hover={{ bg: '#345ed1' }} isLoading={isloading ? true : false} zIndex={0} onClick={(e: any) => EnviarMensagem(product?.[0]?.codigo5, mensagem)} borderRadius={3} cursor={'pointer'} fontSize={20} bg={'blue.500'} children={<SendOutlined style={{ color: 'white', borderRadius: 5 }} />} />
                    </InputGroup>

                    <Text fontFamily={'Roboto, sans-serif'} textColor={'black'} fontSize={16} p={5}><b>Últimas perguntas feitas:</b></Text>
                    {data?.length === 0 ?
                        <>
                            <Text textAlign={'center'} fontFamily={'Inter, sans-serif'} fontSize={18} p={2}><b>Não há perguntas nesse produto.</b></Text>
                            <Text textAlign={'center'} fontFamily={'Inter, sans-serif'} textColor={'gray'} fontSize={16} p={1}><b>Experimente perguntar algo ao fornecedor!</b></Text>
                        </>
                        :

                        showallResults === 0 &&
                        <>
                            <HStack>
                                <Comment
                                    author={<Text fontFamily={'Roboto, sans-serif'} fontSize={14}>{data?.[0]?.nome}</Text>}
                                    avatar={data?.[0]?.avatar?.length > 15 ?
                                        <Avatar bg={'transparent'} src={data?.[0].avatar}></Avatar>
                                        :
                                        <UserOutlined style={{ fontSize: 20, padding: 8, cursor: 'auto' }} />}
                                    content={
                                        <Text fontFamily={'Inter, sans-serif'} fontSize={15} pt={1} >{data?.[0]?.mensagem}</Text>
                                    }

                                />
                                {/* Verificando se o usuário logado é um fornecedor e se essa empresa pertence a esse fornecedor */}
                                {(data?.[0]?.resposta?.length === 0 || data?.[0]?.resposta === null) && fornecedor && empresaId == empresaFornecedor &&
                                    <Popover closeOnEsc returnFocusOnClose closeOnBlur initialFocusRef={initRef}  >
                                        {({ isOpen, onClose }) => (
                                            <>
                                                <PopoverTrigger>
                                                    <Button fontSize={14} variant={"link"} colorScheme={"blue"}>Responder<SendOutlined style={{ fontSize: 18, padding: 1 }} /></Button>

                                                </PopoverTrigger>
                                                <Portal>
                                                    <PopoverContent>
                                                        <PopoverArrow />
                                                        <PopoverHeader><b>Responder</b></PopoverHeader>
                                                        <PopoverCloseButton />
                                                        <PopoverBody px={3}>
                                                            <Text fontFamily={'Inter, sans-serif'} fontSize={14} p={2}><b>{data?.[0]?.mensagem}</b></Text>
                                                            <Input onChange={(e: any) => setResposta(e.target.value)} onKeyPress={(e: any) => EnviarRespostaComEnter(e, data?.[0]?.id)} p={2} placeholder="Responder..."></Input>
                                                            <Button isLoading={isloading ? true : false} onClick={() => EnviarResposta(data?.[0]?.id, resposta)} ref={initRef} colorScheme={'green'} top={2}>Enviar</Button>
                                                        </PopoverBody>

                                                    </PopoverContent>
                                                </Portal>
                                            </>
                                        )}
                                    </Popover>

                                }
                            </HStack>
                            {data?.[0]?.resposta?.length > 0 &&
                                <HStack pl={12}>

                                    <EnterOutlined style={{ fontSize: 18 }} rotate={90} />
                                    <Text textColor={'gray'}>{data?.[0]?.resposta}</Text>
                                    {/* Verificando se o usuário logado é um fornecedor e se essa empresa pertence a esse fornecedor */}
                                    {fornecedor && empresaId == empresaFornecedor &&

                                        <>
                                            <Popover closeOnEsc returnFocusOnClose closeOnBlur initialFocusRef={initRef}  >
                                                {({ isOpen, onClose }) => (
                                                    <>
                                                        <PopoverTrigger>

                                                            <EditOutlined style={{ fontSize: 20, cursor: 'pointer' }} />


                                                        </PopoverTrigger>
                                                        <Portal>
                                                            <PopoverContent>
                                                                <PopoverArrow />
                                                                <PopoverHeader><b>Editar</b></PopoverHeader>
                                                                <PopoverCloseButton />
                                                                <PopoverBody p={3}>
                                                                    <Input placeholder={data?.[0]?.resposta} onChange={(e: any) => setResposta(e.target.value)} onKeyPress={(e: any) => EnviarRespostaComEnter(e, data?.[0]?.id)} p={2}></Input>
                                                                    <Button isLoading={isloading ? true : false} onClick={() => EnviarResposta(data?.[0]?.id, resposta)} ref={initRef} colorScheme={'green'} top={2}>Enviar</Button>
                                                                </PopoverBody>

                                                            </PopoverContent>
                                                        </Portal>
                                                    </>
                                                )}
                                            </Popover>
                                            <Tooltip label='Excluir'>
                                                <DeleteOutlined onClick={() => DeletarResposta(data?.[0]?.id)} style={{ fontSize: 20, cursor: 'pointer' }} />
                                            </Tooltip>
                                        </>


                                    }
                                </HStack>

                            }
                        </>
                    }
                    {showallResults === 1 ?
                        <></>
                        :
                        data?.length > 1 &&
                        <VStack align={'initial'} maxW={'200px'}>
                            <Button rightIcon={<DownOutlined style={{ fontSize: 12, color: '#3483FA', paddingTop: 1 }} />} _active={{}} _hover={{}} textColor={'#3483FA'} onClick={() => setShowAllResults(1)} variant={'ghost'} w={'100%'}>Ver mais {data?.length - 1} resultados</Button>

                        </VStack>

                    }
                    {showallResults &&
                        data?.map((allMessages: any) => {

                            return (
                                <Show key={allMessages?.id}>
                                    <HStack>
                                        <Comment

                                            author={<Text fontFamily={'Roboto, sans-serif'} fontSize={14}>{allMessages.nome}</Text>}
                                            avatar={allMessages?.avatar?.length > 15 ?
                                                <Avatar cursor={'auto'} bg={'transparent'} src={allMessages.avatar}></Avatar>
                                                :
                                                <UserOutlined style={{ fontSize: 20, padding: 8, cursor: 'auto' }} />}
                                            content={

                                                <Text fontFamily={'Inter, sans-serif'} fontSize={15} p={1}>{allMessages.mensagem}</Text>

                                            }

                                        />
                                        {(allMessages?.resposta?.length === 0 || allMessages?.resposta === null) && fornecedor && empresaId == empresaFornecedor ?
                                            <Popover closeOnEsc returnFocusOnClose closeOnBlur initialFocusRef={initRef}  >
                                                {({ isOpen, onClose }) => (
                                                    <>
                                                        <PopoverTrigger>
                                                            <Button fontSize={14} variant={"link"} colorScheme={"blue"}>Responder<SendOutlined style={{ fontSize: 15, padding: 1 }} /></Button>

                                                        </PopoverTrigger>
                                                        <Portal>
                                                            <PopoverContent>
                                                                <PopoverArrow />
                                                                <PopoverHeader><b>Responder</b></PopoverHeader>
                                                                <PopoverCloseButton />
                                                                <PopoverBody p={3}>
                                                                    <Text fontFamily={'Inter, sans-serif'} fontSize={14} p={2}><b>{allMessages.mensagem}</b></Text>
                                                                    <Input p={2} onKeyPress={(e: any) => EnviarRespostaComEnter(e, allMessages?.id)} onChange={(e: any) => setResposta(e.target.value)} placeholder="Responder..."></Input>
                                                                    <Button isLoading={isloading ? true : false} onClick={() => EnviarResposta(allMessages?.id, resposta)} ref={initRef} colorScheme={'green'} top={2}>Enviar</Button>
                                                                </PopoverBody>

                                                            </PopoverContent>
                                                        </Portal>
                                                    </>
                                                )}
                                            </Popover>
                                            :
                                            <></>
                                        }
                                    </HStack>
                                    {allMessages?.resposta?.length > 0 &&
                                        <HStack pl={12} pb={5} >
                                            <EnterOutlined style={{ fontSize: 18 }} rotate={90} />
                                            <Text textColor={'gray'}>{allMessages.resposta}</Text>

                                            {/* Verificando se o usuário logado é um fornecedor e se essa empresa pertence a esse fornecedor */}
                                            {fornecedor && empresaId == empresaFornecedor &&
                                                <>
                                                    <Popover closeOnEsc returnFocusOnClose closeOnBlur initialFocusRef={initRef}  >
                                                        {({ isOpen, onClose }) => (
                                                            <>
                                                                <PopoverTrigger>

                                                                    <EditOutlined style={{ fontSize: 20, cursor: 'pointer' }} />

                                                                </PopoverTrigger>
                                                                <Portal>
                                                                    <PopoverContent>
                                                                        <PopoverArrow />
                                                                        <PopoverHeader><b>Editar</b></PopoverHeader>
                                                                        <PopoverCloseButton />
                                                                        <PopoverBody p={3}>
                                                                            <Input placeholder={allMessages.resposta} onChange={(e: any) => setResposta(e.target.value)} onKeyPress={(e: any) => EnviarRespostaComEnter(e, allMessages?.id)} p={2}></Input>
                                                                            <Button isLoading={isloading ? true : false} onClick={() => EnviarResposta(allMessages?.id, resposta)} ref={initRef} colorScheme={'green'} top={2}>Enviar</Button>
                                                                        </PopoverBody>

                                                                    </PopoverContent>
                                                                </Portal>
                                                            </>
                                                        )}
                                                    </Popover>
                                                    <Tooltip label='Excluir'>

                                                        <DeleteOutlined onClick={() => DeletarResposta(allMessages?.id)} style={{ fontSize: 20, cursor: 'pointer' }} />
                                                    </Tooltip>

                                                </>

                                            }
                                        </HStack>


                                    }

                                </Show>
                            )
                        })

                    }
                    <Divider borderColor={'gray.400'} h={'60px'} />
                    <OpinioesProdutos />

                </VStack>

                <Drawer autoFocus={false} blockScrollOnMount={false} lockFocusAcrossFrames placement="top" onClose={onCloseDrawerProdutos} isOpen={isOpenDrawerProdutos}>
                    <DrawerContent borderRadius={8} boxShadow={'0px 0px 15px 3px #0000008b;'} p={3} bgColor={'white'}>
                        <DrawerBody>
                            <HStack className="AddedProduct" justify={'center'} align={'center'} px={2} spacing={5}>
                                <Avatar id="AddeddProductAvatar" size={'lg'} src={image}></Avatar>

                                <VStack align={'center'}>
                                    <Text id="AddeddProductText" fontFamily={'Inter, sans-serif'} textColor={erroproduto === 0 ? 'black' : '' || erroproduto === false ? '#0bbd1d' : '#ff680f'} fontSize={18}><b>{erroproduto === 0 ? <Text>Carregando <Spinner /></Text> : '' || erroproduto === false ? 'Adicionado ao carrinho!' : 'Erro ao adicionar produto no carrinho!'}</b></Text>
                                    <Text id="AddeddProductTitle" fontSize={18} fontFamily={'Inter, sans-serif'}><b>{name}</b></Text>
                                </VStack>
                                <Divider orientation="vertical" borderColor={'gray.300'} h={'50px'} />
                                <Text id="AddeddProductPrice" fontFamily={'Roboto, sans-serif'} fontSize={20}>{price?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</Text>

                                <Button minW={'90px'} borderRadius={5} fontSize={15} p={3} height={'35px'} colorScheme={'blue'} onClick={() => { navigate('/carrinho') }}>Ver carrinho </Button>

                                <DrawerCloseButton id="AddeddProductCloseButton" _focus={{}} fontSize={15} />
                            </HStack>
                        </DrawerBody>
                    </DrawerContent>
                </Drawer>

                <Modal isOpen={isOpenModalImagem} onClose={onCloseModalImagem}>
                    <ModalOverlay bg={'blackAlpha.800'} />
                    <ModalCloseButton top={'4%'} right={'4%'} bg={'blackAlpha.300'} fontSize={20} zIndex={1} color={'white'} />
                    <ModalContent h={'70%'} zIndex={2} borderRadius={0}>

                        <ModalBody >
                            <VStack className="img-container" h={'full'} w={'full'} align={'center'} justify={'center'}>
                                <Image transform={'auto'} scale={1.2} maxW={'100%'} objectFit={'cover'} maxH={'100%'} src={product?.[0]?.image} />
                            </VStack>
                        </ModalBody>

                        <ModalFooter>

                        </ModalFooter>
                    </ModalContent>
                </Modal>

            </motion.div>
        </VStack>
    );
})