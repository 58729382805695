import { ProdutosProvider } from "../components/Context/ProdutosContext";
import { ListaProdutosPage } from "../pages/ListaProdutosPage";
export const ListaProdutosProvidersPage = () => {
	return (

		<ProdutosProvider>
			<ListaProdutosPage />
		</ProdutosProvider>

	);
}