import { BgColorsOutlined, CopyTwoTone, DeleteOutlined, MinusOutlined, PlusOutlined, ShoppingOutlined } from '@ant-design/icons';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { Alert, AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, AlertIcon, Box, Button, Divider, HStack, Image, Input, InputGroup, InputLeftAddon, InputRightAddon, Menu, MenuButton, MenuItem, MenuList, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spacer, Spinner, Tab, TabList, TabPanel, TabPanels, Tabs, Text, Tooltip, useDisclosure, useToast, VStack } from '@chakra-ui/react';
import { Avatar, Group, Select } from '@mantine/core';
import { message, notification, Steps } from 'antd';
import axios from 'axios';
import copy from "copy-to-clipboard";
import React, { forwardRef, useEffect, useState } from 'react';
import { FiTrash } from 'react-icons/fi';
import { IoIosArrowBack } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import { LoadingCircle } from '../components/loading/LoadingCircle';
import { GetCarrinho } from '../hooks/GetCarrinho';
import { GetMe } from '../hooks/GetMe';
import '../Styles/Globalcss.css';
import { Produto } from '../types/types';
import { api } from '../util/api';

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
	image: string;
	label: string;
	description: string;
}

function delay(n: any) {
	return new Promise(function (resolve) {
		setTimeout(resolve, n * 1000);
	});
}

export const CarrinhoComprasPage = () => {
	const { dataCarrinho, error, mutateCarrinho } = GetCarrinho();
	const { me, isLoadingMe } = GetMe();
	const navigate = useNavigate();

	const [isProductLoading, setIsProductLoading] = useState(false);
	const [editQuantityLoading, setEditQuantityLoading]: any = useState();
	const [editLoading, setEditLoading]: any = useState();
	const [somaitens, setSomaItens] = useState(0);
	const [corProduct, setCorProduct]: any = useState();
	const [alterarProduto, setAlterarProduto]: any = useState(0);
	var [copyText, setCopyText]: any = useState(null);
	var [qrcode, setQrCode] = useState<any>(0);
	const generated_number = getRandomInt(100000000000, 10000000000010000);

	const toast = useToast();

	const cancelRef = React.useRef(null);

	const { isOpen, onOpen, onClose } = useDisclosure();
	const { isOpen: isOpenFazerPedido, onOpen: onOpenFazerPedido, onClose: onCloseFazerPedido } = useDisclosure();
	const { isOpen: isOpenPixMethod, onOpen: onOpenPixMethod, onClose: onClosePixMethod } = useDisclosure();
	const { isOpen: isOpenCardMethod, onOpen: onOpenCardMethod, onClose: onCloseCardMethod } = useDisclosure();
	const { isOpen: isOpenMoneyMethod, onOpen: onOpenMoneyMethod, onClose: onCloseMoneyMethod } = useDisclosure();
	const { isOpen: isOpenInstrucoes, onOpen: onOpenInstrucoes, onClose: onCloseInstrucoes } = useDisclosure();

	const initialRef = React.useRef(null);
	const finalRef = React.useRef(null);
	const [current, setCurrent] = useState(0);

	const next = () => {
		setCurrent(current + 1);
	};

	const prev = () => {
		setCurrent(current - 1);
	};
	const { Step } = Steps;

	const onChange = (value: number) => {
		setCurrent(value);
	};

	const [paymentmethod, setPaymentMethod]: any = useState(null);

	useEffect(() => {

		if (!isLoadingMe && me?.statusCode === 401) {
			navigate('/login')
		}
	})

	useEffect(() => {

		// Toda vez que entrar na tela do carrinho ele setta o loading para true, ele será true até o carregamento da lista de produtos no carrinho
		setIsProductLoading(true)

		if (dataCarrinho?.length === 0)
			setIsProductLoading(false);
		if (dataCarrinho?.length > 0)
			setIsProductLoading(false);
		if (error)
			setIsProductLoading(false)


	}, [dataCarrinho, error])


	useEffect(() => {

		// Filtrando todos os preços de todos os produtos presentes no carrinho e mulitplicando com a quantidade de cada um para fazer o cálculo do preço de cada produto

		var allPrices = dataCarrinho?.map((products: any) => products.preco_produto * products.quantidade_produto);
		let somaPrices = 0;

		for (let i = 0; i < allPrices?.length; i++) {
			somaPrices += allPrices[i];
		}
		setSomaItens(somaPrices);
	}, [dataCarrinho])

	const nome_empresas = dataCarrinho?.map((empresas: any) => (empresas?.nome_empresa));
	const email_empresas = dataCarrinho?.map((empresas: any) => (empresas?.email_empresa));

	const SetnomeEmpresas = nome_empresas?.filter(function (el: any, i: any) {
		return nome_empresas.indexOf(el) === i;
	});
	const SetemailEmpresas = email_empresas?.filter(function (el: any, i: any) {
		return email_empresas.indexOf(el) === i;
	});

	function deleteFromCart(productsId: any) {

		// Deleta o item do carrinho passando como parâmetro para a query o ID do produto

		const route: string = `/user/removerproduto/${productsId}`;
		setIsProductLoading(true);
		api.delete(route).then(() => {
			mutateCarrinho();
			setIsProductLoading(false);

		})
	}

	function clearCart() {

		// Limpa o carrinho removendo todos os produtos nele existentes.

		onClose();
		const route: string = `/user/clearcart`;
		setIsProductLoading(true);
		api.delete(route).then(() => {
			mutateCarrinho();
			setIsProductLoading(false);
		}).catch((error: any) => {
			message.error("Não foi possível limpar o carrinho! Por favor tente novamente mais tarde!")
			setIsProductLoading(false)
		})
	}



	const dadosSelect = [
		{
			image: 'https://i.ibb.co/C5dwN4L/99538099-3b7a5d00-298b-11eb-9f.png',
			label: 'Pix',
			value: 'PIX',
			description: 'Pix é o pagamento instantâneo brasileiro. O meio de pagamento criado pelo Banco Central (BC)',
		},

		{
			image: 'https://i.ibb.co/0qCZNjT/debit-card.png',
			label: 'Cartão',
			value: 'CARTÃO',
			description: 'Cartões de crédito, débito ou vale alimentação.',
		},
		{
			image: 'https://i.ibb.co/98XcKjK/cash-payment-1.png',
			label: 'Dinheiro',
			value: 'DINHEIRO',
			description: 'Pagamento a vista com dinheiro em espécie.',
		}
	];

	const steps = [
		{
			title: 'Formato',
			content: `Entre em contato com as empresas para combinar sobre a forma de pagamento e forma de envio da mercadoria.`,



		},
		{
			title: 'Segundo',
			content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer pellentesque leo sit amet semper imperdiet. Sed faucibus a tortor non vulputate. Pellentesque placerat risus ac sodales suscipit. Nunc consectetur risus ac sem faucibus dapibus. Sed eleifend nulla sed massa porta fermentum. Phasellus mi quam, luctus pretium purus et, lobortis gravida mi. Donec justo ex, ultricies quis erat quis, auctor fermentum lorem.',
		},
		{
			title: 'Terceiro',
			content: 'Vivamus tincidunt elit nec mi sagittis, at tempus lacus suscipit. Proin quis metus ut quam sollicitudin congue non et nisi. Pellentesque convallis fringilla feugiat. Cras varius lorem vel dolor hendrerit, quis vehicula diam dignissim. Nullam posuere vitae sapien nec feugiat. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Fusce hendrerit erat eu posuere cursus. Aenean congue consequat felis, et bibendum enim fermentum vel. In ultrices erat massa, eu pellentesque nunc blandit sit amet. Duis a eros varius, volutpat elit vel, porta orci. Pellentesque quis orci non metus vestibulum placerat et id diam. Curabitur eget dignissim lorem. ',
		},


	]

	function AbrirTelaInstrucoes() {
		onOpenInstrucoes();
		onOpenFazerPedido();
	}

	function ConfirmarPedido() {
		if (somaitens < 1) {
			onCloseFazerPedido();
			message.destroy();
			message.error({
				content: 'Adicione produtos no carrinho antes de confirmar o pedido!',
				className: 'custom-class',
				duration: 2,
				style: {
					fontFamily: 'Roboto, sans-serif',
					fontSize: 15,
					zIndex: 10,

				},

			});
		}

		if (paymentmethod !== null && somaitens > 1) {
			message.destroy();
			onCloseFazerPedido();
			onClosePixMethod();
			onCloseCardMethod();
			onCloseMoneyMethod();
			message.loading('Confirmando pedido...')
			const route: string = 'pedido';



			api.post(route, { dataCarrinho, paymentmethod, somaitens }).then(() => {
				message.destroy()
				notification.open({
					duration: 2.5,
					placement: 'top',
					message: <Text textColor={'white'} fontFamily={'Roboto, sans-serif'} fontSize={16}><b>Sucesso!</b></Text>,
					description:
						<Text textColor={'white'} fontSize={16} fontFamily={'Inter, sans-serif'}><b>Pedido efetuado com sucesso!</b></Text>,
					style: { background: '#4aaf53', borderRadius: 6, boxShadow: '0px 0px 20px 4px #6868688a' },
				});
				clearCart();

			}).catch((error: any) => {
				message.destroy()
				notification.open({
					duration: 2.5,
					placement: 'top',
					message: <Text textColor={'white'} fontFamily={'Roboto, sans-serif'} fontSize={16}><b>Erro!</b></Text>,
					description:
						<Text textColor={'white'} fontSize={16} fontFamily={'Inter, sans-serif'}><b>Não foi possível confirmar seu pedido!</b></Text>,
					style: { background: '#cc4242', borderRadius: 6, boxShadow: '0px 0px 20px 4px #6868688a' },
				});
			})


		}
	}


	function VerifyPaymentMethod() {

		// Verifica se o método de pagamento é igual a "Pix", "Cartão" ou "Dinheiro", chamando para cada um sua respectiva modal

		if (paymentmethod === 'PIX')
			PixPayment();
		if (paymentmethod === 'CARTÃO')
			CardPayment();
		if (paymentmethod === 'DINHEIRO')
			MoneyPayment();
	}

	async function PixPayment() {

		// Fecha a modal do método de pagamento e abre a modal do pagamento por PIX
		onCloseFazerPedido();
		onOpenPixMethod();

		setQrCode(0);
		await delay(3);
		axios.get("https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=Example").then(async (response: any) => {
			setQrCode(response?.config?.url)

		})

	}

	async function CardPayment() {

		// Fecha a modal do método de pagamento e abre a modal do pagamento por cartão

		onCloseFazerPedido();
		onOpenCardMethod();

	}

	async function MoneyPayment() {

		// Fecha a modal do método de pagamento e abre a modal do pagamento em dinheiro

		onCloseFazerPedido();
		onOpenMoneyMethod();

	}

	function getRandomInt(min: any, max: any) {
		// Gera um número aleatório
		min = Math.ceil(min);
		max = Math.floor(max);
		return Math.floor(Math.random() * (max - min)) + min;
	}

	const copyToClipboard = () => {

		// Copia a chave pix (número aleatório gerado) para a área de transferência
		copyText = generated_number
		copy(copyText);
		message.destroy();
		message.success({
			content: 'Chave PIX copiada!',
			className: 'custom-class',

			style: {
				fontFamily: 'Roboto, sans-serif',
				fontSize: 14,
				zIndex: 10,

			},

		});
	}


	async function EditarQuantidadeProduto(idProduto: number, quantidade_selecionada: number, quantidade_disponivel: number) {
		if (quantidade_selecionada > quantidade_disponivel) {
			toast.closeAll()
			toast({
				title: 'Erro ao alterar quantidade!',
				description: `Estoque disponível de apenas ${quantidade_disponivel} itens!`,
				status: 'error',
				duration: 3000,
				isClosable: true
			})
			return 1;
		}
		else if (quantidade_selecionada == 0) {
			toast.closeAll()
			toast({
				title: 'Erro ao alterar quantidade!',
				description: `Não é possível alterar para essa quantidade (0)!`,
				status: 'error',
				duration: 3000,
				isClosable: true
			})
			return 1;
		}
		else
			notification.destroy()
		notification.open({
			duration: 2.5,
			placement: 'top',
			message: <VStack>
				<Text textAlign={'center'} textColor={'black'} fontFamily={'Roboto, sans-serif'} fontSize={18}><b>Carregando...</b></Text><Spinner p={10} /></VStack>,
			style: { background: 'rgb(255, 255, 255)', borderRadius: 6, boxShadow: '0px 0px 20px 4px #cfcfcf89' },
		});
		setEditQuantityLoading(true)
		await delay(1)
		api.put('/user/cart/editarquantproduto', { idProduto, quantidade_selecionada }).then(() => {
			mutateCarrinho()

		})
		await delay(0.5)
		notification.destroy()
		setEditQuantityLoading(false)
	}

	async function EditarCorProduto(idProduto: number, cor: string) {
		setEditLoading(true)
		setAlterarProduto(0)
		await delay(1)
		api.put('/user/cart/editarcorproduto', { idProduto, cor }).then(() => {
			mutateCarrinho()
		})
		await delay(0.5)
		setEditLoading(false)
	}

	const [numerocartao, SetNumeroCartao]: any = useState<any>();
	const [nomecompleto, SetNomeCompleto] = useState();
	const [datavenc, SetDataVenc] = useState();
	const [codigoseguranca, SetCodigoSeguranca] = useState();

	const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
		({ image, label, description, ...others }: ItemProps, ref) => (
			<div ref={ref} {...others}>
				<Group noWrap>
					<Avatar src={image} />

					<div>
						<Text size="sm">{label}</Text>
						<Text size="xs" color="dimmed">
							{description}
						</Text>
					</div>
				</Group>
			</div>
		)
	);

	return (

		<VStack key={dataCarrinho?.carrinho} mx={10} h={isProductLoading ? '500px' : 'full'}>
			<HStack w="full" bg="white" py={5} px={2}>

				<Button onClick={() => navigate('/')} leftIcon={<IoIosArrowBack />} variant={"outline"} colorScheme={"gray"}>Continuar comprando</Button>
				<Spacer />
				<Button colorScheme={"whatsapp"} onClick={(AbrirTelaInstrucoes)}>Finalizar compra</Button>

			</HStack>

			{isProductLoading ?
				<LoadingCircle />
				:
				<></>
			}
			{dataCarrinho?.length > 0 &&
				<>
					<Alert w={'auto'} fontSize={15} status='warning'>
						<AlertIcon />
						Antes de finalizar a compra verifique se os produtos no seu carrinho estão com a quantidade e preços corretos!
					</Alert>
					<Tabs w="full" minH={"300px"} bg={"white"}>

						<TabList>
							<Tab fontWeight={"semibold"}>Carrinho</Tab>

						</TabList>
						<TabPanels>
							<TabPanel>
								{dataCarrinho?.map((products: Produto) => {
									return (
										<VStack key={products?.id_produto} py={2} mx={2}>
											<HStack w={'100%'} >

												<Image fallbackSrc={'/fail_load_image.png'} onClick={() => navigate(`/compras/${products?.id_produto}/${products?.id_empresa}`)} cursor={'pointer'} w="180px" src={products.image_produto} pr={10} />
												<VStack alignItems={"start"}>
													<Text fontFamily={'Inter, sans-serif'} textColor={'black'} fontSize={16}><b>{products.descricao5}</b></Text>
													<Text fontFamily={'Inter, sans-serif'} textColor={'gray'} fontSize={16}>Id do produto: {products.id_produto} </Text>
													<Text fontFamily={'Inter, sans-serif'} textColor={'gray'} fontSize={16}>Unidades: <b>{products.quantidade_produto}</b> {products?.un5} ({products.salitoqtd5} disponíveis)</Text>
													<HStack bg={'white'} maxW='145px'>
														<InputGroup onInput={(e: any) => e.target.value = e.target.value.slice(0, 4)}>
															{products?.quantidade_produto === products?.salitoqtd5 ?
																<></>
																:
																<InputLeftAddon borderRadius={0} p={0} onClick={() => EditarQuantidadeProduto(products?.id_produto, products?.quantidade_produto + 1, products?.salitoqtd5)} children={<Button textColor={'blue'} disabled={editQuantityLoading || editLoading ? true : false}><PlusOutlined /></Button>} />
															}
															<Input p={2} maxLength={5} type={'number'} maxW={'60px'} textAlign={'center'} onInput={(e: any) => EditarQuantidadeProduto(products?.id_produto, e.target.value, products?.salitoqtd5)} />
															{products?.quantidade_produto === 1 ?
																<></>
																:
																<InputRightAddon p={0} borderRadius={0} children={<Button textColor={'blue'} borderRadius={0} p={0} disabled={editQuantityLoading || editLoading ? true : false} onClick={() => EditarQuantidadeProduto(products?.id_produto, products?.quantidade_produto - 1, products?.quantidade_produto)}><MinusOutlined /></Button>} />
															}
														</InputGroup>

													</HStack>
													<VStack align={'initial'}>
														{products?.cor_produto !== 'undefined' &&
															<Text fontFamily={'Inter, sans-serif'} textColor={'black'} fontSize={16}> <b>Cor do produto: {products?.cor_produto}</b></Text>

														}
														{products?.tamanho_produto !== null && products?.tamanho_produto !== 'undefined' &&
															<Text fontFamily={'Inter, sans-serif'} textColor={'black'} fontSize={16}><b>Tamanho do produto (em cm): {products.tamanho_produto}</b></Text>

														}

														{products?.cor1?.length > 0 &&
															<>
																<Button isLoading={editLoading === true ? true : false} onClick={() => setAlterarProduto(1)} colorScheme={'blue'} variant={'link'}>Alterar<BgColorsOutlined style={{ marginLeft: 4 }} /></Button>
																{alterarProduto === 1 &&
																	<Menu>
																		<MenuButton disabled={products?.[0]?.quantidade === 0 ? true : false} value={products?.cor_produto} _hover={{}} as={Button} rightIcon={<ChevronDownIcon />}>

																			{corProduct !== undefined ? corProduct : 'Alterar cor do produto'}
																		</MenuButton>
																		<MenuList fontSize={16}>
																			<MenuItem >Alterar a cor do produto</MenuItem>
																			<MenuItem onClick={() => EditarCorProduto(products?.id_produto, products?.cor1)}>{products?.cor1}</MenuItem>
																			<MenuItem onClick={() => EditarCorProduto(products?.id_produto, products?.cor2)}>{products?.cor2}</MenuItem>
																			<MenuItem onClick={() => EditarCorProduto(products?.id_produto, products?.cor3)}>{products?.cor3}</MenuItem>
																		</MenuList>
																	</Menu>

																}
															</>

														}
													</VStack>
													<HStack py={3}>

														<Button onClick={() => navigate(`/1/page=1/lista-produtos/`)} variant={"link"} colorScheme={"blue"}>Mais produtos do vendedor<ShoppingOutlined style={{ fontSize: 20, padding: 2 }} /></Button>

														<Button pl={5} onClick={() => deleteFromCart(products.id_produto)} variant={"link"} colorScheme={"blue"}>Excluir<DeleteOutlined style={{ fontSize: 20, padding: 2 }} /></Button>

													</HStack>
												</VStack>
												<Spacer />

												<Text lineHeight={1.25} fontFamily={'Inter, sans-serif'} textColor={'black'} fontSize={19}><b>{(products?.preco_produto * products?.quantidade_produto)?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</b></Text>
											</HStack>
											<Divider orientation="horizontal" borderColor={'gray.300'} />

										</VStack>
									)
								})}

								<HStack w="full" bg="white" py={5} px={2}>
									<Button onClick={() => {

										onOpen();

									}}
										leftIcon={<FiTrash />} variant={"ghost"} colorScheme={"red"}>Limpar carrinho

									</Button>
									<Spacer />
									<Text fontSize={16} fontWeight={"semibold"}>Subtotal ({dataCarrinho?.length} {dataCarrinho?.length === 1 ? 'item' : 'itens'}):</Text>
									<Text fontSize={18} fontWeight={"semibold"} >{dataCarrinho?.length > 0 ? somaitens?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : ''}</Text>

								</HStack>
							</TabPanel>
						</TabPanels>
					</Tabs>
				</>
			}
			{dataCarrinho < 1 &&

				<VStack w="full" bg={"white"} minH={"500px"}>
					<Text p={3} pt={'7%'} fontFamily={'Inter, sans-serif'} align={'center'} fontSize={25} textColor='gray.700'>O seu carrinho está vazio.</Text>
					<Text p={3} pt={2} fontFamily={'Inter, sans-serif'} align={'center'} fontSize={22} textColor='gray'>Não sabe o que comprar? Centenas de produtos esperam por você!</Text>
					<Button onClick={() => navigate('/empresas/all')} colorScheme={'blue'}>Ver produtos</Button>
				</VStack>


			}

			<AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize="lg" fontWeight="bold">
							Confirmação!
						</AlertDialogHeader>

						<AlertDialogBody>
							<Text fontSize={"lg"}>Tem certeza de que deseja limpar o carrinho?</Text>
						</AlertDialogBody>

						<AlertDialogFooter>
							<Button ref={cancelRef} onClick={onClose}>
								Cancelar
							</Button>
							<Button colorScheme="red" onClick={clearCart} ml={3}>
								Deletar
							</Button>
						</AlertDialogFooter>

					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>


			{/* Modal que abre tela de Selecionar método de pagamento */}
			<Modal
				initialFocusRef={initialRef}
				finalFocusRef={finalRef}
				isOpen={isOpenFazerPedido}
				onClose={onCloseFazerPedido}
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Efetuar pedido</ModalHeader>
					<ModalCloseButton />
					<ModalBody pb={6}>

						<Select
							onChange={((e: any) => setPaymentMethod(e))}
							zIndex={4000}
							label="Forma de pagamento"
							placeholder="Escolha um"
							itemComponent={SelectItem}
							data={dadosSelect}
							searchable
							maxDropdownHeight={400}
							nothingFound="Não encontrado!"
							filter={(value, item) =>
								item?.label?.toLowerCase().includes(value?.toLowerCase()?.trim()) ||
								item?.description.toLowerCase().includes(value?.toLowerCase()?.trim())
							}
						/>
					</ModalBody>

					<ModalFooter>
						<Button onClick={() => VerifyPaymentMethod()} colorScheme='whatsapp' mr={3}>
							Continuar
						</Button>
						<Button onClick={onCloseFazerPedido}>Cancelar</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>

			{/* Modal que abre tela do pagamento por pix */}
			<Modal closeOnOverlayClick={false} isOpen={isOpenPixMethod} onClose={onClosePixMethod}>
				<ModalOverlay />
				<ModalContent >
					<ModalHeader >SELECIONADO: PIX</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<VStack>
							{somaitens < 1 ?
								<>
									<Text textColor={'black'} fontFamily={'Roboto, sans-serif'} fontSize={20}>Seu carrinho está vazio!</Text>
									<Text textColor={'gray.600'} fontFamily={'Roboto, sans-serif'} fontSize={16}>Adicione produtos no carrinho e volte aqui mais tarde!</Text>
								</>

								:
								<>
									<Text textColor={'black'} fontFamily={'Roboto, sans-serif'} fontSize={17}>Seu pedido tem um preço total de {somaitens?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</Text>
									<Text fontSize={17}>Gerando o seu QR-Code...</Text>
									<Image p={3} src={qrcode === 0 ?
										'https://cdn.dribbble.com/users/902865/screenshots/4814970/loading-opaque.gif'
										:
										qrcode}
									>
									</Image>

									{qrcode !== 0 &&
										<>
											<Text fontSize={18} textColor={'green'} fontFamily={'Roboto, sans-serif'}>QR Code gerado com sucesso!</Text>
											<HStack>
												<Text onChange={((e: any) => setCopyText(generated_number))} fontSize={18}>Chave aleatória: {generated_number}
												</Text>

												<Tooltip fontSize={14} p={2} label={'Copiar chave PIX'} style={{ cursor: 'pointer' }}>
													<CopyTwoTone style={{ fontSize: 18 }} onClick={copyToClipboard} />
												</Tooltip>
											</HStack>
										</>

									}
								</>
							}
						</VStack>
					</ModalBody>

					<ModalFooter>
						<Button onClick={() => ConfirmarPedido()} mr={3} colorScheme={'green'} >Continuar</Button>
						<Button colorScheme='blue' onClick={onClosePixMethod}>
							Fechar
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>


			{/* Modal que abre tela do pagamento por cartão */}
			<Modal closeOnOverlayClick={false} isOpen={isOpenCardMethod} onClose={onCloseCardMethod}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>SELECIONADO: Cartão</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						{somaitens < 1 ?
							<>
								<Text textColor={'black'} fontFamily={'Roboto, sans-serif'} fontSize={20}>Seu carrinho está vazio!</Text>
								<Text textColor={'gray.600'} fontFamily={'Roboto, sans-serif'} fontSize={16}>Adicione produtos no carrinho e volte aqui mais tarde!</Text>
							</>
							:
							<>
								<Text mt={2} textColor={'black'} fontFamily={'Roboto, sans-serif'} fontSize={18}>Seu pedido tem um preço total de {somaitens?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</Text>
								<Text mt={2} textColor={'black'} fontFamily={'Roboto, sans-serif'} fontSize={17}>Por favor adicione um novo cartão</Text>
								<Text mt={2} fontSize={17} fontFamily={'Roboto, sans-serif'} textColor={'black'}><b>Número do cartão</b></Text>

								<HStack>
									<Input maxLength={16} onChange={((e: any) => SetNumeroCartao(e.target.value))} p={3} mt={2} placeholder='Número do cartão'></Input>
									{numerocartao?.substring(0, 2) === '41' &&
										<Image w={'18%'} src='https://logodownload.org/wp-content/uploads/2016/10/visa-logo-2.png'></Image>

									}

									{numerocartao?.substring(0, 2) === '51' || numerocartao?.substring(0, 2) === '52'
										|| numerocartao?.substring(0, 2) === '53'
										|| numerocartao?.substring(0, 2) === '54'
										|| numerocartao?.substring(0, 2) === '55' ?
										<Image w={'18%'} src='https://cashfree.com.br/wp-content/uploads/2020/09/Mastercard-Standart.png'></Image>
										:
										<></>
									}
									{numerocartao?.substring(0, 2) === '36'
										|| numerocartao?.substring(0, 2) === '38' ?
										<Image w={'22%'} src='https://logodownload.org/wp-content/uploads/2016/10/Diners-Club-Logo.png'></Image>
										:
										<></>
									}
									{numerocartao?.substring(0, 4) === '6011'
										|| numerocartao?.substring(0, 2) === '65' ?
										<Image w={'20%'} src='https://emalta.com.br/wp-content/uploads/2016/10/elo-compras-internacionais.jpg'></Image>
										:
										<></>
									}
									{numerocartao?.substring(0, 2) === '35' ?
										<Image w={'18%'} src='https://logodownload.org/wp-content/uploads/2018/05/jcb-logo.png'></Image>
										:
										<></>
									}
									{numerocartao?.substring(0, 2) === '34'
										|| numerocartao?.substring(0, 2) === '37' ?
										<Image w={'18%'} src='https://0800bancos.com.br/wp-content/uploads/2019/08/American-Express-Amex.jpg'></Image>
										:
										<></>
									}
								</HStack>

								<Text mt={2} fontSize={17} fontFamily={'Roboto, sans-serif'} textColor={'black'}><b>Nome Completo</b></Text>
								<Input onChange={((e: any) => SetNomeCompleto(e.target.value))} p={3} mt={2} placeholder='Nome completo'></Input>

								<Text mt={2} fontSize={17} fontFamily={'Roboto, sans-serif'} textColor={'black'}><b>Data de validade</b></Text>
								<Input type={'month'} onChange={((e: any) => SetDataVenc(e.target.value))} p={3} mt={2} placeholder='Data de vencimento'></Input>

								<Text mt={2} fontSize={17} fontFamily={'Roboto, sans-serif'} textColor={'black'}><b>Código de segurança</b></Text>
								<Input maxLength={3} onChange={((e: any) => SetCodigoSeguranca(e.target.value))} p={3} mt={2} placeholder='Código de segurança (SVV)'></Input>

								<Text mt={2} fontSize={17} fontFamily={'Roboto, sans-serif'} textColor={'black'}><b>CPF</b></Text>
								<Input maxLength={11} p={3} mt={2} placeholder='CPF do titular do cartão'></Input>
							</>
						}
					</ModalBody>

					<ModalFooter>
						<Button onClick={ConfirmarPedido} mr={3} colorScheme='green'>
							Confirmar
						</Button>
						<Button colorScheme={'blue'} onClick={onCloseCardMethod}>Fechar</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>


			<Modal closeOnOverlayClick={false} isOpen={isOpenMoneyMethod} onClose={onCloseMoneyMethod}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>SELECIONADO: Dinheiro</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						{somaitens < 1 ?
							<>
								<Text textColor={'black'} fontFamily={'Roboto, sans-serif'} fontSize={20}>Seu carrinho está vazio!</Text>
								<Text textColor={'gray.600'} fontFamily={'Roboto, sans-serif'} fontSize={16}>Adicione produtos no carrinho e volte aqui mais tarde!</Text>
							</>
							:
							<>
								<Text textColor={'black'} fontFamily={'Roboto, sans-serif'} fontSize={17}>Seu pedido tem um preço total de {somaitens?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</Text>
								<Text textColor={'gray.800'} fontSize={17}>Você precisará de quantos reais de troco?</Text>
								<Input mt={2} placeholder='Valor do troco'></Input>
							</>
						}
					</ModalBody>

					<ModalFooter>
						<Button onClick={ConfirmarPedido} mr={3} colorScheme='green'>
							Confirmar
						</Button>
						<Button colorScheme={'blue'} onClick={onCloseMoneyMethod}>Fechar</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>


			<Modal isOpen={isOpenInstrucoes} onClose={onCloseInstrucoes}>
				<ModalOverlay />
				<ModalContent >
					<ModalHeader>Informações importantes!</ModalHeader>
					<ModalCloseButton />
					<ModalBody textColor={'black'} fontFamily={'Roboto, sans-serif'} fontSize={18}>
						<>
							<Steps percent={current === 2 ? 100 : 50 && current === 1 ? 50 : 30} onChange={onChange} current={current}>
								{steps.map(item => (
									<Step key={item.title} title={item.title} />
								))}
							</Steps>

							<Box mt={3} p={5} boxShadow={'5px 8px 12px 3px #3535351a;'}>

								<div key={1} style={{ padding: '5%' }} className="steps-content">{steps[current].content}</div>
								{SetnomeEmpresas?.map((nomes: any) => {
									return (
										<div key={nomes} style={{ padding: '3%' }} className="steps-content"><b>{nomes}</b></div>
									)
								})}
								{SetemailEmpresas?.map((email: any) => {
									return (
										<div key={email} style={{ padding: '3%' }} className="steps-content">Email: {email}</div>

									)
								})}
							</Box>
							{current > 0 && (
								<Button top={2} style={{ margin: '0 8px' }} onClick={() => prev()}>
									Anterior
								</Button>
							)}
							{current < steps.length - 1 && (
								<Button mt={4} onClick={() => next()}>
									Próximo
								</Button>
							)}
						</>
					</ModalBody>

					<ModalFooter>
						<Button colorScheme='blue' mr={3} onClick={onCloseInstrucoes}>
							Fechar
						</Button>

					</ModalFooter>
				</ModalContent>
			</Modal>
		</VStack>
	)
}