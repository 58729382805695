import { useMediaQuery } from "@chakra-ui/react"
import { SkeletonLoadingMobile } from "../loading/SkeletonLoadingMobile"
import { SkeletonLoadingProdutos } from "../loading/SkeletonLoadingProdutos"


export const SkeletonResponsivo = () => {
    const [MenorThan1260] = useMediaQuery('(min-width: 1136px)')
    return (

        <>
            {MenorThan1260 ? <SkeletonLoadingProdutos /> : <SkeletonLoadingMobile />}
        </>
    )
}
