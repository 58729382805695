import useSWR from "swr";
import { apiEndPoint } from "../util/api";

// Hook usado para buscar se o usuário ao entrar na rota /adm está logado, sendo passado o AdmToken como header

export function LoginAdm() {
	const token = localStorage.getItem('AdmToken')

	const fetcher = (url: any) => fetch(apiEndPoint + '/me', {
		headers: {
			'Authorization': `Bearer ${token}`,

		}
	}).then(response => response.json());

	const { data, error, mutate } = useSWR(apiEndPoint + '/me', fetcher)

	return {
		admlogged: data,
		isLoading: !error && !data,
		error: error,
		mutate,
		mutateCart: mutate,

	}
}