import { SimpleGrid, Skeleton, VStack } from "@chakra-ui/react";
export const SkeletonLoadingMobile = () => {
	return (

		<VStack w={{ "sm": "100%", "lg": "100%" }}>
			
			<SimpleGrid pt={50} columns={{ "sm": 1, md: 1 }} w="full" bgColor={'#fafafa'} boxShadow='0.3px 0.3px 3px rgb(158, 158, 158)'>
				
				<VStack spacing={1} w={"full"}>
					<Skeleton borderRadius={3} h={"200px"} w={"100%"} />
					<Skeleton borderRadius={3} h={"200px"} w={"100%"} />
				</VStack>
				
			</SimpleGrid>
			
		</VStack>



	);
}