import { Outlet } from "react-router-dom";
import { Chat } from "./Chat";
import { CartProvider } from "./Context/CartContext";
import { EmpresasProvider } from "./Context/EmpresasContext";
import { Footer } from "./Footer";
import { NavBarResponsiva } from "./Responsivos/NavBarResponsiva";
export const Layout = () => {
    return (


        <CartProvider>
            <EmpresasProvider>
                <NavBarResponsiva />
                <Chat />
                <Outlet />
                <Footer />
            </EmpresasProvider>
        </CartProvider>


    )
}