import useSWR from "swr";
import { apiEndPoint } from "../util/api";

// Hook usado para receber a quantidade de respostas em determinado produto e settar as notificações para as repostas por usuário

export function GetRespostasProdutos() {
	const token = localStorage.getItem('@App:Access_Token')
	const fetcher = (url: any) => fetch(apiEndPoint + `/respostasporusuario`, {
		headers: {
			'Authorization': `Bearer ${token}`,

		}
	}).then(response => response.json());

	const { data, error, mutate } = useSWR(apiEndPoint + '/respostasporusuario', fetcher, { refreshInterval: 30000 })

	return {
		allRespostas: data,
		isLoadingRespostas: !error && !data,
		error: error,
		mutateRespostas: mutate
	}
}