import { DownloadIcon } from "@chakra-ui/icons";
import { Button, VStack } from "@chakra-ui/react";
import QRCode from "qrcode.react";
import * as React from "react";


export default function QRCodeHome() {
  const qrRef = React.useRef(null);
  const url = `https://carrinho.success.inf.br/empresas/all`;

  const downloadQRCode = (evt: React.FormEvent) => {
    evt.preventDefault();
    // @ts-ignore
    let canvas = qrRef.current.querySelector("canvas");
    let image = canvas.toDataURL("image/png");
    let anchor = document.createElement("a");
    anchor.href = image;
    anchor.download = `qr-code-home.png`;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);

  };

  const qrCode = (
    <QRCode
      id="qrCodeElToRender"
      size={200}
      value={url}
      bgColor="white"
      fgColor="#1a1d24"
      level="H"

    />
  );

  return (
    <div className="qr-container">
      <form className="qr-container__form">

      </form>

      <div className="qr-container__qr-code" ref={qrRef}>
        {qrCode}
      </div>

      <VStack p={5}>
        <Button rightIcon={<DownloadIcon />} p={5} variant={'solid'} colorScheme={'green'} onClick={downloadQRCode}>Baixar</Button>
      </VStack>

    </div>
  );
}